import Vue from 'vue';
import moment from 'moment';

export function formatDateLongSeconds(value: number): string {
  if (value) {
    return moment(String(value)).local().format('MMMM D, YYYY [at] h:mm:ss a');
  }
  return 'N/A';
}

const formatDateLongSecondsFilter = Vue.filter('formatDateLongSeconds', formatDateLongSeconds);
export default formatDateLongSecondsFilter;