



































































































// Libraries
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FunctionalCalendar } from 'vue-functional-calendar';
import moment, { Moment } from 'moment';
import { BvTimeCtxEvent } from 'bootstrap-vue';

@Component({
  name: 'date-picker-range',
  components: {
    FunctionalCalendar
  }
})
export default class DatePickerRange extends Vue {
  @Prop({required: false, default: () => [new Date(), new Date()]})
  public initialDates!: Date[];

  @Prop({required: false})
  public maxDate?: Date;

  @Prop({required: false, default: () => []})
  public sideOptions?: SideOptions[];

  @Prop({required: false, default: false})
  public showSeconds?: boolean;

  @Prop({required: false, default: false})
  public hasAction?: boolean;

  public startDate!: Moment;
  public endDate!: Moment;

  public displayDate: string = '';
  public displayStartTime: string = 'hh:mm';
  public displayEndTime: string = 'hh:mm'; 
  public displayStartDate: string = '';
  public displayEndDate: string = '';
  public btimeStartModel: string = '';
  public btimeEndModel: string = '';
  public startDateError: string = '';
  public endDateError: string = '';
  public startTimeError: string = '';
  public endTimeError: string = '';
  public config = {
    isDark: true,
    isDateRange: true,
    isDatePicker: false,
    dateFormat: 'mm/dd/yyyy',
    dayNames: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    markedDateRange: {},
    disabledDates: [''],
    isTypeable: true,
    sundayStart:true
  } as DatePickerRangeConfig;
  public isOpen = false;
  public isCustom = false;
  public showStartTime = false;
  public showEndTime = false;
  public date: DatePickerRangeModel = {currentDate: new Date, dateRange: {start: '', end: ''}} as DatePickerRangeModel;
  private parentEl: HTMLElement = {} as HTMLElement;
  public sOptions: SideOptions[] = [];
  public timeFormat = '';
  public get displayDates(): string {
    return this.displayDate;
  }
  public defaultSideOptionIndex!: number;

  private dateFormatString = 'M/D/YYYY';
  
  @Watch('initialDates')
  public reset() {
    this.resetSideOptions();
    this.$set(this.sOptions[this.defaultSideOptionIndex], 'active', true);
    this.setupDates();
    this.setupTimes();
    this.isCustom = false;
    this.emit();
  }

  public created() {
    // set disabledDates
    if (this.maxDate != null) {
      const maxDate = moment(this.maxDate).add(1, 'day').format(this.dateFormatString);
      this.config.disabledDates = ['afterToday', maxDate.toString()];
    }
    if (this.sideOptions) {
      this.setupTimes();
      this.setUpSideOptions();
    }

    this.timeFormat = this.showSeconds ? 'HH:mm:ss' : 'HH:mm';

    this.defaultSideOptionIndex = this.sideOptions?.findIndex(item => item.active) ?? 0;
  }
  public mounted() {
    this.setupDates();
    this.registerClickEvent();
    this.isCustom = false;
  }
  
  public updateDateFromCalendar() {
    
    const startDateHour = this.startDate.hours();
    const startDateMinute = this.startDate.minutes();
    const startDateSeconds = this.startDate.seconds();
    this.startDate = moment(this.date.dateRange.start, this.dateFormatString, true);

    this.startDate.hours(startDateHour);
    this.startDate.minutes(startDateMinute);
    this.startDate.seconds(startDateSeconds);

    if (!!this.date.dateRange.end) {
      const endDateHour = this.endDate.hours();
      const endtDateMinute = this.endDate.minutes();
      const endtDateSeconds = this.endDate.seconds();
      
      this.endDate = moment(this.date.dateRange.end, this.dateFormatString, true);

      this.endDate.hours(endDateHour);
      this.endDate.minutes(endtDateMinute);
      this.endDate.seconds(endtDateSeconds);
    }

    this.setIsCustom();
    this.displayDate = this.getDisplayDate();
    this.setInputDisplayDate();
    this.startDateError = '';
    this.endDateError = '';

    this.validateTime();

    this.emit();
  }

  public updateDateFromDateInput() {
    const startDate = (this.$refs.startPickerRange as HTMLInputElement).value;
    const endDate = (this.$refs.endPickerRange as HTMLInputElement).value;
    const maxDate = moment(this.maxDate).add(1, 'day').format(this.dateFormatString);
    this.displayStartDate = startDate;
    this.displayEndDate = endDate;

    const startDateMoment = moment(startDate, this.dateFormatString, true);
    const endDateMoment = moment(endDate, this.dateFormatString, true);

    // if the date is not valid.
    this.startDateError = !startDateMoment.isValid() ? 'Please use (mm/dd/yyyy) format' : '';
    this.endDateError = !endDateMoment.isValid() ? 'Please use (mm/dd/yyyy) format' : '';

    // if any of the dates are before the max date/
    if (this.maxDate) {
      const message = `Enter date before ${maxDate}`;
      if (startDateMoment.isValid() && startDateMoment.isAfter(this.maxDate)) {
        this.startDateError = message;
      }
      if (endDateMoment.isValid() && endDateMoment.isAfter(this.maxDate)) {
        this.endDateError = message;
      }
    }

    if (startDateMoment.isValid() && endDateMoment.isValid() && 
       (!this.maxDate || 
       (startDateMoment.isBefore(this.maxDate) && endDateMoment.isBefore(this.maxDate)))) {
      // start date must be before end date.
      if (startDateMoment.isAfter(endDateMoment)) {
        const message = `Enter date on/before ${this.displayEndDate}`;
        this.startDateError = message;
      }
      // end date must be after than start date.
      if (endDateMoment.isValid() && endDateMoment.isBefore(startDateMoment)) {
        const message = `Enter date on/after ${this.displayStartDate}`;
        this.endDateError = message;
      }
    }

    if (this.startDateError !== '' || this.endDateError !== '') {
      return;
    }

    this.startDate = startDateMoment.isValid() ? startDateMoment : this.startDate;
    this.endDate = endDateMoment.isValid() ? endDateMoment : this.endDate;

    const start = moment(this.startDate).format(this.dateFormatString);
    const end =  moment(this.endDate).format(this.dateFormatString);
    this.setDatesInCalendar(start, end);
    this.displayDate = this.getDisplayDate();
    this.setIsCustom();
    this.validateTime();
    this.emit();
  }

  public updateDateFromSideMenu(index: number, date: Date[]) {
    this.isCustom = false;
    if (date.length === 0) {
      throw new Error('Please provide a start and end date in configuration object side-options');
    }
    
    const [startDate, endDate] = date;

    this.$set(this.sOptions[index], 'active', true);
    this.startDate = moment(startDate);
    this.endDate = moment(endDate);
    this.displayDate = this.getDisplayDate();
    const start = moment(this.startDate).format(this.dateFormatString);
    const end =  moment(this.endDate).format(this.dateFormatString);
    this.setDatesInCalendar(start, end);
    this.setInputDisplayDate();
    this.validateTime();
    this.emit();
  }

  public setupDates(): void {
    const start = moment(this.initialDates[0]).format(this.dateFormatString);
    const end = moment(this.initialDates[1]).format(this.dateFormatString);
    this.startDate =  moment(this.initialDates[0]);
    this.endDate = moment(this.initialDates[1]);
    this.displayDate = this.getDisplayDate();
    this.setInputDisplayDate();
    this.setDatesInCalendar(start, end);
  }

  public setupTimes(): void {
    this.startDate = moment(this.initialDates[0]);
    this.endDate =  moment(this.initialDates[1]);

    this.btimeStartModel = `${this.startDate.hours()}:${this.startDate.minutes()}`;
    this.btimeEndModel =  `${this.endDate.hours()}:${this.endDate.minutes()}`;

    if (this.showSeconds) {
      this.btimeStartModel = `${this.btimeStartModel}:${this.startDate.seconds()}`;
      this.btimeEndModel = `${this.btimeEndModel}:${this.endDate.seconds()}`;
    }
  }

  public setDatesInCalendar(start: string, end: string) {
    this.date.dateRange.start = start;
    this.date.dateRange.end = end;
  }

  public setUpSideOptions(): void {
    if (!!this.sideOptions && this.sideOptions?.length > 0) {
      this.sOptions = [];
      this.sOptions = [...this.sideOptions];
    }
  }
  public registerClickEvent(): void {
    this.parentEl = this.$el.closest('#app') as HTMLElement;
    this.parentEl.addEventListener('click', this.clickedOutside);
  }

  public clickedOutside(event: MouseEvent) {
    const isDatePicker = (this.$refs.datetimepickerrange as HTMLElement) ? 
      (this.$refs.datetimepickerrange as HTMLElement).contains(event.target as HTMLElement) : false;

    const isStartTimePicker = (this.$refs.startTimePickerRange as HTMLElement) ?
        (this.$refs.startTimePickerRange as HTMLElement).contains(event.target as HTMLElement) : false;
    const isStartTimePickerOptions = (this.$refs.startTimePickerOptions as HTMLElement) ?
        (this.$refs.startTimePickerOptions as HTMLElement).contains(event.target as HTMLElement) : false;
    const isStartTimeDropDown = (event.target as HTMLElement).className === 'datetimepicker-range-input-container__dropdown starttime';
    
    const isEndTimePicker = (this.$refs.endTimePickerRange as HTMLElement) ?
        (this.$refs.endTimePickerRange as HTMLElement).contains(event.target as HTMLElement) : false;
    const isEndTimePickerOptions = (this.$refs.endTimePickerOptions as HTMLElement) ?
        (this.$refs.endTimePickerOptions as HTMLElement).contains(event.target as HTMLElement) : false;
    const isEndTimeDropDown = (event.target as HTMLElement).className === 'datetimepicker-range-input-container__dropdown endtime';


    if (!isDatePicker && !this.showEndTime && !this.showStartTime) {
      this.close();
    }

    if (!isStartTimePickerOptions && !isStartTimePicker && !isStartTimeDropDown) {
      this.showStartTime = false;
    }

    if (!isEndTimePicker && !isEndTimePickerOptions && !isEndTimeDropDown) {
      this.showEndTime = false;
    }
  }
  public destroy() {
    this.parentEl.removeEventListener('click', this.clickedOutside);
  }

  public close() {
    const event = {
      startDate: this.startDate,
      endDate: this.endDate,
    };
    this.isOpen = false;
    this.showEndTime = false;
    this.showStartTime = false;
    this.$emit('closed', event);
  }

  public getDisplayDate(): string {
    const inputDateFormat = 'MMM DD, YYYY';
    const start = this.startDate.isValid() ? this.startDate.format(inputDateFormat) : '';
    const end =  this.endDate.isValid() ? this.endDate.format(inputDateFormat) : '';

    if (this.startDate.isSame(this.endDate, 'day')) {
      return `${start}`;
    }

    return `${start} - ${end}`;
  }

  public setInputDisplayDate(): void {
    this.displayStartDate = this.startDate.isValid() ? this.startDate.format('MM/DD/YYYY') : '';
    this.displayEndDate = this.endDate.isValid() ? this.endDate.format('MM/DD/YYYY') : '';
  }

  public resetSideOptions() {
    if (this.sideOptions == null || this.sideOptions?.length === 0) {
      return;
    }

    this.sideOptions.forEach((value) => value.active = false);
  }

  public setStartTime(context: BvTimeCtxEvent) {
    const initialTime = moment(this.initialDates[0]);
    if (context.value !== '') {
      this.displayStartTime = context.formatted;
      const hour = context.hours ?? 0;
      const minute = context.minutes ?? 0;
      const second = context.seconds ?? 0;

      this.startDate.hours(hour);
      this.startDate.minutes(minute);
      this.startDate.seconds(second);

      this.validateTime();
    
      if (initialTime.format(this.timeFormat) !== this.startDate.format(this.timeFormat)) {
        this.setIsCustom();
      }
      this.emit();
    }
  }

  public setEndTime(context: BvTimeCtxEvent) {
    const initialTime = moment(this.initialDates[1]);
    if (context.value !== '') {
      this.displayEndTime = context.formatted;
      const hour = context.hours ?? 0;
      const minute = context.minutes ?? 0;
      const second = context.seconds ?? 0;

      this.endDate.hours(hour);
      this.endDate.minutes(minute);
      this.endDate.seconds(second);
      
      this.validateTime();

      if (initialTime.format(this.timeFormat) !== this.endDate.format(this.timeFormat)) {
        this.setIsCustom();
      }

      this.emit();
    }
  }

  public setIsCustom() {
    this.isCustom = true;
    this.resetSideOptions();
  }

  public validateTime() {
    // if the time is not valid.
    this.startTimeError = !this.startDate.isValid() ? 'Please provide a valid time format' : '';
    this.endTimeError = !this.endDate.isValid() ? 'Please provide a valid time format' : '';

    if (this.startDate.isAfter(this.endDate)) {
      this.startTimeError = `Enter time on/before ${this.displayEndTime}`;
    }

    if (this.endDate.isBefore(this.startDate)) {
       this.endTimeError = `Enter time on/after ${this.displayStartTime}`;
    }
  }

  public emit() {
    const event = {
      startDate: this.startDate,
      endDate: this.endDate,
    };

    if (this.startDate.isValid() && this.endDate.isValid() && this.startTimeError === '' && this.endTimeError === '') {
      this.$emit('onUpdate', event);
    }
  }
}

export interface SideOptions {
  name: string,
  dates: Date[],
  active: boolean,
}

export interface DatePickerRangeEvent {
  startDate: Moment;
  endDate: Moment;
}

export interface DatePickerRangeModel {
  currentDate: Date,
  dateRange: { start: string, end: string},
  multipleDateRange: [],
  selectedDate: boolean,
  selectedDateTime: boolean,
  selectedDates: [],
  selectedDatesItem: string,
  selectedHour: string,
  selectedMinute: string
}

export interface DatePickerRangeConfig {
  sundayStart: boolean,
  newCurrentDate: Date,
  limits: {} | boolean,
  minSelDays: number | boolean,
  maxSelDays: number | boolean,
  placeholder: string | boolean,
  dateFormat: string,
  isDatePicker: boolean,
  isMultipleDatePicker: boolean,
  isMultipleDateRange: boolean,
  isDateRange: boolean,
  withTimePicker: boolean,
  isMultiple: boolean,
  calendarsCount: number,
  isSeparately: boolean,
  isModal: boolean,
  isAutoCloseable: boolean,
  isTypeable: boolean,
  changeMonthFunction: boolean,
  changeYearFunction: boolean,
  changeYearStep: number,
  changeMonthStep: number,
  markedDates: string[],
  markedDateRange: {start: string, end: string},
  disabledDayName: string[],
  disabledDates: string[],
  dayNames: string[],
  monthNames: string[],
  shortMonthNames: string[],
  showWeekNumbers: boolean,
  transition: boolean,
  hiddenElements: string[],
  titlePosition: string,
  arrowsPosition: string,
  isDark: boolean,
  isLayoutExpandable: boolean,
  alwaysUseDefaultClasses: boolean
}
