import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '@/store';
import sharedAxiosInstance from '@/services/api-service';
import ConfigFactory from '@/services/config';
import { AgentsService } from '@/services/agents-service';
import { ActiveStatus } from '@/enum/agent-status';
import { IAgentViewModel } from '@/view-models/i-agent-view-model';
import { IAgentCertificateResponse } from '@/services/response-models/agent-certificate-response';

export const name: string = 'agentSelected';

export const types = {
    getters: {
    },
    mutations: {
        SET_SELECTED_AGENT:'setSelectedAgent'
    }
};

export interface IAgentSelectedStoreState {
    selectedAgent: IAgentViewModel;
}

export interface AgentStoreGetters extends GetterTree<IAgentSelectedStoreState, IRootState> {
    getSelectedAgent(state: IAgentSelectedStoreState): IAgentViewModel;
}

export interface AgentStoreMutations extends MutationTree<IAgentSelectedStoreState> {
    setSelectedAgent(state: IAgentSelectedStoreState, agentInfo: IAgentViewModel): void;
}

export interface AgentStoreActions extends ActionTree<IAgentSelectedStoreState, IRootState> {
    getCertificate(context: AgentContext): void;
    createAgent(context: AgentContext, createAgentInfo: IAgentViewModel): Promise<void>;
    updateAgent(context: AgentContext, updateAgentInfo: IAgentViewModel): Promise<void>;
    setSelectedAgent(context: AgentContext, agentInfo: IAgentViewModel): Promise<void>;
}

const initAgentsService = async () => {
    const conf = await ConfigFactory.GetConfig();
    return new AgentsService(sharedAxiosInstance.sharedAgentAxiosInstance,
        process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL ?
          process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL :
          conf.get('pcApiUrl'));
};

export type AgentContext = ActionContext<IAgentSelectedStoreState, IRootState>;

export const AgentSelectedStore = {
    namespaced: true,
    state: {
        selectedAgent: {}
    } as IAgentSelectedStoreState,
    getters:  {
        getSelectedAgent(state: IAgentSelectedStoreState): IAgentViewModel {
            return state.selectedAgent;
        }
    } as AgentStoreGetters,
    mutations: {
        setSelectedAgent(state: IAgentSelectedStoreState, agentInfo: IAgentViewModel) {
            state.selectedAgent = agentInfo;
        }
    } as AgentStoreMutations,
    actions: {
        async getCertificate(context: AgentContext): Promise<void> {
            const agentsService = await initAgentsService();
            if (context.state.selectedAgent) {
                await agentsService
                  .getBootstrapCertificate(context.state.selectedAgent).then((response) => {
                   if (response) {
                    const contentType= 'application/json';
                    downloadCertificateData(response, contentType);
                   }
                });
            }
        },
        async getInstaller(context: AgentContext): Promise<void> {
            const agentsService = await initAgentsService();
            if (context.state.selectedAgent) {
                await agentsService
                  .getAgentInstaller(context.state.selectedAgent).then((response) => {
                   if (response) {
                        window.location.href = response.preSignedUrl;
                   }
                });
            }
        },
        async createAgent(context: AgentContext, createAgentInfo: IAgentViewModel): Promise<void> {
            const agentsService = await initAgentsService();
            const createdAgent: IAgentViewModel = await agentsService.createAgent(createAgentInfo);
            context.commit(types.mutations.SET_SELECTED_AGENT, createdAgent);
        },
        async updateAgent(context: AgentContext, updateAgentInfo: IAgentViewModel): Promise<void> {
            const agentsService = await initAgentsService();
            const updatedAgent: IAgentViewModel = await agentsService.updateAgent(updateAgentInfo);
            context.commit(types.mutations.SET_SELECTED_AGENT, updatedAgent);
        },
        async toggleAgentStatus(context: AgentContext, agentInfo: IAgentViewModel): Promise<void> {
            const agentsService = await initAgentsService();
            const updatedAgent: IAgentViewModel = agentInfo.status === ActiveStatus.Disabled ?
                await agentsService.enableAgent(agentInfo) :
                await agentsService.disableAgent(agentInfo);
            const index = context.rootState.agent.agents.findIndex((agentKey: IAgentViewModel) =>
              agentKey.agentKey === updatedAgent.agentKey);
            if (index > -1) {
                context.rootState.agent.agents.splice(index, 1, updatedAgent);
            }
            context.commit(types.mutations.SET_SELECTED_AGENT, updatedAgent);
        },
        async getAgentInfo(context: AgentContext, agentInfo: IAgentViewModel): Promise<void> {
            context.dispatch (types.mutations.SET_SELECTED_AGENT, agentInfo);
        },
        async getSpecificAgent(context: AgentContext, agentkey: string): Promise<void> {
            const agentsService = await initAgentsService();
            const agentData: IAgentViewModel = await agentsService
                .getSpecificAgent(agentkey,context.rootState.agent.specificCustomerKey);
            context.commit(types.mutations.SET_SELECTED_AGENT, agentData);
        },
        async setSelectedAgent(context: AgentContext, agentInfo: IAgentViewModel): Promise<void> {
            context.commit(types.mutations.SET_SELECTED_AGENT, agentInfo);
        }
    } as AgentStoreActions
};

export function downloadCertificateData(data: IAgentCertificateResponse, contentType: string) {
    const fileName = 'agent-certificate-'+data.agentKey+'.json';
    const downloadData = JSON.stringify(data, null, 2);
    const url = window.URL.createObjectURL(new Blob([downloadData], { type: contentType }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.target = '_blank';
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
}

