import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '@/store';
import sharedAxiosInstance from '@/services/api-service';
import ConfigFactory from '@/services/config';
import { TaskDefinitionService } from '@/services/task-definition-service';
import { ActiveStatus } from '@/enum/agent-status';
import { IDictionary, ITaskDefinitionViewModel, TaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import AuthService from '@/services/auth-service';
import { IAsset } from '@/view-models/i-asset-view-model';
import { Vue } from 'vue-property-decorator';
import { IAccordianDetails } from '@/view-models/i-accordian';
import { translatableResources } from '@/static/locales/translatableResources';
import { StepProgressState } from '@/enum/step-progress-state';
import { AgentsService } from '@/services/agents-service';
import { IResponseMessage, Tag } from '@/view-models/i-tag-view-model';

export const name: string = 'task';

export const types = {
    getters: {
    },
    mutations: {
        SET_TASK_DEFINITIONS_LIST: 'setTaskDefinitionsList',
        ADD_TASK_DEFINITION_INFO: 'addTaskDefinitionInfo',
        UPDATE_TASK_DEFINITION_INFO: 'updateTaskDefinitionInfo',
        SET_ASSETS: 'setAssets',
        SET_TASK_DEFINITIONS_LOADED:'setTaskDefinitionsLoaded',
        UPDATE_SPECIFIC_TASK_DEFINITION: 'updateSpecificTaskDefinition',
        SET_ACCORDAIN_DETAILS: 'setAccordianDetails',
        SET_SPECIFIC_TASK_DEFINITION: 'setSpecificTaskDefintion',
        SET_SECTION_DETAILS: 'setSectionDetails',
        UPDATE_SECTION_DETAILS: 'updateSectionDetails',
        UPDATE_ACCORDIAN_DETAIL: 'updateAccordianDetail'
    },
    actions: {
        GET_TASK_DEFINITIONS_LIST: 'getTaskDefinitionsList',
    }
};

export interface ITaskDefinitionStoreState {
    taskDefinitionsList: ITaskDefinitionViewModel[];
    assets: IAsset[];
    isTaskDefinitionsLoaded: boolean;
    accordianDetails: IAccordianDetails[];
    specificTaskDefinition: ITaskDefinitionViewModel;
    sectionDetails: ITaskDefinitionViewModel;
    selectedTaskType: string;
    keyValuePairs: IDictionary[];
}

export interface TaskDefinitionStoreGetters extends GetterTree<ITaskDefinitionStoreState, IRootState> {
    getSpecificTaskDefinition(state: ITaskDefinitionStoreState): ITaskDefinitionViewModel;
    getAssets(state: ITaskDefinitionStoreState): IAsset[];
    hasHierarchyKey(state: ITaskDefinitionStoreState): boolean;
    getSelectedTaskType(state: ITaskDefinitionStoreState): string;
    getTaskDefinitionsList(state: ITaskDefinitionStoreState): TaskDefinitionViewModel[];
}

export interface TaskDefinitionStoreMutations extends MutationTree<ITaskDefinitionStoreState> {
    setTaskDefinitionsList(state: ITaskDefinitionStoreState, taskDefinitionsList: ITaskDefinitionViewModel[]): void;
    addTaskDefinitionInfo(state: ITaskDefinitionStoreState, taskDefinitionInfo: ITaskDefinitionViewModel): void;
    updateTaskDefinitionInfo(state: ITaskDefinitionStoreState, taskDefinitionInfo: ITaskDefinitionViewModel): void;
    setAssets(state: ITaskDefinitionStoreState, assets: IAsset[]): void;
    setTaskDefinitionsLoaded(state: ITaskDefinitionStoreState, isLoaded: boolean): void;
    updateSpecificTaskDefinition(state: ITaskDefinitionStoreState, taskDefinition: ITaskDefinitionViewModel): void;
    setAccordianDetails(state: ITaskDefinitionStoreState, details: IAccordianDetails[]): void;
    setSpecificTaskDefintion(state: ITaskDefinitionStoreState, taskDefinition: ITaskDefinitionViewModel): void;
    setSectionDetails(state: ITaskDefinitionStoreState, sectionInfo: ITaskDefinitionViewModel): void;
    updateSectionDetails(state: ITaskDefinitionStoreState, sectionInfo: ITaskDefinitionViewModel): void;
    updateTaskType(state: ITaskDefinitionStoreState, type: string): void;
    updateAccordianDetail(state: ITaskDefinitionStoreState, detail: IAccordianDetails): void;
}

export interface TaskDefinitionStoreActions extends ActionTree<ITaskDefinitionStoreState, IRootState> {
    loadAssets(context: TaskDefinitionContext): void;
    getTaskDefinitionsList(context: TaskDefinitionContext): void;
    createTaskDefinition(context: TaskDefinitionContext, createTDInfo: ITaskDefinitionViewModel): Promise<void>;
    updateTaskDefinition(context: TaskDefinitionContext, updateTDInfo: ITaskDefinitionViewModel): Promise<void>;
    getAccordianDetails(context: TaskDefinitionContext): void;
    getSpecificTaskDefinition(context: TaskDefinitionContext, key: string): void;
    setTaskDefinitionsLoaded(context: TaskDefinitionContext, isLoaded: boolean): Promise<void>;
    updateAccordianDetail(context: TaskDefinitionContext, detail: IAccordianDetails): Promise<void>;
    resetState(context: TaskDefinitionContext): Promise<void>;
}

export const initAuthService = async () => {
    const conf = await ConfigFactory.GetConfig();
    return new AuthService(sharedAxiosInstance.sharedKesAxiosInstance,
        process.env.VUE_APP_KES_PORTAL_API_BASE_URL ?
          process.env.VUE_APP_KES_PORTAL_API_BASE_URL :
          conf.get('userManagementApi'));
};

const initTaskDefinitionService = async () => {
    const conf = await ConfigFactory.GetConfig();
    return new TaskDefinitionService(sharedAxiosInstance.sharedAgentAxiosInstance,
        process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL ?
          process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL :
          conf.get('pcApiUrl'));
};

const initAgentsService = async () => {
    const conf = await ConfigFactory.GetConfig();
    return new AgentsService(sharedAxiosInstance.sharedAgentAxiosInstance,
        process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL ?
          process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL :
          conf.get('pcApiUrl'));
};

export type TaskDefinitionContext = ActionContext<ITaskDefinitionStoreState, IRootState>;

export const TaskDefinitionStore = {
    namespaced: true,
    state: {
        taskDefinitionsList: Array<ITaskDefinitionViewModel>(),
        assets: Array<IAsset>(),
        isTaskDefinitionsLoaded: false,
        accordianDetails: Array<IAccordianDetails>(),
        specificTaskDefinition: {},
        sectionDetails: {},
        selectedTaskType: '',
        keyValuePairs: Array<IDictionary>()
    } as ITaskDefinitionStoreState,
    getters:  {
        getSpecificTaskDefinition(state: ITaskDefinitionStoreState): ITaskDefinitionViewModel {
            return state.specificTaskDefinition;
        },
        getAssets(state: ITaskDefinitionStoreState): IAsset[]  {
            return state.assets;
        },
        hasHierarchyKey(state: ITaskDefinitionStoreState): boolean {
            return !!state.specificTaskDefinition?.keyValues?.HierarchyKey ?? false;
        },
        getSelectedTaskType(state: ITaskDefinitionStoreState): string {
            return state.selectedTaskType;
        },
        getTaskDefinitionsList(state: ITaskDefinitionStoreState): ITaskDefinitionViewModel[] {
            return state.taskDefinitionsList;
        }
    } as TaskDefinitionStoreGetters,
    mutations: {
        setTaskDefinitionsList(state: ITaskDefinitionStoreState,
                               taskDefinitionsList: ITaskDefinitionViewModel[]): void {
            state.taskDefinitionsList = taskDefinitionsList;
        },
        addTaskDefinitionInfo(state: ITaskDefinitionStoreState, taskDefinitionInfo: ITaskDefinitionViewModel): void {
            state.taskDefinitionsList.push(taskDefinitionInfo);
        },
        updateTaskDefinitionInfo(state: ITaskDefinitionStoreState, taskDefinitionInfo: ITaskDefinitionViewModel): void {
            const index = state.taskDefinitionsList.findIndex((td) =>
              td.taskDefinitionKey === taskDefinitionInfo.taskDefinitionKey);
            if (index > -1) {
                state.taskDefinitionsList.splice(index, 1, taskDefinitionInfo);
            }
        },
        setAssets(state: ITaskDefinitionStoreState, assets: IAsset[]): void {
            state.assets = assets;
        },
        setTaskDefinitionsLoaded(state: ITaskDefinitionStoreState, isLoaded: boolean): void {
            state.isTaskDefinitionsLoaded = isLoaded;
        },
        updateSpecificTaskDefinition(state: ITaskDefinitionStoreState, taskDefinition: ITaskDefinitionViewModel): void {
            const tdIndex: number = state.taskDefinitionsList
                .findIndex((d: ITaskDefinitionViewModel) => d.taskDefinitionKey === taskDefinition.taskDefinitionKey);
            if (tdIndex !== -1) {
                Vue.set(state.taskDefinitionsList, tdIndex, taskDefinition);
            } else {
                state.taskDefinitionsList.push(taskDefinition);
            }
        },
        setAccordianDetails(state: ITaskDefinitionStoreState, details: IAccordianDetails[]): void {
            state.accordianDetails = [];
            state.accordianDetails = details;
        },
        setSpecificTaskDefintion(state: ITaskDefinitionStoreState, taskDefinition: ITaskDefinitionViewModel): void {
            Vue.set(state, 'specificTaskDefinition', taskDefinition);
        },
        setSectionDetails(state: ITaskDefinitionStoreState, sectionInfo: ITaskDefinitionViewModel): void {
            state.sectionDetails = sectionInfo;
        },
        updateSectionDetails(state: ITaskDefinitionStoreState, sectionInfo: ITaskDefinitionViewModel): void {
            Object.assign(state.sectionDetails, sectionInfo);
        },
        updateTaskType(state: ITaskDefinitionStoreState, type: string): void {
            state.selectedTaskType = type;
        },
        updateKeyValuePairs(state: ITaskDefinitionStoreState, keyValuePairs: IDictionary[]): void {
            state.keyValuePairs = keyValuePairs;
        },
        updateAccordianDetail(state: ITaskDefinitionStoreState, detail: IAccordianDetails): void {
            const filteredIndex = state.accordianDetails.findIndex((accordian) => accordian.id === detail.id);

            if (filteredIndex) {
                state.accordianDetails[filteredIndex] = detail;
            }
        }
    } as TaskDefinitionStoreMutations,
    actions: {
        async loadAssets(context: TaskDefinitionContext): Promise<void> {
            const authService = await initAuthService();
            const response = await authService.getCustomerAssets();
            context.commit(types.mutations.SET_ASSETS, response);
        },
        async getTaskDefinitionsList(context: TaskDefinitionContext): Promise<void> {
            const taskDefinitionService = await initTaskDefinitionService();
            if (context.rootState.agentSelected.selectedAgent.agentKey &&
                !context.state.isTaskDefinitionsLoaded) {
                context.commit(types.mutations.SET_TASK_DEFINITIONS_LIST, []);
                await taskDefinitionService.getTaskDefinitions(context.rootState.agentSelected.selectedAgent.agentKey)
                 .then((response: ITaskDefinitionViewModel[]) => {
                    response.sort((a, b) => a.name.replace(/ /g, '').localeCompare(b.name.replace(/ /g, ''), undefined, {numeric: true, sensitivity: 'base'}));
                    context.commit(types.mutations.SET_TASK_DEFINITIONS_LIST, response);
                    context.commit(types.mutations.SET_TASK_DEFINITIONS_LOADED,true);
                });
            }
        },
        async createTaskDefinition(context: TaskDefinitionContext, taskInfo: ITaskDefinitionViewModel): Promise<void> {
            const taskDefinitionService = await initTaskDefinitionService();
            const createdTD: ITaskDefinitionViewModel = await taskDefinitionService.createTaskDefinition(taskInfo);
            context.commit(types.mutations.ADD_TASK_DEFINITION_INFO, createdTD);
        },
        async updateTaskDefinition(context: TaskDefinitionContext, taskInfo: ITaskDefinitionViewModel): Promise<void> {
            const taskDefinitionService = await initTaskDefinitionService();
            const updatedTD: ITaskDefinitionViewModel = await taskDefinitionService.updateTaskDefinition(taskInfo);
            context.commit(types.mutations.UPDATE_TASK_DEFINITION_INFO, updatedTD);
            context.commit(types.mutations.SET_SPECIFIC_TASK_DEFINITION, updatedTD);
        },
        async toggleTaskDefinitionStatus(context: TaskDefinitionContext, taskInfo: ITaskDefinitionViewModel):
            Promise<void> {
            const taskDefinitionService = await initTaskDefinitionService();
            const updatedTD: ITaskDefinitionViewModel = taskInfo.status === ActiveStatus.Disabled ?
                await taskDefinitionService.enableTaskDefinition(taskInfo) :
                await taskDefinitionService.disableTaskDefinition(taskInfo);
            context.commit(types.mutations.UPDATE_TASK_DEFINITION_INFO, updatedTD);
        },
        getAccordianDetails(context: TaskDefinitionContext): void {
            const details = [
                {
                    id: 1,
                    title: translatableResources.taskDefinition.taskDetails,
                    expand: true,
                    state: StepProgressState.Pending,
                    show: true
                },
                {
                    id: 2,
                    title: translatableResources.taskDefinition.selectTags,
                    expand: false,
                    state: StepProgressState.Pending,
                    show: true
                },
                {
                    id: 3,
                    title: translatableResources.taskDefinition.specifyKeyValuesOptional,
                    expand: false,
                    state: StepProgressState.Pending,
                    show: false
                }
            ];
            context.commit(types.mutations.SET_ACCORDAIN_DETAILS, details);
        },
        async getSpecificTaskDefinition(context: TaskDefinitionContext, key): Promise<void> {
            const taskDefinitionService = await initTaskDefinitionService();
            const taskData: ITaskDefinitionViewModel = await taskDefinitionService
                .getSpecificTaskDefinition(context.rootState.agentSelected.selectedAgent.agentKey, key);

            // Check to see if the data source is set for this task and if not, set it.
            // This is necessary when navigating directly to the task detail page.
            if (taskData && context.rootState.dataSource.selectedDataSource?.dataSourceKey !== taskData.dataSourceKey) {
                if (context.rootState.dataSource.dataSourceList.length === 0) {
                    await context.dispatch('dataSource/getDataSourceList',null, {root: true});
                    const datasource = context.rootState.dataSource.dataSourceList.find((ds) =>
                        ds.dataSourceKey === taskData.dataSourceKey);
                    if (datasource) {
                        context.commit('dataSource/setSelectedDataSource', datasource, {root: true});
                    }
                }
            }
            context.commit(types.mutations.SET_SPECIFIC_TASK_DEFINITION, taskData);
        },
        async setTaskDefinitionsLoaded(context: TaskDefinitionContext, isLoaded: boolean): Promise<void> {
            context.commit (types.mutations.SET_TASK_DEFINITIONS_LOADED, isLoaded);
        },
        async setSpecificTaskDefinition(context: TaskDefinitionContext,
                                        taskDefinition: ITaskDefinitionViewModel): Promise<void> {
            context.commit(types.mutations.SET_SPECIFIC_TASK_DEFINITION, taskDefinition);
        },
        async updateAccordianDetail(context: TaskDefinitionContext, detail: IAccordianDetails): Promise<void> {
            context.commit(types.mutations.UPDATE_ACCORDIAN_DETAIL, detail);
        },
        async resetState(context: TaskDefinitionContext): Promise<void> {
            context.commit(types.mutations.SET_SPECIFIC_TASK_DEFINITION, {});
            context.commit(types.mutations.SET_SECTION_DETAILS, {});
        },
        async triggerRun(context: TaskDefinitionContext, taskInfo: ITaskDefinitionViewModel): Promise<{token: string, url: string}> {
            const taskDefinitionService = await initTaskDefinitionService();
            return taskDefinitionService.triggerAgentRun(taskInfo);
                
        },
        async validateTagsByFile(context: TaskDefinitionContext, payload: {file: File, customerKey: string} ): Promise<IResponseMessage<Tag[]>> {
            const agentService = await initAgentsService();
            return agentService.validateTagsByFile(payload.file, payload.customerKey);
        },
        async validateTagName(context: TaskDefinitionContext, payload: {tags: string[] , customerKey: string} ): Promise<IResponseMessage<Tag[]>> {
            const agentService = await initAgentsService();
            return agentService.validateTagName(payload.tags, payload.customerKey);
        },
        async validateTags(context: TaskDefinitionContext, payload: {agentKey: string, taskKey: string}): Promise<void> {
            const taskDefinitionService = await initTaskDefinitionService();
            return taskDefinitionService.validateTags(payload.agentKey, payload.taskKey);
        }
    } as TaskDefinitionStoreActions
};
