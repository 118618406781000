













import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';
import { ITaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import store from '@/store';
import HelperMethods from '@/shared/helper-methods';
import { IAccordianDetails } from '@/view-models/i-accordian';
import { StepProgressState } from '@/enum/step-progress-state';
import { IAsset } from '@/view-models/i-asset-view-model';
import { IBSelect } from '@/view-models/shared/b-select';
import { HierarchicalTaskTypes, TagBasedTaskTypes } from '@/enum/task-definition-type';
import { AssetType } from '@/enum/asset-type';
import TypeAheadDropdown, { TypeAheadItem } from '@/components/common/TypeAheadDropdown.vue';
import { cloneDeep } from 'lodash';
@Component({
  name: 'root-node-details-view',
  components: {
    TypeAheadDropdown
  }
})
export default class RootNodeDetailsView extends BaseComponent {
  @Prop({required: true})
  private stepData!: IAccordianDetails;
  private rootNodePath: string = '';
  private assetSelected: string = '';
  private dropdownOptions: TypeAheadItem[] = [];

  get isHealthy(): boolean {
    return this.specificTaskDefinition.status === this.runningStatuses.Healthy;
  }
  get specificTaskDefinition(): ITaskDefinitionViewModel {
    return store.state.taskDefinition.specificTaskDefinition;
  }
  get taskType(): string {
    return store.state.taskDefinition.selectedTaskType;
  }
  get assetOptions(): TypeAheadItem[] {
    let options: IBSelect[] | TypeAheadItem[]= [];
    switch (this.taskType) {
      case HierarchicalTaskTypes.Ember:
        options = this.getAssetsByType(this.emberAssetTypes);
        break;
      case HierarchicalTaskTypes.TowerView:
        options = this.getAssetsByType(this.towerAssetTypes);
        break;
      default:
        options = this.getAssetsByType([]);
        break;
    }
    return options;
  }
  get emberAssetTypes(): IAsset[] {
    return store.state.taskDefinition.assets.filter((a) =>
           (a.type === AssetType.EmberType && a.entityType === AssetType.EmberType));
  }
  get towerAssetTypes(): IAsset[] {
    return store.state.taskDefinition.assets.filter((a) =>
           (a.type === AssetType.TowerType && a.entityType === AssetType.TowerType));
  }
  get selectedAsset(): string {
    return this.assetSelected;
  }
  set selectedAsset(asset: string) {
    this.assetSelected = !HelperMethods.isStringEmpty(asset)? asset : '';
  }
  get assetName(): string {
    if (this.assetOptions.length > 0) {
      const index = this.assetOptions.findIndex((a) => a.value === this.selectedAsset);
      return this.assetOptions[index]?.value;
    }
    return '';
  }

  get assetDisplayName(): string {
     if (this.assetOptions.length > 0) {
      const index = this.assetOptions.findIndex((a) => a.value === this.selectedAsset);

      if (index > -1) {
        return this.assetOptions[index].name;
      }
    }
    return '';
  }

  public mounted(): void {
    this.loadTaskData();
    this.watchValues();
  }

  public updated(): void {
    this.watchValues();
    const typeahead = this.$refs['typeahead'] as TypeAheadDropdown;

    if (typeahead) {
      typeahead.selectedType = this.assetDisplayName;
    }
  }
  public async loadTaskData(): Promise<void> {
    if (this.$route.params.taskid) {
      this.setTaskDefinitionInfo(this.specificTaskDefinition);
    } else {
      this.resetTaskDefinitionInfo();
    }

    if (this.$route.params.taskid == null) {
      this.selectedAsset = '';
    }
  }

  public setSelectedType($event: TypeAheadItem) {
    this.selectedAsset = $event.value;
  }

  @Watch('rootNodePath')
  public watchValues(): void {
    const updatedObject: ITaskDefinitionViewModel = {} as ITaskDefinitionViewModel;
    updatedObject.rootNodePath = this.rootNodePath;
    updatedObject.assetKey = this.selectedAsset;
    updatedObject.assetName = this.assetDisplayName;
    this.updateStepProgress(!this.isEmpty(updatedObject));
    store.commit('taskDefinition/updateSectionDetails', updatedObject);
  }
  public isEmpty(values: ITaskDefinitionViewModel): boolean {
    return Object.values(values).some((x) => (HelperMethods.isStringEmpty(x)));
  }
  public async updateStepProgress(value: boolean): Promise<void> {
    if (value) {
      this.stepData.state = StepProgressState.Completed;
    } else {
      this.stepData.state = StepProgressState.Pending;
    }
    await store.dispatch('taskDefinition/updateAccordianDetail', this.stepData);
  }
  public setTaskDefinitionInfo(data: ITaskDefinitionViewModel): void {
    if (data.name) {
      this.rootNodePath = data.rootNodePath;
      this.selectedAsset = data.assetKey;
    }
  }
  public resetTaskDefinitionInfo(): void {
    this.rootNodePath = '';
    if (this.assetOptions.length > 0) {
      this.selectedAsset = this.assetOptions[0].value;
    }
  }
  public getAssetsByType(assets: IAsset[]): TypeAheadItem[] {
    const sorted = cloneDeep(assets);
    sorted.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
    if (sorted.length > 0) {
      return sorted.map((asset) => ({ value: asset.key, name: asset.name, active: this.selectedAsset === asset.key ? true : false}));
    }
    return [];
  }
}
