import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '@/store';
import sharedAxiosInstance from '@/services/api-service';
import ConfigFactory from '@/services/config';
import { DataSourceService } from '@/services/data-source-service';
import { ActiveStatus } from '@/enum/agent-status';
import { IDataSourceViewModel } from '@/view-models/i-data-source-view-model';
import { Vue } from 'vue-property-decorator';
import { ITagViewModel, ITag, ITagData } from '@/view-models/i-tag-view-model';
import { AgentsService } from '@/services/agents-service';
import HelperMethods from '@/shared/helper-methods';

export const name: string = 'dataSource';

export const types = {
    getters: {
    },
    mutations: {
        SET_DATA_SOURCE_LIST: 'setDataSourceList',
        ADD_DATA_SOURCE_INFO: 'addDataSourceInfo',
        UPDATE_DATA_SOURCE_INFO: 'updateDataSourceInfo',
        SET_DATA_SOURCES_LOADED: 'setDataSourcesLoaded',
        UPDATE_SPECIFIC_DATA_SOURCE: 'updateSpecificDataSource',
        SET_SELECTED_DATA_SOURCE: 'setSelectedDataSource',
        SET_AVAILABLE_TAGS: 'setAvailableTags',
        UPDATE_SELECTED_TAGS: 'updateSelectedTags',
        SET_SELECTED_TAGS: 'setSelectedTags'
    },
    actions: {
        GET_DATA_SOURCE_LIST: 'getDataSourceList',
    }
};

export const emptyTagViewModel: ITagViewModel = {
    createdOnUtc: '',
    dataSourceKey: '',
    tags: new Array<ITagData>()
};

export interface IDataSourceStoreState {
    dataSourceList: IDataSourceViewModel[];
    isDataSourceLoaded: boolean;
    selectedDataSource: IDataSourceViewModel;
    availableTags: ITagViewModel;
    selectedTags: ITagData[];
    selectedTag: ITagData;
    isLoadingTags: boolean;
}

export interface DataSourceStoreGetters extends GetterTree<IDataSourceStoreState, IRootState> {
    hasRefreshedTags(state: IDataSourceStoreState): boolean;
    getSelectedDataSource(state: IDataSourceStoreState): IDataSourceViewModel;
    getSelectedTags(state: IDataSourceStoreState): ITag[];
    getDataSourceList(state: IDataSourceStoreState): IDataSourceViewModel[];
    isLoadingTags(state: IDataSourceStoreState): boolean;
}

export interface DataSourceStoreMutations extends MutationTree<IDataSourceStoreState> {
    setDataSourceList(state: IDataSourceStoreState, dataSourceList: IDataSourceViewModel[]): void;
    addDataSourceInfo(state: IDataSourceStoreState, addedDSInfo: IDataSourceViewModel): void;
    updateDataSourceInfo(state: IDataSourceStoreState, updatedDSInfo: IDataSourceViewModel): void;
    setDataSourcesLoaded(state: IDataSourceStoreState, isLoaded: boolean): void;
    updateSpecificDataSource(state: IDataSourceStoreState, dataSource: IDataSourceViewModel): void;
    setSelectedDataSource(state: IDataSourceStoreState, dataSource: IDataSourceViewModel): void;
    setAvailableTags(state: IDataSourceStoreState, tagInfo: ITagViewModel): void;
    updateSelectedTags(state: IDataSourceStoreState, tag: ITag): void;
    setIsLoadingTags(state: IDataSourceStoreState, flag: boolean): void;
}

export interface DataSourceStoreActions extends ActionTree<IDataSourceStoreState, IRootState> {
    getDataSourceList(context: DataSourceContext): void;
    createDataSource(context: DataSourceContext, createDSInfo: IDataSourceViewModel): Promise<void>;
    updateDataSource(context: DataSourceContext, updateDSInfo: IDataSourceViewModel): Promise<void>;
    toggleDataSourceStatus(context: DataSourceContext, updateDSInfo: IDataSourceViewModel): Promise<void>;
    setDataSourcesLoaded(context: DataSourceContext, isLoaded: boolean): Promise<void>;
    getAvailableTags(context: DataSourceContext, flag: boolean): void;
    updateSelectedTags(context: DataSourceContext, tag: ITag): Promise<void>;
}

const initDataSourceService = async () => {
    const conf = await ConfigFactory.GetConfig();
    return new DataSourceService(sharedAxiosInstance.sharedAgentAxiosInstance,
        process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL ?
          process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL :
          conf.get('pcApiUrl'));
};
const initAgentsService = async () => {
    const conf = await ConfigFactory.GetConfig();
    return new AgentsService(sharedAxiosInstance.sharedAgentAxiosInstance,
        process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL ?
          process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL :
          conf.get('pcApiUrl'));
};

export type DataSourceContext = ActionContext<IDataSourceStoreState, IRootState>;

export const DataSourceStore = {
    namespaced: true,
    state: {
        dataSourceList: Array<IDataSourceViewModel>(),
        isDataSourceLoaded: false,
        selectedDataSource: {},
        availableTags: {},
        selectedTags: Array<ITag>(),
        selectedTag: {},
        isLoadingTags: false
    } as IDataSourceStoreState,
    getters:  {
        getSelectedDataSource(state: IDataSourceStoreState): IDataSourceViewModel {
            return state.selectedDataSource;
        },
        getSelectedTags(state: IDataSourceStoreState): ITag[] {
            return state.selectedTags;
        },
        getDataSourceList(state: IDataSourceStoreState): IDataSourceViewModel[] {
            return state.dataSourceList;
        },
        isLoadingTags(state: IDataSourceStoreState): boolean {
            return state.isLoadingTags;
        }
    } as DataSourceStoreGetters,
    mutations: {
        setDataSourceList(state: IDataSourceStoreState, dataSourceList: IDataSourceViewModel[]): void {
            state.dataSourceList = dataSourceList;
        },
        addDataSourceInfo(state: IDataSourceStoreState, addedDSInfo: IDataSourceViewModel) {
            const index = state.dataSourceList.findIndex((ds) => ds.dataSourceKey === '');
            if (index > -1) {
                state.dataSourceList.splice(index, 1, addedDSInfo);
            }
        },
        updateDataSourceInfo(state: IDataSourceStoreState, updatedDSInfo: IDataSourceViewModel) {
            const index = state.dataSourceList.findIndex((ds) => ds.dataSourceKey === updatedDSInfo.dataSourceKey);
            if (index > -1) {
                state.dataSourceList.splice(index, 1, updatedDSInfo);
            }
        },
        setDataSourcesLoaded(state: IDataSourceStoreState, isLoaded: boolean) {
            state.isDataSourceLoaded = isLoaded;
        },
        updateSpecificDataSource(state: IDataSourceStoreState, dataSource: IDataSourceViewModel): void {
            const dsIndex: number = state.dataSourceList
                .findIndex((d: IDataSourceViewModel) => d.dataSourceKey === dataSource.dataSourceKey);
            if (dsIndex !== -1) {
                Vue.set(state.dataSourceList, dsIndex, dataSource);
                if (state.selectedDataSource.dataSourceKey === dataSource.dataSourceKey) {
                    state.selectedDataSource = dataSource;
                }
            } else {
                state.dataSourceList.push(dataSource);
            }
        },
        setSelectedDataSource(state: IDataSourceStoreState, dataSource: IDataSourceViewModel): void {
            state.selectedDataSource = dataSource;
        },
        setAvailableTags(state: IDataSourceStoreState, tagInfo: ITagViewModel): void {
            state.availableTags = tagInfo;
        },
        setSelectedTags(state: IDataSourceStoreState, tags: ITagData[]): void {
            state.selectedTags = tags;
        },
        updateSelectedTags(state: IDataSourceStoreState, tag: ITagData): void {
            const tagIndex: number = state.selectedTags
                .findIndex((d: ITag) => d.key === tag.key);

            if (tagIndex === -1) {
                state.selectedTags.unshift(tag);
            } else if (tagIndex !== -1) {
                state.selectedTags[tagIndex] = tag;
            } else if (tagIndex !== -1) {
                state.selectedTags.splice(tagIndex, 1);
            }
        },
        deleteFromSelectedTags(state: IDataSourceStoreState, tag: ITag): void {
            const tagIndex: number = state.selectedTags.findIndex((item: ITag) => item.key === tag.key);
            if (tagIndex > -1) {
              state.selectedTags.splice(tagIndex, 1);
            }
        },
        setIsLoadingTags(state: IDataSourceStoreState, flag: boolean) : void {
            state.isLoadingTags = flag;
        }
    } as DataSourceStoreMutations,
    actions: {
        async getDataSourceList(context: DataSourceContext): Promise<void> {
            const dataSourceService = await initDataSourceService();
            if (context.rootState.agentSelected.selectedAgent.agentKey && !context.state.isDataSourceLoaded) {
                context.commit(types.mutations.SET_DATA_SOURCE_LIST, []);
                await dataSourceService.getDataSource(context.rootState.agentSelected.selectedAgent.agentKey)
                 .then((response: IDataSourceViewModel[]) => {
                    context.commit(types.mutations.SET_DATA_SOURCE_LIST, response);
                    context.commit(types.mutations.SET_DATA_SOURCES_LOADED,true);
                });
            }
        },
        async createDataSource(context: DataSourceContext, createDSInfo: IDataSourceViewModel): Promise<void> {
            const dataSourceService = await initDataSourceService();
            const createdDS: IDataSourceViewModel = await dataSourceService.createDataSource(createDSInfo);
            context.commit(types.mutations.ADD_DATA_SOURCE_INFO, createdDS);
        },
        async updateDataSource(context: DataSourceContext, updateDSInfo: IDataSourceViewModel): Promise<void> {
            const dataSourceService = await initDataSourceService();
            const updatedDS: IDataSourceViewModel = await dataSourceService.updateDataSource(updateDSInfo);
            context.commit(types.mutations.UPDATE_DATA_SOURCE_INFO, updatedDS);
        },
        async toggleDataSourceStatus(context: DataSourceContext, dsInfo: IDataSourceViewModel): Promise<void> {
            const dataSourceService = await initDataSourceService();
            const updatedDS: IDataSourceViewModel = dsInfo.status === ActiveStatus.Disabled ?
                await dataSourceService.enableDataSource(dsInfo) :
                await dataSourceService.disableDataSource(dsInfo);
            context.commit(types.mutations.UPDATE_DATA_SOURCE_INFO, updatedDS);
        },
        async setDataSourcesLoaded(context: DataSourceContext, isLoaded: boolean): Promise<void> {
            context.commit (types.mutations.SET_DATA_SOURCES_LOADED, isLoaded);
        },
        async getAvailableTags(context: DataSourceContext, flag: boolean): Promise<void> {
            const service = await initAgentsService();
            if (context.state.selectedDataSource.dataSourceKey) {
                const tagData: ITagViewModel = await service
                .getAvailableTags(context.state.selectedDataSource);

                // Add case insensitive name for comparison.
                if (!!tagData.tags) {
                    tagData.tags.forEach((tag) => tag.previousValue = tag.name);
                }

                if (!flag) {
                    tagData.tags != null ?
                        context.commit(types.mutations.SET_AVAILABLE_TAGS, tagData) :
                        context.commit(types.mutations.SET_AVAILABLE_TAGS, emptyTagViewModel);
                } else {
                    const dateNew = HelperMethods.convertDate(tagData.createdOnUtc);
                    const dateOld = HelperMethods.convertDate(context.state.availableTags.createdOnUtc);
                    if (dateNew > dateOld) {
                        context.commit(types.mutations.SET_AVAILABLE_TAGS, tagData);
                    } else {
                        throw new Error(`Tags on data source '${context.state.selectedDataSource.name}' has not been refreshed yet.`);
                    }
                }
            }
        },
        async updateSelectedTags(context: DataSourceContext, tag: ITag): Promise<void> {
            context.commit(types.mutations.UPDATE_SELECTED_TAGS, tag);
        },
        async refreshTags(context: DataSourceContext): Promise<void> {
            const service = await initAgentsService();
            if (context.state.selectedDataSource.dataSourceKey) {
                await service.refreshTags(context.state.selectedDataSource);
            }
        },
        async setSelectedTags(context: DataSourceContext, tags: ITag): Promise<void> {
            context.commit(types.mutations.SET_SELECTED_TAGS, tags);
        }
    } as DataSourceStoreActions
};



