














import notificationsStore from '@/store/notificationsStore/notificationsStore';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'toast'
})
export default class Toast extends Vue {
   get message() {
    return notificationsStore.message;
  }

}
