












































// Libraries
import { Component, Vue, Watch } from 'vue-property-decorator';

// View Models
import { IBreadCrumbItem } from '@/view-models/i-breadcrumb';
import store from '@/store';
import apiManagementStore from '@/store/apiManagementStore/apiManagementStore';
import notificationsStore from '@/store/notificationsStore/notificationsStore';

// Components
import TabbedNav from '@/components/common/TabbedNav.vue';
import BreadCrumb from '@/components/common/BreadCrumb.vue';
import Loading from '@/components/common/Loading.vue';
import ApiManagementTable from '@/components/api-management/ApiManagementTable.vue';
import CreateNewApiConfirm from '@/components/api-management/CreateNewApiConfirm.vue';
import GenerateJsonPayloadConfirm from '@/components/api-management/GenerateJsonPayloadConfirm.vue';
import IconButton from '@/components/dsm/IconButton.vue';
import DeleteApiConfirm from '@/components/api-management/DeleteApiConfirm.vue';
import { IApiKeyResponse } from '@/view-models/i-api-management-view-model';
import i18n from '@/i18n';
import { getDefaultCrumbs } from '@/router/bread-crumb-routes';
import assetServiceStore from '@/store/assetServiceStore/assetServiceStore';
import { IHierarchy } from '@/view-models/i-hierarchy-model';
import availableDataStore from '@/store/availableDataStore/availableDataStore';

@Component({
  name: 'api-management',
  components: {
    BreadCrumb,
    Loading,
    TabbedNav,
    CreateNewApiConfirm,
    ApiManagementTable,
    GenerateJsonPayloadConfirm,
    DeleteApiConfirm,
    IconButton
  }
})
export default class ApiManagement extends Vue {
  get breadCrumbItems(): IBreadCrumbItem[] {
    return [
      ...getDefaultCrumbs(),
      {
        text: this.$t(`apiManagement.title`, { action : ''}).toString(),
      }
    ];
  }

  get isLoading() {
    return store.getters['app/isLoading'];
  }

  get hasApiData(): boolean {
    return apiManagementStore.apiKeyList.length > 0;
  }

  get enableApiMangement() {
    return apiManagementStore.apiManagementIsEnabled;
  }

  get specificCustomerKey() {
    return store.getters['agent/specificCustomerKey'];
  }
  get hierarchies(): IHierarchy[] {
    return store.getters['hierarchyBuilder/hierarchyList'];
  }
  get message() {
    return notificationsStore.message;
  }

  public async resetSelectedApiKey(): Promise<void> {
    await apiManagementStore.setSelectedApi({} as IApiKeyResponse);
  }

  @Watch('specificCustomerKey')
  public async specificCustomerKeyChange() {
    await (this.$refs.apiTable as ApiManagementTable).refreshTable();
  }

  public async refresh() {
    await (this.$refs.apiTable as ApiManagementTable).refreshTable();
  }

  public async generateSampleJsonConfirm(): Promise<void> {
    // setup list of hierarchies to display to user.
    await apiManagementStore.setExamplePayloadModal(true);
    if (!availableDataStore.generateJsonLoaded) {
      ((this.$refs.generateJsonPayload) as GenerateJsonPayloadConfirm).isLoading = true;
      await store.dispatch(
        'hierarchyBuilder/getHierarchyListBySpecificCustomerKey'
      );
    }

    if (assetServiceStore.assets.length === 0) {
      await apiManagementStore.setExamplePayloadModal(true);
      await assetServiceStore.getAssetHierarchy(this.specificCustomerKey);
    }
    availableDataStore.setGenerateJsonLoaded(true);
    ((this.$refs.generateJsonPayload) as GenerateJsonPayloadConfirm).isLoading = false;
  }

  public async createNewApiKey() {
    const ref = this.$refs.apiTable as ApiManagementTable;
    apiManagementStore.createNewApiKey(this.specificCustomerKey).then(async () => {
      notificationsStore.setMessage(`${i18n.t(`apiManagement.successAddingKey`)}`);
      notificationsStore.toastContext.show('success-toast');
      await ref.refreshTable();
    })
    .catch((error) => {
      store.dispatch('error/setError', {
        error,
        errorString: 'Error while trying to Add API Key\n',
        handleError: true,
        routeHomeAfterError: false
      });
    });
  }
}
