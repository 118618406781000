export interface IAvailableDataResponse {
  agentKey: string;
  assetKey: string;
  assetName: string;
  customerKey: string;
  receivedOn: string;
  dataSourceKey: string;
  correlationKey: string;
  completedOn: string;
  statusTimeline: StatusTimeLine;
  status: string;
  expires: string;
  tagCountIn: number | null;
  tagCountOut: number | null;
  type: string | null;
  taskDefinitionKey: string;
  taskRequestSource: AvailableDataTaskRequestSource;
  inputTagsDelta: string[];
}

export enum AvailableDataTaskRequestSource {
  Api = 'Api',
  Agent = 'Agent'
};

export interface StatusTimeLine {
  Failed?: string;
  Received?: string;
  UpdatingHistorian?:string;
  Started?: string;
  Complete?: string;
}