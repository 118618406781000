import axios from 'axios';
import authInterceptor from './service-interceptor';

const sharedAgentAxiosInstance = axios.create();
sharedAgentAxiosInstance.interceptors.request.use(authInterceptor);

const sharedKesAxiosInstance = axios.create();
sharedKesAxiosInstance.interceptors.request.use(authInterceptor);

const sharedHierarchyAxiosInstance = axios.create();
sharedHierarchyAxiosInstance.interceptors.request.use(authInterceptor);

const sharedTagsAxiosInstance = axios.create();
sharedTagsAxiosInstance.interceptors.request.use(authInterceptor);

const sharedAvailableDataAxiosInstance = axios.create();
sharedAvailableDataAxiosInstance.interceptors.request.use(authInterceptor);

const sharedAgentLogsAxiosInstance = axios.create();
sharedAgentLogsAxiosInstance.interceptors.request.use(authInterceptor);

const sharedUserManagement = axios.create();
sharedUserManagement.interceptors.request.use(authInterceptor);

export default {
    sharedAgentAxiosInstance,
    sharedKesAxiosInstance,
    sharedHierarchyAxiosInstance,
    sharedTagsAxiosInstance,
    sharedAvailableDataAxiosInstance,
    sharedAgentLogsAxiosInstance,
    sharedUserManagement
};
