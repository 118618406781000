import BaseService from '../base-service';
import { AxiosInstance } from 'axios';
import { IAvailableDataResponse } from '@/view-models/i-available-data-view-model';
import { IAvailableDataReadingResults, ICustomerReadings } from '@/view-models/i-readings-view-model';
import { ICustomerResults } from '@/view-models/i-results-view-model';
import { IAvailableDataLogs } from '@/view-models/i-available-data-logs';
import { TagBasedTaskTypes } from '@/enum/task-definition-type';

export class AvailableDataService extends BaseService {

  constructor(axiosInstance: AxiosInstance, portalApiUrl: string) {
    super(portalApiUrl, '', axiosInstance, 'customers');
  }

  public async getAll(customerKey: string): Promise<IAvailableDataResponse[]> {
    return (await this.httpClient.get<IAvailableDataResponse[]>(`${customerKey}/available-data`));
  }

  public async postByCorrelationKey(customerKey: string, correlationKey: string): Promise<IAvailableDataReadingResults> { 
    return this.httpClient.post<IAvailableDataReadingResults>(`${customerKey}/available-data/${correlationKey}`);
  }

  public async getCustomerReadings(customerKey: string, correlationKey: string): Promise<ICustomerReadings> { 
    return (await this.httpClient.get<ICustomerReadings>(`${customerKey}/available-data/${correlationKey}/readings`));
  }

  public async getCustomerResults(customerKey: string, correlationKey: string): Promise<ICustomerResults> { 
    return (await this.httpClient.get<ICustomerResults>(`${customerKey}/available-data/${correlationKey}/results`));
  }

  public async getAvailableDataLogs(customerKey: string, correlationKey: string): Promise<IAvailableDataLogs> { 
    return (await this.httpClient.get<IAvailableDataLogs>(`${customerKey}/available-data/${correlationKey}/logs`));
  }

  public async getAvailableDataByAgentKey(customerKey: string, agentKey: string) {
    return (await this.httpClient.get<any>(`${customerKey}/available-data/`,{headers: {
      'x-agent-key': agentKey
    }}));
  }

  public async uploadAvailableData(customerKey: string, type: TagBasedTaskTypes, assetKey: string, file: File) {
    const options = {
      headers: { 
        'Content-Type' : 'multipart/form-data',
      }
    };
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post<any>(`${customerKey}/tag-values/csv-portal?type=${type}&assetKey=${assetKey}`, formData, options);
  }

  public async filterAvailableData(customerKey: string, startDate: string, endDate:string, agentKey:string = '') {
    return this.httpClient.get<IAvailableDataResponse[]>(`${customerKey}/available-data/filter?startDate=${startDate}&endDate=${endDate}`,{headers: {
      'x-agent-key': agentKey
    }});
  }
}
