





























































import { Component, Prop } from 'vue-property-decorator';
import DataSourceDetails from '@/components/create/DataSourceDetails.vue';
import StatusVisual from '@/components/common/StatusVisual.vue';
import Tasks from '@/components/create/Tasks.vue';
import { IDataSourceViewModel, DataSourceViewModel } from '@/view-models/i-data-source-view-model';
import BaseComponent from '@/shared/BaseComponent.vue';
import store from '@/store';
import HelperMethods from '@/shared/helper-methods';
import { ITaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import IconButton from '@/components/dsm/IconButton.vue';
import { TagBasedDataSourceTypes } from '@/enum/data-source-types';

@Component({
  name: 'data-source-card',
  components: {
    DataSourceDetails,
    StatusVisual,
    Tasks,
    IconButton
  }
})
export default class DataSourceCard extends BaseComponent {

  @Prop({ required: true })
  private dataSource !: DataSourceViewModel;
  private visible = false;

  get isDisableButtonDisabled(): boolean {
    return this.dataSource.status !== this.dataSource.desiredStatus
        || this.dataSource.status === this.runningStatuses.AwaitingCredentials;
  }
  get isHealthy(): boolean {
    return this.dataSource.status !== this.runningStatuses.Healthy;
  }
  get hasDatasourceKey(): boolean {
    return !HelperMethods.isStringEmpty(this.dataSource.dataSourceKey);
  }
  get isFileType(): boolean {
    return this.dataSource.type === TagBasedDataSourceTypes.File && this.taskDefinitions.length > 0;
  }
  get taskDefinitions(): ITaskDefinitionViewModel[] {
    return store.state.taskDefinition.taskDefinitionsList.filter(
      (data: ITaskDefinitionViewModel) => data.dataSourceKey === this.dataSource.dataSourceKey
    );
  }
  public mounted(): void {
    if (this.dataSource.status === this.runningStatuses.Healthy) {
      this.visible = true;
    } else {
      this.visible = false;
    }

    if (!this.hasDatasourceKey) {
      this.$root.$emit(
      'bv::toggle::collapse',
      'collapse-' + this.dataSource.dataSourceKey
      );
    }
  }
  public async toggleDataSourceStatus(dsInfo: IDataSourceViewModel): Promise<void> {
    await store.dispatch('dataSource/toggleDataSourceStatus',dsInfo);
    
    // collapse or expand the card based on the previous delayed status.
    if (dsInfo.status === this.runningStatuses.Healthy) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }

  public createTask(): void {
    if (this.isFileType) {
      return;
    }
    store.commit('dataSource/setSelectedDataSource', this.dataSource);
    store.commit('hierarchyBuilder/setSelectedHierarchy', {});
    store.commit('hierarchyBuilder/setKeyValue', []);
    store.commit('hierarchyBuilder/setMappedHierarchySelectedTags', []);
    store.commit('dataSource/setSelectedTags', []);
    store.commit('dataSource/setAvailableTags', []);
    this.$router.push('/' + this.dataSource.agentKey + '/task');
  }
  public updateValue(val: boolean): void {
    if (val) {
      this.visible = !this.visible;
    }
  }
}
