






















import { Component } from 'vue-property-decorator';
import store from '@/store';
import BaseComponent from '@/shared/BaseComponent.vue';
import { copyToClipBoard } from '@/shared/helper-methods';


@Component({
  name: 'error'
})
export default class Error extends BaseComponent {

  private store = store;
  private alertTimer = 0;
  get error(): string {
    return store.getters['error/getError'];
  }

  private onClose() {
    // Handle Error
    if (store.getters['error/getRouteHomeAfterError']) {
      this.$router.push('/');
    }
    if (this.error.includes('403')) {
      this.$router.push('/error-403');
    }
    // Clear Error
    store.commit('error/clearError');
  }
  private copy(): void {
    copyToClipBoard(this.error);
    this.alertTimer = 3;
  }
} 
