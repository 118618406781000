export enum LogLevel {
    Trace = 'Trace',
    Debug = 'Debug',
    Information = 'Information',
    Warning = 'Warning',
    Error = 'Error',
    Critical = 'Critical',
    None = 'None',
}

export enum AgentLogType {
    All = 'ALL',
    Agent = 'AGENT',
    Task = 'TASK'
}