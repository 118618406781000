








































































































  import { Component } from 'vue-property-decorator';
  import TableComponent from '@/components/common/TableComponent.vue';
  import DataSourceTab from '@/components/create/DataSourceTab.vue';
  import AgentAvailableDataTab from '@/components/create/AgentAvailableDataTab.vue';
  import InstallerCertificateView from '@/components/create/InstallerCertificateView.vue';
  import StatusVisual from '@/components/common/StatusVisual.vue';
  import { IAgentViewModel, AgentViewModel } from '@/view-models/i-agent-view-model';
  import store from '@/store';
  import BaseComponent from '@/shared/BaseComponent.vue';
  import HelperMethods from '@/shared/helper-methods';
  import Loading from '@/components/common/Loading.vue';
  import BreadCrumb from '@/components/common/BreadCrumb.vue';
  import { IBreadCrumbItem } from '@/view-models/i-breadcrumb';
  import { getDefaultCrumbs } from '@/router/bread-crumb-routes';
  import { isEmpty } from 'lodash';
  import AgentLogDataTab from '@/components/create/AgentLogDataTab.vue';
import availableDataStore from '@/store/availableDataStore/availableDataStore';

  @Component({
    name: 'plant-connector-create',
    components: {
      StatusVisual,
      AgentAvailableDataTab,
      AgentLogDataTab,
      InstallerCertificateView,
      DataSourceTab,
      TableComponent,
      Loading,
      BreadCrumb
    }
  })
  export default class PlantConnectorCreate extends BaseComponent {
    public showAvailableData: boolean = false;
    public showAgentLogData: boolean = false;
    private agentNameInput: string = '';
    private isEditingName: boolean = false;
    private showNextStepsModal: boolean = false;
    private disableSave: boolean = false;
    private componentKey: number = 0;
    private tab: number = 1;

    get breadCrumbItems(): IBreadCrumbItem[] {
      return [
        ...getDefaultCrumbs(),
        {
          text: this.selectedAgent.name ?
                  `${this.selectedAgent.name}`:
                  this.$t(`global.title`, { action : 'New'}).toString(),
        }
      ];
    }

    get selectedAgent(): IAgentViewModel {
      return store.getters['agentSelected/getSelectedAgent'];
    }

    get agentVersionIsEnabled(): boolean {
      return store.getters['agent/agentVersionIsEnabled'];
    }

    get latestAgentVersion(): string {
      return `${store.getters['agent/latestAgentVersion']}`;
    }

    get agentConfigured(): boolean {
      return this.selectedAgent.status === this.setupStatuses.ReadyForScheduledTask ||
             this.selectedAgent.status === this.runningStatuses.Healthy;
    }

    get agentExists(): boolean {
      return  store.state.agent.agents.findIndex((item: IAgentViewModel) =>
                HelperMethods.equalsIgnoreCase(item.name, this.agentNameInput)) > -1;
    }

    get hasSpecialCharacters(): boolean {
      return HelperMethods.specialCharacters(this.agentNameInput);
    }
    
    get hasLetters(): boolean {
      return HelperMethods.hasLetters(this.agentNameInput);
    }

    get customerKey(): string {
      return store.state.agent.user ? store.state.agent.user.activeCustomerKey : '';
    }

    get specificCustomerKey(): string {
      return store.state.agent.specificCustomerKey ? store.state.agent.specificCustomerKey : '';
    }

    get isValidAgent(): boolean {
      return this.selectedAgent != null;
    }

    get isAgentSaved(): boolean {
      return this.isValidAgent && this.selectedAgent.customerKey !== '';
    }

    public mounted(): void {
      if (!this.isValidAgent) {
        this.$router.push('/');
      }
      if (!this.isAgentSaved) {
        this.isEditingName = true;
        this.editName();
      }

      if (isEmpty(this.selectedAgent)) {
        store.commit('agentSelected/setSelectedAgent',new AgentViewModel());
        store.commit('dataSource/setDataSourcesLoaded', false);
        store.commit('taskDefinition/setTaskDefinitionsLoaded', false);
        this.isEditingName = true;
        this.editName();
      }

      this.routeToTab();
    }
    public created(): void {
      this.$router.onReady(() => {
       this.handleRefresh();
      });
    }
    public destroyed(): void {
      availableDataStore.setAvailableAgentLoaded(false);
      availableDataStore.setAvailableDataAgentList([]);
    }
    public routeToTab(): void {
      if (this.$route.name === 'AgentAvailableData') {
        this.tab = 2;
        this.showAvailableData = true;
        this.showAgentLogData = false;
      } else if (this.$route.name === 'AgentLogs') {
        this.tab = 3;
        this.showAgentLogData = true;
        this.showAvailableData = false;
      } else {
        this.tab = 1;
        this.showAvailableData = false;
        this.showAgentLogData = false;
      }
    }
    public async handleRefresh() {
      await this.onLoad();
      if ((this.$route.name === 'PlantConnectorUpdate' || this.$route.name === 'DataSourceUpdate') &&
      HelperMethods.isStringEmpty(this.selectedAgent.agentKey)) {
        this.showRefreshProgress = true;
        this.refreshPage();
        this.showRefreshProgress = false;
      } else if (this.$route.name === 'PlantConnectorCreate' &&
        this.customerKey === undefined) {
        store.commit('agentSelected/setSelectedAgent',new AgentViewModel());
      } else if (this.$route.name === 'DataExportCreate') {
        this.showAvailableData = true;
      }
    }
    public refreshPage() {
      this.componentKey += 1;
    }
    public editName(): void {
      this.agentNameInput = this.selectedAgent ? this.selectedAgent.name: '';
      this.isEditingName = true;
      this.$nextTick().then(() => {
        const txtField: HTMLInputElement = this.$refs.textName as HTMLInputElement;
        if (txtField) {
          txtField.focus();
        }
      });
    }

    public async saveAgentName(): Promise<void> {
      if (this.disableSave) {
        return;
      }
      const agent: IAgentViewModel = {} as IAgentViewModel;
      agent.name = this.agentNameInput;
      agent.customerKey = this.customerKey;
      try {
        this.disableSave = true;
        if (this.isAgentSaved) {
          agent.agentKey = this.selectedAgent.agentKey;
          await store.dispatch('agentSelected/updateAgent', agent);
        } else {
          await store.dispatch('agentSelected/createAgent', agent).then(() => {
            this.$router.push('/' + this.selectedAgent.agentKey);
          });
        }
        this.isEditingName = false;
      } catch (error) {
        throw error;
      }
      this.disableSave = false;
    }
  }
