import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import { IAgentCertificateResponse, IAgentInstallerResponse } from './response-models/agent-certificate-response';
import { IAgentViewModel } from '@/view-models/i-agent-view-model';
import { IDataSourceViewModel } from '@/view-models/i-data-source-view-model';
import { IResponseMessage, ITagViewModel, Tag } from '@/view-models/i-tag-view-model';

export class AgentsService extends BaseService {

  constructor(axiosInstance: AxiosInstance, portalApiUrl: string) {
    super(portalApiUrl, '', axiosInstance,'customers');
  }

  public async getAgents(customerKey: string): Promise<IAgentViewModel[]> {
    return this.httpClient.get<IAgentViewModel[]>(`${customerKey}/agents`);
  }

  public async getBootstrapCertificate(agentInfo: IAgentViewModel): Promise<IAgentCertificateResponse> {
    return this.httpClient
      .get<IAgentCertificateResponse>(`${agentInfo.customerKey}/agents/${agentInfo.agentKey}/bootstrap-certificate`);
  }

  public async getAgentInstaller(agentInfo: IAgentViewModel): Promise<IAgentInstallerResponse> {
    return this.httpClient
      .get<IAgentInstallerResponse>(`${agentInfo.customerKey}/agents/${agentInfo.agentKey}/installer`);
  }

  public async createAgent(createInfo: IAgentViewModel): Promise<IAgentViewModel> {
    return this.httpClient.post<IAgentViewModel>(`${createInfo.customerKey}/agents`, createInfo);
  }

  public async updateAgent(updateInfo: IAgentViewModel): Promise<IAgentViewModel> {
    return this.httpClient
      .put<IAgentViewModel>(`${updateInfo.customerKey}/agents/${updateInfo.agentKey}`, updateInfo);
  }

  public async enableAgent(updateInfo: IAgentViewModel): Promise<IAgentViewModel> {
    return this.httpClient
      .put<IAgentViewModel>(`${updateInfo.customerKey}/agents/${updateInfo.agentKey}/enable`, updateInfo);
  }

  public async disableAgent(updateInfo: IAgentViewModel): Promise<IAgentViewModel> {
    return this.httpClient
      .put<IAgentViewModel>(`${updateInfo.customerKey}/agents/${updateInfo.agentKey}/disable`, updateInfo);
  }
  public async getSpecificAgent(agentKey: string, customerKey: string): Promise<IAgentViewModel> {
    return this.httpClient.get<IAgentViewModel>(`${customerKey}/agents/${agentKey}`);
  }
  public async getAvailableTags(selectedDS: IDataSourceViewModel): Promise<ITagViewModel> {
    return this.httpClient.get<ITagViewModel>(`${selectedDS.customerKey}/data-sources/${selectedDS.dataSourceKey}/tags`);
  }
  public async refreshTags(selectedDS: IDataSourceViewModel): Promise<any> {
    return this.httpClient.put<any>(`${selectedDS.customerKey}/data-sources/${selectedDS.dataSourceKey}/tags/refresh`);
  }
  public async validateTagsByFile(file: File, customerKey: string): Promise<IResponseMessage<Tag[]>> {
    const options = {
      headers: { 'Content-Type' : 'multipart/form-data' }
    };
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post<IResponseMessage<Tag[]>>(`${customerKey}/validate-import`, formData, options);
  }
  public async validateTagName(tags: string[], customerKey: string): Promise<IResponseMessage<Tag[]>> {
    return this.httpClient.post<IResponseMessage<Tag[]>>(`${customerKey}/validate-tag-names`, tags);
  }
  public async lastestAgentVersion(customerKey: string): Promise<string> {
    return this.httpClient.get<string>(`${customerKey}/agents/latest-version`);
  }
}
