






































import { maskStringAsAsterisk } from '@/shared/mask-string';
import store from '@/store';
import apiManagementStore from '@/store/apiManagementStore/apiManagementStore';
import { IApiKeyDeleteModel } from '@/view-models/i-api-management-view-model';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'delete-api-confirm',
})
export default class DeleteApiConfirm extends Vue {
  public show: boolean = false;
  public mask = maskStringAsAsterisk;
  public deleteConfirm: boolean = false;
  private lmodalState: boolean = false;

  get apiKey(): string {
    return apiManagementStore.selectedApi?.apiKey ?? '';
  }

  get specificCustomerKey() {
    return store.getters['agent/specificCustomerKey'];
  }

  get modalState(): boolean {
    return apiManagementStore.showApiDeleteModal;
  }
  set modalState(flag: boolean) {
    this.lmodalState = flag;
    apiManagementStore.setShowApiDeleteModal(flag);
  }

  public async deleteKey(): Promise<void> {
    const deletePayload = {customerKey: this.specificCustomerKey, apiKey: this.apiKey} as IApiKeyDeleteModel;
    await apiManagementStore.deleteApiKey(deletePayload);
    this.onClose();
  }
  private async onClose(): Promise<void> {
    await apiManagementStore.setShowApiDeleteModal(false);
    this.deleteConfirm = false;
    this.show = false;
    this.$emit('on-close');
  }
}
