










































































import { Component, Vue } from 'vue-property-decorator';
import TextAreaCopy from '@/components/common/TextAreaCopy.vue';
import Loading from '@/components/common/Loading.vue';
import availableDataStore from '@/store/availableDataStore/availableDataStore';
import { downloadTextAsFile } from '@/shared/helper-methods';
import { FileType } from '@/enum/file-type';
import i18n from '@/i18n';
import { DownloadAction } from '@/enum/AvailableDataModalState.enum';
import { IAvailableDataResponse } from '@/view-models/i-available-data-view-model';
import StatusTimeLineView from '@/components/available-data/StatusTimeLineView.vue';

@Component({
  name: 'compare-available-data',
  components: {
    TextAreaCopy,
    Loading,
    StatusTimeLineView
  }
})
export default class CompareAvailableData extends Vue {
  private lmodalState = false;
  public DownloadAction = DownloadAction;
  get title(): string {
    return `${this.currentItem.assetName} ${availableDataStore.modalTitle} `;
  }

  get correlationKey(): string {
    return availableDataStore.correlationKey;
  }

  get currentItem(): IAvailableDataResponse {
    return availableDataStore.currentItem;
  }

  get fileType(): FileType {
    return availableDataStore.fileType;
  }

  get modalState(): boolean {
    return availableDataStore.showCompareModal;
  }
  
  get isLoadingState(): boolean {
    return availableDataStore.isLoadingViewData;
  }
  get generatedInputPayload(): string {
    // preserve indentation in file.
    return availableDataStore.generatedInputData;
  }

  get generatedOutputPayload(): string {
    // preserve indentation in file.
    return availableDataStore.generatedOutputData;
  }

  set modalState(flag: boolean) {
    this.lmodalState = flag;
    availableDataStore.setPayloadModal(flag);
  }
  
  public download(action: DownloadAction) {
    if (action === DownloadAction.Inputs || action === DownloadAction.All) {
      downloadTextAsFile(this.generatedInputPayload,
                        `${i18n.t(`availableData.inputData`).toString().replace(' ','').toLowerCase()}-${this.correlationKey}-${new Date().toISOString()}.json`
                        , this.fileType);
    }

    if (action === DownloadAction.Outputs || action === DownloadAction.All) {
        downloadTextAsFile(this.generatedOutputPayload,
                          `${i18n.t(`availableData.outputData`).toString().replace(' ','').toLowerCase()}-${this.correlationKey}-${new Date().toISOString()}.json`
                          , this.fileType);
    }
  }

  private async onClose(): Promise<void> {
    await availableDataStore.resetModalState();
    await availableDataStore.setCompareModal(false);
    await availableDataStore.setCorrelationKey('');
  }

  public destroyed() {
    availableDataStore.resetModalState();
  }
}
