






























































import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';
import {
  HierarchicalTaskTypes,
  TagBasedTaskTypes,
  TaskFrequency,
  TaskTimeoutFrequency,
  TaskTypes
} from '@/enum/task-definition-type';
import { ITaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import { IBSelect } from '@/view-models/shared/b-select';
import store from '@/store';
import { IDataSourceViewModel } from '@/view-models/i-data-source-view-model';
import HelperMethods, { toCamelCase } from '@/shared/helper-methods';
import { IAccordianDetails } from '@/view-models/i-accordian';
import { StepProgressState } from '@/enum/step-progress-state';
import {translatableResources} from '@/static/locales/translatableResources';
import IconButton from '@/components/dsm/IconButton.vue';
import { HierarchicalDataSourceTypes, TagBasedDataSourceTypes } from '@/enum/data-source-types';
import assetServiceStore from '@/store/assetServiceStore/assetServiceStore';
@Component({
  name: 'task-details-view',
  components: {
    IconButton
  }
})
export default class TaskDetailsView extends BaseComponent {
  @Prop({required: true})
  private stepData!: IAccordianDetails;
  private taskSelected: HierarchicalTaskTypes | TagBasedTaskTypes = HierarchicalTaskTypes.Ember;
  private frequencySelected: string = '';
  private timeoutIntervalSelected: string = '';
  private counter: number = 0;
  private timeoutCounter: number = 0;
  private taskName: string = '';
  private showProgress: boolean = false;
  private retrieveLatest: boolean = false;

  get isHealthy(): boolean {
    return this.specificTaskDefinition.status === this.runningStatuses.Healthy;
  }
  get dataSourceName(): string {
    return this.selectedDataSource.name;
  }
  get dataSourceList(): IDataSourceViewModel[] {
    return store.state.dataSource.dataSourceList;
  }
  get editTask(): boolean {
    return !!this.$route.params.taskid;
  }
  get selectedDataSource(): IDataSourceViewModel {
    return store.state.dataSource.selectedDataSource;
  }
  get selectedApplicationType(): any {
    return this.specificTaskDefinition.type;
  }
  get assetServiceIsEnabled(): boolean {
    return assetServiceStore.assetServiceIsEnabled;
  }
  get selectedDataSourceType(): HierarchicalDataSourceTypes | TagBasedDataSourceTypes {
    return this.selectedDataSource.type;
  }
  get optionByApplicationType(): TaskTypes[] {
    const equipmentsOptions: TaskTypes[] = [];
    const type = this.editTask ? this.selectedApplicationType : this.selectedDataSource?.type;
    const sourceType = this.editTask? this.tagBasedTaskTypes : this.tagBasedDataSourceTypes;
    const taskTypes = type in sourceType ? TagBasedTaskTypes : HierarchicalTaskTypes;

    Object.values(taskTypes).forEach((equipment) => {
      equipmentsOptions.push({
        text: this.$t(`taskDefinition.applicationType.${toCamelCase(equipment)}`).toString(),
        value: equipment
      });
    });

    if (this.assetServiceIsEnabled) {
      return equipmentsOptions;
    } else {
      return equipmentsOptions.filter((option) => option.value !== TagBasedTaskTypes.AssetService);
    }

  }
  get timeFrequencyOptions(): IBSelect[] {
    return Object.values(TaskFrequency).map((time) => ({ value : time.charAt(0).toLowerCase(), text : time }));
  }
  get timeoutIntervalOptions(): IBSelect[] {
    return Object.values(TaskTimeoutFrequency).map((time) => ({ value : time.charAt(0).toLowerCase(), text : time }));
  }
  get taskDefinitionsList(): ITaskDefinitionViewModel[] {
    return store.state.taskDefinition.taskDefinitionsList;
  }
  get specificTaskDefinition(): ITaskDefinitionViewModel {
    return store.getters['taskDefinition/getSpecificTaskDefinition'];
  }
  get isInvalidTaskDefinition(): boolean {
    return  this.taskDefinitionsList != null &&
      this.taskDefinitionsList.findIndex((item: ITaskDefinitionViewModel) =>
        HelperMethods.equalsIgnoreCase(item.name, this.taskName)) > -1;
  }
  get hasSpecialCharacters(): boolean {
    return HelperMethods.specialCharacters(this.taskName);
  }
  get isInvalidFrequency(): boolean {
    return this.counter < 0 || this.counter > 1000;
  }
  get sectionDetails(): ITaskDefinitionViewModel {
    return store.state.taskDefinition.sectionDetails;
  }
  get isEdit(): boolean {
    return this.taskName !== this.specificTaskDefinition.name;
  }
  get accordianDetails(): IAccordianDetails[] {
    return store.state.taskDefinition.accordianDetails;
  }
  get isFileType(): boolean {
    return this.selectedDataSourceType === TagBasedDataSourceTypes.File;
  }
  get customerKey(): string {
    return store.getters['agent/specificCustomerKey'];
  }
  get isHierarchyLoaded(): boolean {
    return store.getters['hierarchyBuilder/isHierarchyLoaded'];
  }
  get isAssetsLoaded(): boolean {
    return assetServiceStore.assetsLoaded;
  }
  public mounted(): void {
    this.loadTaskData();
    this.watchValues();
  }
  public async loadTaskData(): Promise<void> {
    if (this.$route.params.taskid) {
      this.setTaskDefinitionInfo(this.specificTaskDefinition);
    } else {
      this.resetTaskDefinitionInfo();
    }
  }
  @Watch('counter')
  public watchValues(): void {
    this.updateStepDetails();
    store.commit('taskDefinition/updateTaskType',this.taskSelected);
    const updatedObject = {} as ITaskDefinitionViewModel;
    updatedObject.name = this.taskName;
    updatedObject.type = this.taskSelected;
    updatedObject.frequency = this.counter.toString().concat(this.frequencySelected);
    updatedObject.timeoutInterval = this.timeoutCounter.toString().concat(this.timeoutIntervalSelected);
    updatedObject.dataSourceKey = this.selectedDataSource?.dataSourceKey;

    if (!this.isFileType) {
      updatedObject.retrieveLatest = this.retrieveLatest ?? false;
    }
    this.updateStepProgress(updatedObject);
    store.commit('taskDefinition/updateSectionDetails',updatedObject);
  }

  @Watch('timeoutCounter')
  public watchTimeoutInterval(): void { this.watchValues(); }

  @Watch('taskName')
  public watchTaskname(): void { this.watchValues(); }

  // Load hierarchyBuilder or asset service assets.
  public async loadAssets() {
    if (this.taskSelected === this.tagBasedTaskTypes.HierarchyBuilder && !this.isHierarchyLoaded) {
      await store.dispatch('hierarchyBuilder/getHierarchyList');
    }
    if (this.taskSelected === this.tagBasedTaskTypes.AssetService && !this.isAssetsLoaded) {
      await assetServiceStore.getAssetHierarchy(this.customerKey);
    }
    this.updateDetailsForFilePath();
  }
  public isEmpty(values: ITaskDefinitionViewModel): boolean {
    return Object.values(values).some((x) => typeof x === 'string' ? HelperMethods.isStringEmpty(x) : false);
  }
  public async updateStepProgress(data: ITaskDefinitionViewModel): Promise<void> {
    if (!this.isEmpty(data) && (!this.isInvalidTaskDefinition && !this.isInvalidFrequency && !this.hasSpecialCharacters || !this.isEdit)) {
      this.stepData.state = StepProgressState.Completed;
    } else {
      this.stepData.state = StepProgressState.Pending;
    }
    await store.dispatch('taskDefinition/updateAccordianDetail', this.stepData);
  }
  public correctFrequency(): void {
    if (this.counter < 0) {
      this.counter = 0;
    }

    if (this.counter > 1000) {
      this.counter = 1000;
    }
  }
  public setTaskDefinitionInfo(data: ITaskDefinitionViewModel): void {
    if (data.name) {
      this.taskName = data.name;
      this.taskSelected = data.type;
      this.frequencySelected = data.frequency.slice(-1);
      this.counter = +data.frequency.slice(0, -1);
      this.timeoutIntervalSelected = data.timeoutInterval.slice(-1) === '' ? (TaskTimeoutFrequency.Seconds).slice(-1) : data.timeoutInterval.slice(-1);
      this.timeoutCounter = +data.timeoutInterval.slice(0, -1) === 0 ? 30 : +data.timeoutInterval.slice(0, -1);

      if (!this.isFileType) {
        this.retrieveLatest = data.retrieveLatest ?? false;
      }
    }
  }
  public resetTaskDefinitionInfo(): void {
    this.taskName = '';
    this.taskSelected = this.optionByApplicationType[0].value;
    this.frequencySelected = this.timeFrequencyOptions.length > 0 ? this.timeFrequencyOptions[0].value : '';
    this.timeoutIntervalSelected = this.timeoutIntervalOptions.length > 0 ? this.timeoutIntervalOptions[0].value : '';
    this.counter = 10;
    this.timeoutCounter = 30;
  }

  public updateStepDetails(): void {
    switch (this.taskSelected){
      case TagBasedTaskTypes.HierarchyBuilder:
        this.removeAssetServicePath();
        this.removeTowerViewSimulatorPath();
        this.hierarchyBuilderPath();
        this.addSelectedTags();
        this.updateDetailsForFilePath();
        this.addKeyValues();
        break;
      case TagBasedTaskTypes.AssetService:
        this.removeTowerViewSimulatorPath();
        this.removeHierarchyPath();
        this.addAssetServicePath();
        this.addSelectedTags();
        break;
      default:
        this.removeTowerViewSimulatorPath();
        this.removeHierarchyPath();
        this.removeAssetServicePath();
        this.tagBasedTaskTypesPath();
        this.updateDetailsForFilePath();
        this.addKeyValues();
    }
  }

  public async updateDetailsForFilePath(): Promise<void> {
    if (this.selectedDataSourceType === TagBasedDataSourceTypes.File) {
      const newAccordianDetails = this.accordianDetails.filter((item) => {
        return item.title !== translatableResources.taskDefinition.selectTags;
      });
      store.commit('taskDefinition/setAccordianDetails', newAccordianDetails);
    }
  }

  private async addSelectedTags(): Promise<void> {
     if (this.selectedDataSourceType === TagBasedDataSourceTypes.File && this.taskSelected == TagBasedTaskTypes.HierarchyBuilder) {
       const selectedTagsStep = this.accordianDetails.filter((item) => {
         return item.title === translatableResources.taskDefinition.selectTags;
       });

       if (selectedTagsStep.length === 0) {
         const selectedTagsStep =  {
            id: 2,
            title: translatableResources.taskDefinition.selectTags,
            expand: false,
            state: StepProgressState.Pending,
            show: true
         };
        const newAccordianDetails = [...this.accordianDetails];
        newAccordianDetails.splice(2, 0, selectedTagsStep);
        newAccordianDetails.forEach((value, index) => value.id = index + 1);
        store.commit('taskDefinition/setAccordianDetails', newAccordianDetails);
       }
     }
  }
  private towerViewTaskTypesPath(): void {
    const assetRootNodeStep = this.accordianDetails.find((value) => value.title === translatableResources.taskDefinition.asset) ?? null;

    if (assetRootNodeStep == null) {
      const assetStep: IAccordianDetails = {
        id: 2,
        title: translatableResources.taskDefinition.asset,
        expand: false,
        state: StepProgressState.Pending,
        show: true
      };

      const newAccordianDetails = [...this.accordianDetails];

      newAccordianDetails.splice(1, 0, assetStep);
      newAccordianDetails.forEach((value, index) => value.id = index + 1);
      store.commit('taskDefinition/setAccordianDetails', newAccordianDetails);
    }
  }
  private addAssetServicePath(): void {
    const assetServicePath = this.accordianDetails.find((value) => value.title === translatableResources.taskDefinition.assetServiceDetails) ?? null;
    if (assetServicePath == null) {
      const hierarchyStep: IAccordianDetails = {
        id: 2,
        title: translatableResources.taskDefinition.assetServiceDetails,
        expand: false,
        state: StepProgressState.Pending,
        show: true
      };

      const newAccordianDetails = [...this.accordianDetails];

      newAccordianDetails.splice(1, 0, hierarchyStep);
      newAccordianDetails.forEach((value, index) => value.id = index + 1);
      store.commit('taskDefinition/setAccordianDetails', newAccordianDetails);
    }
  }

  private removeAssetServicePath(): void {
    const assetServicePath = this.accordianDetails.find((value) => value.title === translatableResources.taskDefinition.assetServiceDetails) ?? null;
    if (assetServicePath) {
      const newAccordianDetails = this.accordianDetails.filter((item) => {
        return item.title !== translatableResources.taskDefinition.assetServiceDetails;
      });
      store.commit('taskDefinition/setAccordianDetails', newAccordianDetails);
      this.adjustAccordianSteps();
    }
  }

  private tagBasedTaskTypesPath(): void {
    if (this.taskSelected in this.tagBasedTaskTypes) {
      this.accordianDetails.forEach((item) => {
        if (item.title === translatableResources.taskDefinition.assetRootNode) {
          item.title = translatableResources.taskDefinition.selectTags;
        }
        return item;
      });
    } else {
      this.accordianDetails.forEach((item) => {
        if (item.title === translatableResources.taskDefinition.selectTags) {
          item.title = translatableResources.taskDefinition.assetRootNode;
        }
        return item;
      });
    }
  }

  private removeTowerViewSimulatorPath(): void {
    const assetRootNodeStep = this.accordianDetails.find((value) => value.title === translatableResources.taskDefinition.asset) ?? null;
    if (assetRootNodeStep) {
      const newAccordianDetails = this.accordianDetails.filter((item) => {
        return item.title !== translatableResources.taskDefinition.asset;
      });
      store.commit('taskDefinition/setAccordianDetails', newAccordianDetails);
      this.adjustAccordianSteps();
    }
  }
  private removeHierarchyPath(): void {
    const hierarchyStep = this.accordianDetails.find((value) => value.title === translatableResources.hierarchyDetails.selectHierarchy) ?? null;
    if (hierarchyStep) {
      const newAccordianDetails = this.accordianDetails.filter((item) => {
        return item.title !== translatableResources.hierarchyDetails.selectHierarchy;
      });
      store.commit('taskDefinition/setAccordianDetails', newAccordianDetails);
      this.adjustAccordianSteps();
    }
  }

  private adjustAccordianSteps(): void {
    this.accordianDetails.forEach((item) => {
      if (item.id !== 1 ) {
        item.id = item.id - 1;
      }
      return item;
    });
  }

  private removeKeyValues(): void {
    const keyValuesStep = this.accordianDetails
      .find((value) => value.title === translatableResources.taskDefinition.specifyKeyValuesOptional) ?? null;

    if (keyValuesStep) {
      const newAccordianDetails = this.accordianDetails.filter((item) => {
        return item.title !== translatableResources.taskDefinition.specifyKeyValuesOptional;
      });
      newAccordianDetails[newAccordianDetails.length - 1].show = false;
      store.commit('taskDefinition/setAccordianDetails', newAccordianDetails);
    }
  }

  private addKeyValues(): void {
    const keyValuesStepExist = this.accordianDetails
      .find((value) => value.title === translatableResources.taskDefinition.specifyKeyValuesOptional) ?? null;

    if (keyValuesStepExist == null) {
      const keyValueStep: IAccordianDetails = {
        id: this.accordianDetails.length,
        title: translatableResources.taskDefinition.specifyKeyValuesOptional,
        expand: false,
        state: StepProgressState.Pending,
        show: false
      };
      const newAccordianDetails = [...this.accordianDetails];
      newAccordianDetails[newAccordianDetails.length - 1].show = true;
      newAccordianDetails.splice(newAccordianDetails.length, 0, keyValueStep);
      newAccordianDetails.forEach((value, index) => value.id = index + 1);
      store.commit('taskDefinition/setAccordianDetails', newAccordianDetails);
    }
  }

  private hierarchyBuilderPath(): void {
    const hierarchyStepExist = this.accordianDetails.find((value) => value.title === translatableResources.hierarchyDetails.selectHierarchy) ?? null;
    if (hierarchyStepExist == null) {
      const hierarchyStep: IAccordianDetails = {
        id: 2,
        title: translatableResources.hierarchyDetails.selectHierarchy,
        expand: false,
        state: StepProgressState.Pending,
        show: true
      };

      const newAccordianDetails = [...this.accordianDetails];

      newAccordianDetails.splice(1, 0, hierarchyStep);
      newAccordianDetails.forEach((value, index) => value.id = index + 1);
      store.commit('taskDefinition/setAccordianDetails', newAccordianDetails);
    }
  }
  private async resetAssetKey(): Promise<void> {
    store.commit('hierarchyBuilder/setSelectedAsset', {});
    await assetServiceStore.setSelectedAsset(null);
  }

  private async resetTags(): Promise<void> {
    store.commit('hierarchyBuilder/setMappedHierarchySelectedTags', []);
    await assetServiceStore.setAssetTags({keyValues: [], tagMetaData: []});
  }

  private resetKeyValues(): void {
    if (this.taskSelected === TagBasedTaskTypes.AssetService) {
      store.commit('hierarchyBuilder/setKeyValue', []);
    }
    this.$emit('resetKeyValues');
  }
}
