import BaseService from '../base-service';
import { AxiosInstance } from 'axios';
import { AgentLogType } from '@/enum/log-level';

export class AgentLogService extends BaseService {
  constructor(axiosInstance: AxiosInstance, portalApiUrl: string) {
    super(portalApiUrl, '', axiosInstance, 'customers');
  }

  public async getAvailableAgentLogs(customerKey: string, agentKey: string, date: string) {
    const options = {
      query: {
        date
      }
    };
    return this.httpClient.get<any>(`${customerKey}/agent-logs/${agentKey}/logs-details`, options);
  }

  public async getAgentLog(customerKey: string, agentKey: string, fileName: string) {
    const options = {
      query: {
        fileName
      },
      responseType: 'text'
    };

    return this.httpClient.get<any>(`${customerKey}/agent-logs/${agentKey}`, options);
  }

  public async requestAvailableAgentLogs(customerKey: string, agentKey: string, date: string) {
    return this.httpClient.put<any>(`${customerKey}/agent-logs/${agentKey}?date=${date}&agentLogsType=${AgentLogType.All}`);
  }

  public async downloadAvailableAgentLogs(customerKey: string, agentKey: string, fileName: string) {
    const options = {
      query: {
        fileName
      },
      responseType: 'text'
    };
    return this.httpClient.get<any>(`${customerKey}/agent-logs/${agentKey}/download`, options);
  }

  public async downloadAllAvailableAgentLogs(customerKey: string, agentKey: string, date: string) {
    const options = {
      query: {
        date,
      },
      headers:{
        accept: 'text/plain'
      },
      responseType: 'blob'
    };
    return this.httpClient.get(`${customerKey}/agent-logs/${agentKey}/download/all`, options);
  }
}
