


































import { Component } from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';
import { IAgentViewModel } from '@/view-models/i-agent-view-model';
import store from '@/store';
import DatePickerRange, { DatePickerRangeEvent, SideOptions } from '@/components/dsm/DatePickerRange.vue';
import moment from 'moment';
import AvailableDataTable from '@/components/available-data/AvailableDataTable.vue';
import ViewAvailableData from '@/components/available-data/ViewAvailableData.vue';
import CompareAvailableData from '@/components/available-data/CompareAvailableData.vue';
import i18n from '@/i18n';
import { ITaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import { IBSelect } from '@/view-models/shared/b-select';
import { IDataSourceViewModel } from '@/view-models/i-data-source-view-model';
import IconButton from '@/components/dsm/IconButton.vue';
import SearchBar from '@/components/common/SearchBar.vue';
import availableDataStore from '@/store/availableDataStore/availableDataStore';

@Component({
  name: 'agent-available-data-tab',
  components: {
    DatePickerRange,
    AvailableDataTable,
    ViewAvailableData,
    CompareAvailableData,
    IconButton,
    SearchBar
  }
})
export default class AgentAvailableDataTab extends BaseComponent {
  public initialStartDateTime =  moment().hours(8).minutes(0);
  public initialEndDateTime = moment().hours(23).minutes(59);
  public date: DatePickerRangeEvent = {startDate: this.initialStartDateTime, endDate: this.initialEndDateTime} as DatePickerRangeEvent;
  public dateEvent: DatePickerRangeEvent = {startDate: this.initialStartDateTime, endDate: this.initialEndDateTime} as DatePickerRangeEvent;
  public filter: string | null = null;
  public searchCorrelationKey = '';
  public dateChange = false;

  public fields = [
    { key: 'assetName', label: `${i18n.t(`availableData.assetName`)}`, tdClass:'col-width align-middle'},
    { key: 'taskDefinitionKey', label: `${i18n.t(`global.fields.taskName`)}`, tdClass: 'col-width align-middle text-truncate'},
    { key: 'tagCount', label: `${i18n.t(`availableData.tagCount`)}`, tdClass:'col-width align-middle'},
    { key: 'status', label: `${i18n.t(`global.fields.status`)}`, tdClass:'col-width align-middle'},
    { key: 'duration', label: `${i18n.t(`availableData.duration`)}`, tdClass:'col-width align-middle' },
    { key: 'createdByDate', label: `${i18n.t(`global.fields.created`)}`, tdClass:'align-middle created-by-date' },
    { key: 'actions', label: `${i18n.t(`availableData.viewData`)}`, tdClass: 'actions-width', thClass: 'actions-section' },
  ];

  public sideOptions = [
    {name: 'Today', dates: [moment().hours(8).minutes(0).toDate(), moment().hours(23).minutes(59).toDate()], active: true},
    {name: 'Yesterday', dates: [moment().add(-24,'hours').hours(8).minutes(0).toDate(), moment().add(-24,'hours').hours(23).minutes(59).toDate()], active: false},
  ] as SideOptions[];

  public dataSourceKey: string | null = null;
  public taskSourceKey: string | null = null;

  public initialDates = Array.from(this.sideOptions[0].dates);
 
  get isBusy(): boolean {
    return availableDataStore.isBusy;
  }

  get selectedAgent(): IAgentViewModel {
    return store.state.agentSelected.selectedAgent;
  }

  get hasDatasourceKey(): boolean {
    return this.dataSourceKey != null;
  }

  get dataSrcOptions(): IBSelect[] {
    const dataSourceList = store.getters['dataSource/getDataSourceList'] as IDataSourceViewModel[];
    return Object.values(dataSourceList).map((item: IDataSourceViewModel) =>
      ({ value: item.dataSourceKey, text: item.name }));
  }

  get taskSrcOptions(): IBSelect[] {
    const taskDefinitionList = store.getters['taskDefinition/getTaskDefinitionsList'] as ITaskDefinitionViewModel[];
    
    return Object.values(taskDefinitionList.filter((d) => d.dataSourceKey === this.dataSourceKey)).map((item: ITaskDefinitionViewModel) =>
      ({ value: item.taskDefinitionKey, text: item.name }));
  }


  get maxDate() {
    return moment().toDate();
  }

  public clearFilter(): void {
    this.dataSourceKey = null;
    this.taskSourceKey = null;
    this.filter = null;
    this.searchCorrelationKey = '';
  }

  public setDate($event: DatePickerRangeEvent) {
    this.$set(this, 'dateEvent', $event);
  }

  public resetDateTime() {
    this.$set(this.initialDates, 0, this.sideOptions[0].dates[0]);
    this.$set(this.initialDates, 1,  this.sideOptions[0].dates[1]);
  }
  
  public refreshTable() {
    this.$set(this, 'date', this.dateEvent);
    this.$nextTick(() => {
      (this.$refs['availableDataTable'] as AvailableDataTable).refreshTable();
    });
  }

  public setSearch(key: string) {
    this.filter = key;
  }
}
