import apiManagementStore from '@/store/apiManagementStore/apiManagementStore';
import { getAuthClient } from '@/services/auth';

export const apiManagementGuard = async (to: any, from: any, next: ({}?) => void) => {
  const client = await getAuthClient();
  try {
    if (apiManagementStore.apiManagementIsEnabled) {
      next();
    } else {
      next({name: 'PlantConnectorList'});
    }
  } catch (e) {
    client.loginWithRedirect();
  }
};

