






























import {Component, Watch, Prop} from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';
import { ITaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import store from '@/store';
import TableComponent from '@/components/common/TableComponent.vue';
import SearchBar from '@/components/common/SearchBar.vue';
import { IAccordianDetails } from '@/view-models/i-accordian';
import { IHierarchy } from '@/view-models/i-hierarchy-model';
import { IBTableField } from '@/view-models/shared/b-table';
import { StepProgressState } from '@/enum/step-progress-state';
import { HierarchyApplication } from '@/enum/task-definition-type';
import Loading from '@/components/common/Loading.vue';
import { IBSelect } from '@/view-models/shared/b-select';
import { IAsset } from '@/view-models/i-asset-view-model';
import { cloneDeep, upperFirst } from 'lodash';
import TypeAheadDropdown, { TypeAheadItem } from '@/components/common/TypeAheadDropdown.vue';

@Component({
  name: 'hierarchy-details-view',
  components: {
    TableComponent,
    SearchBar,
    Loading,
    TypeAheadDropdown
  }
})
export default class HierarchyDetailsView extends BaseComponent {
  public selectedAssetKey: string | null = null;
  public selectedSiteKey: string | null = null;
  public isExistingTask: boolean = false;

  @Prop({required: true})
  private stepData!: IAccordianDetails;

  get isHealthy(): boolean {
    return this.specificTaskDefinition.status === this.runningStatuses.Healthy;
  }
  get editTask(): boolean {
    return !!this.$route.params.taskid;
  }
  get hasHierarchyKey(): boolean {
    return store.getters['taskDefinition/hasHierarchyKey'];
  }
  get isHierarchyLoaded(): boolean {
    return store.getters['hierarchyBuilder/isHierarchyLoaded'];
  }
  get hasSelectedAsset(): boolean {
    return !!this.specificTaskDefinition.assetKey || !!this.selectedAssetKey;
  }

  get sites(): TypeAheadItem[] {
    const sorted = cloneDeep(store.state.taskDefinition.assets)
      .filter((a) => a.type === 'CustomerSite');

    sorted.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  
    if (sorted) {
      return sorted.map((asset) => ({value: asset.key, name: asset.name}));
    }
    return [];
  }

  get assetDisplayName(): string {
     if (this.assetOptions.length > 0) {
      const index = this.assetOptions.findIndex((a) => a.value === this.selectedAssetKey);

      if (index > -1) {
        return this.assetOptions[index].name;
      }
    }
    return '';
  }

  get applicationTypes(): IBSelect[] {
    const types = Object.values(HierarchyApplication).map((type) =>{
      return {
        text: upperFirst(type.toLowerCase()),
        value: type
      };
    });

    // TODO: only allow EMBER for now.
    return types.filter(type => type.text.toUpperCase() === HierarchyApplication.Ember);
  }

  get assetOptions(): TypeAheadItem[] {
    let options: TypeAheadItem[] = [];
    const type = Object.keys(HierarchyApplication).indexOf(upperFirst(this.selectedApplicationType.toLowerCase()));
    switch (this.selectedApplicationType) {
      case HierarchyApplication.Ember:
      case HierarchyApplication.TowerView:
        if (this.isExistingTask) {
          options = this.getAssetsByType(type);
        } else {
          options = this.getAssetBySite(type);
        }
        break;
      default:
        options = this.getAssetsByType(0);
        break;
    }
    return options;
  } 
  
  public getAssetBySite(type: number): TypeAheadItem[] {
    const sorted = cloneDeep(this.hierarchies);
    sorted.sort((a, b) => {
      if (a.assetName > b.assetName) {
        return 1;
      }
      if (a.assetName < b.assetName) {
        return -1;
      }
      return 0;
    });

    if (sorted) {
      return sorted
        .filter(asset => asset.application === type && asset.siteKey === this.selectedSiteKey).map((asset) => ({value: asset.assetKey, name: asset.assetName}));
    }
    return [];
  }

  public getAssetsByType(type: number): TypeAheadItem[] {
    const sorted = cloneDeep(this.hierarchies);
    sorted.sort((a, b) => {
      if (a.assetName > b.assetName) {
        return 1;
      }
      if (a.assetName < b.assetName) {
        return -1;
      }
      return 0;
    });

    if (sorted) {
      return sorted.filter(asset => asset.application === type).map((asset) => ({value: asset.assetKey, name: asset.assetName}));
    }
    return [];
  }

  get isHierarchiesEmpty(): boolean {
    return this.checkHierarchy();
  }
  get hierarchies(): IHierarchy[] {
    return store.state.hierarchyBuilder.hierarchyList;
  }
  get selectedHierarchy(): IHierarchy {
    return store.getters['hierarchyBuilder/selectedHierarchy'];
  }
  get applicationType(): HierarchyApplication {
    return HierarchyApplication.Ember;
  }
  get tableFields(): IBTableField[] {
    return [
      {label: 'select', key: 'radioSelect', tdClass: 'col-width', thStyle: {display: 'none'}},
      {label: 'Hierarchy Name', key: 'hierarchyName', thStyle: {display: 'none'}},
      {label: 'Asset Name', key: 'assetName', thStyle: {display: 'none'}}
    ];
  }
  get assets(): IAsset[] {
    return store.state.taskDefinition.assets;
  }
  get specificTaskDefinition(): ITaskDefinitionViewModel {
     return store.getters['taskDefinition/getSpecificTaskDefinition'];
  }
  public checkHierarchy(): boolean {
    return !this.hierarchies || (this.hierarchies && this.hierarchies.length ===0);
  }
  public selectedApplicationType: string = HierarchyApplication.Ember;
  public siteTypeAhead = {} as TypeAheadDropdown
  public typeahead = {} as TypeAheadDropdown;

  public mounted(): void {
    this.watchValues();
    this.updateAssetSelection();
    this.typeahead = this.$refs['typeahead'] as TypeAheadDropdown;
    this.siteTypeAhead = this.$refs['siteTypeAhead'] as TypeAheadDropdown;

    if (this.typeahead) {
      this.typeahead.selectedType = this.assetDisplayName;
    }

    if (this.siteTypeAhead && !!this.selectedAssetKey && this.isExistingTask) {
      const selectedAsset = this.hierarchies.filter((h) => h.assetKey === this.selectedAssetKey)?.[0];
      this.selectedSiteKey = selectedAsset?.siteKey;
      this.siteTypeAhead.selectedType = selectedAsset?.siteName;
    }
  }


  public updateSelectedHierarchy(data: IHierarchy): void {    
    store.dispatch ('hierarchyBuilder/setSelectedHierarchy', data);
  }
  public updateSelectedAsset(data: IAsset): void {    
    store.dispatch ('hierarchyBuilder/setSelectedAsset', data);
  }
  @Watch('selectedAssetKey')
  public async watchValues(): Promise<void> {
    const hierarchy = this.hierarchies.find((hierarchy => hierarchy.assetKey === this.selectedAssetKey));
    await store.dispatch('hierarchyBuilder/setSelectedHierarchy', hierarchy);
    await store.dispatch('hierarchyBuilder/setSelectedApplication', this.selectedApplicationType);

    if (this.selectedAssetKey && hierarchy) {
      await store.dispatch('hierarchyBuilder/setSelectedAsset', this.selectedAssetKey);
      this.updateStepProgress(this.selectedAssetKey);
      this.fetchTagsByHierarchyId();
    }
  }
  public async updateAssetSelection() {
    const assetKey = this.specificTaskDefinition.assetKey ?? null;

    this.isExistingTask = !!assetKey;

    this.selectedAssetKey = assetKey;
    store.dispatch('hierarchyBuilder/setSelectedAsset', this.selectedAssetKey);
  }

  public setSelectedType($event: TypeAheadItem) {
    this.selectedAssetKey = $event.value;
  }

  public setSelectedSite($event: TypeAheadItem) {
    this.selectedSiteKey = $event.value;

    // we reset.
    this.isExistingTask = false;
    this.selectedAssetKey = null;
    this.typeahead.resetAll();
  }

  public async fetchTagsByHierarchyId(): Promise<void> {
    store.commit('dataSource/setIsLoadingTags', true);

    await store.dispatch('hierarchyBuilder/getTagsByHierarchyId', this.selectedHierarchy).finally(() => {
      store.commit('dataSource/setIsLoadingTags', false);
    });
    
    this.$emit('fetchTagsKeyValue');
  }
  public updateStepProgress(value: string): void {
    if (value) {
      this.stepData.state = StepProgressState.Completed;
    } else {
      this.stepData.state = StepProgressState.Pending;
    }
  }

  public destroyed() {
    // update store to reset
    this.updateSelectedAsset({} as IAsset);
    this.updateSelectedHierarchy({} as IHierarchy);
    store.dispatch('hierarchyBuilder/setSelectedApplication', '');
  }
}
