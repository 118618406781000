import { render, staticRenderFns } from "./TextAreaCopy.vue?vue&type=template&id=c4de3444&scoped=true&"
import script from "./TextAreaCopy.vue?vue&type=script&lang=ts&"
export * from "./TextAreaCopy.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./TextAreaCopy.vue?vue&type=style&index=0&id=c4de3444&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "c4de3444",
  null
  ,true
)

export default component.exports