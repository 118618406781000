




import BaseComponent from '@/shared/BaseComponent.vue';
import store from '@/store';
import { io, iot, mqtt } from 'aws-iot-device-sdk-v2';
import { IUserTokenViewModel } from '@/view-models/i-user-token-view-model';
import { WsEventType } from '@/enum/ws-event-type';
import { Component, Watch } from 'vue-property-decorator';
import { IWsEvent } from '@/view-models/i-ws-event';
import { AgentViewModel, IAgentViewModel } from '@/view-models/i-agent-view-model';
import { DataSourceViewModel, IDataSourceViewModel } from '@/view-models/i-data-source-view-model';
import { ITaskDefinitionViewModel, TaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';

export interface IMqttConnectionConfig extends mqtt.MqttConnectionConfig {
  websocket?: object;
}

@Component({
  name : 'IoT'
})
export default class IoT extends BaseComponent {
  private readonly clientBootstrap: io.ClientBootstrap;
  private readonly mqttClient: mqtt.MqttClient;
  private mqttClientConnection: mqtt.MqttClientConnection | null = null;

  constructor() {
    super();
    this.clientBootstrap = new io.ClientBootstrap();
    this.mqttClient = new mqtt.MqttClient(this.clientBootstrap);
  }

  get userToken(): IUserTokenViewModel {
    return store.state.agent.userToken;
  }

  public async connect(mqttConfig: IMqttConnectionConfig): Promise<boolean> {
    this.mqttClientConnection = this.mqttClient.new_connection(mqttConfig);

    this.mqttClientConnection.on(WsEventType.Connect, () => this.onConnect());
    this.mqttClientConnection.on(WsEventType.Disconnect, () => this.onDisconnect());
    this.mqttClientConnection.on(WsEventType.Error, (error) => this.onError(error));
    this.mqttClientConnection.on(WsEventType.Interrupt, (error) => this.onInterrupt(error));
    this.mqttClientConnection.on(WsEventType.Resume, () => this.onResume());
    this.mqttClientConnection.on(WsEventType.Message, (topic: string, message: Buffer) =>
        this.onMessage(topic, message.toString('utf-8'))
    );

    try {
      return await this.mqttClientConnection.connect();
    } catch (e) {
      return Promise.resolve(false);
    }
  }

  public async disconnect(): Promise<void> {
    if (this.mqttClientConnection != null) {
      return this.mqttClientConnection.disconnect();
    }

    throw new Error(`Disconnecting - this._connection is ${JSON.stringify(this.mqttClientConnection)}`);
  }

  public async subscribe(): Promise<mqtt.MqttSubscribeRequest> {
    if (this.mqttClientConnection != null && this.userToken) {
      return this.mqttClientConnection.subscribe(this.userToken.topicFilter, mqtt.QoS.AtLeastOnce);
    }

    throw new Error(`Subscribing - this._connection is ${JSON.stringify(this.mqttClientConnection)}`);
  }

  public async unsubscribe(): Promise<mqtt.MqttRequest> {
    if (this.mqttClientConnection != null && this.userToken.topicFilter) {
      return this.mqttClientConnection.unsubscribe(this.userToken.topicFilter);
    }

    throw new Error(`Unsubscribing - this._connection is ${JSON.stringify(this.mqttClientConnection)}`);
  }

  private onMessage(topic: string, message: string): void {
    this.handleWebSocketEvent({
      topic,
      type    : WsEventType.Message,
      message : JSON.parse(message),
    });
  }

  private onResume(): void {
    this.handleWebSocketEvent({ type : WsEventType.Interrupt });
  }

  private onInterrupt(error: Error): void {
    this.handleWebSocketEvent({ type : WsEventType.Interrupt, error });
  }

  private onError(error: Error): void {
    this.handleWebSocketEvent({ type : WsEventType.Error, error });
  }

  private onDisconnect(): void {
    this.handleWebSocketEvent({ type : WsEventType.Disconnect });
  }

  private onConnect(): void {
    this.handleWebSocketEvent({ type : WsEventType.Connect });
  }

  private async handleWebSocketEvent(event: IWsEvent): Promise<void> {
    if (event.type !== WsEventType.Message) {
      return;
    }

    const topic = event.topic;
    const message = event.message;

    //  TODO: Ideally, the mutations called below (that affects the 'getter' named 'hasRefreshedTags' within
    //  'dataSourceStore') would be refactored into actions, thereby eliminating the need for that 'getter' altogether.
    //  Also check the TODO comment on that store component
    if (topic?.includes('/agent/')) {
      const agent: IAgentViewModel = new AgentViewModel(message as IAgentViewModel);
      store.commit('agent/updateSpecificAgent', agent);
      if (store.state.agentSelected.selectedAgent.agentKey === agent.agentKey) {
        store.commit('agentSelected/setSelectedAgent', agent);
      }
      await store.dispatch('agent/loadAll');
    } else if (topic?.includes('/data-source/')) {
      const dataSource: IDataSourceViewModel = new DataSourceViewModel(message as IDataSourceViewModel);
      if (store.state.agentSelected.selectedAgent.agentKey === dataSource.agentKey) {
        store.commit('dataSource/updateSpecificDataSource', dataSource);
      }
    } else if (topic?.includes('/task-definition/')) {
      const taskDefinition: ITaskDefinitionViewModel = new TaskDefinitionViewModel(message as ITaskDefinitionViewModel);
      if (store.state.agentSelected.selectedAgent.agentKey === taskDefinition.agentKey) {
        store.commit('taskDefinition/updateSpecificTaskDefinition', taskDefinition);
      }
    }
  }

  private configureMqtt(userToken: IUserTokenViewModel): IMqttConnectionConfig {
    const config: IMqttConnectionConfig = iot.AwsIotMqttConnectionConfigBuilder
        .new_with_websockets()
        .with_clean_session(true)
        .with_client_id(userToken.clientId)
        .with_endpoint(userToken.endpoint)
        .with_keep_alive_seconds(120)
        .with_timeout_ms(10000)
        .build();

    config.username = userToken.mqttToken;
    config.websocket = {
      protocol : 'wss-custom-auth'
    };
    config.client_id = userToken.clientId;

    return config;
  }

  @Watch('userToken')
  private async onUserTokenChanged(newValue: IUserTokenViewModel) {
    if (this.mqttClientConnection) {
      if (newValue.topicFilter === this.userToken.topicFilter) {
        return Promise.resolve();
      } else {
        await this.unsubscribe();
        await this.disconnect();
      }
    }

    const mqttConfig = this.configureMqtt(newValue);
    await this.connect(mqttConfig);
    await this.subscribe();
  }
}
