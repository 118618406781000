
















import apiManagementStore from '@/store/apiManagementStore/apiManagementStore';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'create-new-api-confirm'
})
export default class CreateNewApiConfirm extends Vue {
  private lmodalState = false;

  get modalState(): boolean {
    return apiManagementStore.showApiModal;
  }
  set modalState(flag: boolean) {
    this.lmodalState = flag;
    apiManagementStore.setShowApiModal(flag);
  }
  private async onClose(): Promise<void> {
    await apiManagementStore.setShowApiModal(false);
  }
}
