import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '@/store';
import sharedAxiosInstance from '@/services/api-service';
import ConfigFactory from '@/services/config';
import { AgentsService } from '@/services/agents-service';
import { IAgentViewModel } from '@/view-models/i-agent-view-model';
import { IUserViewModel } from '@/view-models/user-view-model';
import { UserTokenService } from '@/services/user-token-service';
import { IUserTokenViewModel } from '@/view-models/i-user-token-view-model';
import { Vue } from 'vue-property-decorator';
import { UserManagementService } from '@/services/user-management-service';

export const name: string = 'agent';

export const types = {
    getters: {
    },
    mutations: {
        SET_AGENTS_LIST: 'setAgentsList',
        SET_USER: 'setUser',
        SET_USER_TOKEN: 'setUserToken',
        UPDATE_SPECIFIC_AGENT: 'updateSpecificAgent',
        SET_LATEST_AGENT_VERSION: 'setLatestAgentVersion',
        SET_AGENTS_LOADED: 'setAgentsLoaded'
    },
    actions: {
        GET_USER: 'getUser',
        GET_USER_TOKEN: 'getUserToken',
        LOAD_ALL: 'loadAll'
    }
};

export interface IAgentStoreState {
    agents: IAgentViewModel[];
    user: IUserViewModel;
    userTokenService: UserTokenService;
    userToken: IUserTokenViewModel;
    specificCustomerKey: string;
    agentVersionIsEnabled: boolean;
    latestAgentVersion: string;
    agentsLoaded: boolean;
}

export interface PlantConnectorStoreGetters extends GetterTree<IAgentStoreState, IRootState> {
    userToken(state: IAgentStoreState): IUserTokenViewModel;
    specificCustomerKey(state: IAgentStoreState): string;
    user(state: IAgentStoreState): IUserViewModel;
    agentVersionIsEnabled(state: IAgentStoreState): boolean;
    latestAgentVersion(state: IAgentStoreState): string;
}

export interface PlantConnectorStoreMutations extends MutationTree<IAgentStoreState> {
    setAgentsList(state: IAgentStoreState, agentList: IAgentViewModel[]): void;
    setUser(state: IAgentStoreState, user: IUserViewModel): void;
    setUserToken(state: IAgentStoreState, userToken: IUserTokenViewModel): void;
    setSpecificCustomerKey(state: IAgentStoreState, customerKey: string): void;
    updateSpecificAgent(state: IAgentStoreState, updatedAgent: IAgentViewModel): void;
    setAgentVersionIsEnabled(state: IAgentStoreState, flag: boolean): void;
    setLatestAgentVersion(state: IAgentStoreState, version: string): void;
}

export interface PlantConnectorStoreActions extends ActionTree<IAgentStoreState, IRootState> {
    getUser(context: PlantConnectorContext): void;
    getUserToken(context: PlantConnectorContext): void;
    loadAll(context: PlantConnectorContext): void;
    loadSpecificCustomerData(context: PlantConnectorContext): void;
    getLatestAgentVersion(context: PlantConnectorContext): void;
}

const initUserService = async () => {
    const conf = await ConfigFactory.GetConfig();
    return new UserManagementService(sharedAxiosInstance.sharedUserManagement,
        process.env.VUE_APP_USER_MANAGEMENT_BASE_URL ?
          process.env.VUE_APP_USER_MANAGEMENT_BASE_URL :
          conf.get('userManagementApi'));
};

const initAgentsService = async () => {
    const conf = await ConfigFactory.GetConfig();
    return new AgentsService(sharedAxiosInstance.sharedAgentAxiosInstance,
        process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL ?
          process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL :
          conf.get('pcApiUrl'));
};

const initUserTokenService = async () => {
    const conf = await ConfigFactory.GetConfig();
    return new UserTokenService(sharedAxiosInstance.sharedAgentAxiosInstance,
        process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL ?
          process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL :
          conf.get('pcApiUrl'));
};

export type PlantConnectorContext = ActionContext<IAgentStoreState, IRootState>;

export const AgentStore = {
    namespaced: true,
    state: {
        agents: Array<IAgentViewModel>(),
        user: {},
        userToken: {},
        specificCustomerKey: '',
        agentVersionIsEnabled: true,
        latestAgentVersion: '',
        agentsLoaded: false
    } as IAgentStoreState,
    getters:  {
        specificCustomerKey(state: IAgentStoreState): string {
            return state.specificCustomerKey;
        },
        user(state: IAgentStoreState): IUserViewModel {
            return state.user;
        },
        agentVersionIsEnabled(state: IAgentStoreState): boolean {
            return state.agentVersionIsEnabled;
        },
        latestAgentVersion(state: IAgentStoreState): string {
            return state.latestAgentVersion;
        }
    } as PlantConnectorStoreGetters,
    mutations: {
        setAgentsList(state: IAgentStoreState, agentList: IAgentViewModel[]): void {
            state.agents = agentList;
        },
        setUser(state: IAgentStoreState, user: IUserViewModel) {
            state.user = user;
        },
        setUserToken(state: IAgentStoreState, userToken: IUserTokenViewModel) {
            state.userToken = userToken;
        },
        setSpecificCustomerKey(state: IAgentStoreState, customerKey: string) {
            state.specificCustomerKey = customerKey;
        },
        updateSpecificAgent(state: IAgentStoreState, agent: IAgentViewModel) {
            const agentsIndex: number = state.agents
              .findIndex((a: IAgentViewModel) => a.agentKey === agent.agentKey);
            if (agentsIndex !== -1) {
                Vue.set(state.agents, agentsIndex, agent);
            } else {
                state.agents.push(agent);
            }
        },
        setAgentVersionIsEnabled(state: IAgentStoreState, flag: boolean): void {
            Vue.set(state, 'agentVersionIsEnabled', flag);
        },
        setLatestAgentVersion(state: IAgentStoreState, version: string): void {
            Vue.set(state, 'latestAgentVersion', version);
        },
        setAgentsLoaded(state: IAgentStoreState, loaded: boolean) : void {
           state.agentsLoaded = loaded;
        }
    } as PlantConnectorStoreMutations,
    actions: {
        async getUser(context: PlantConnectorContext): Promise<void> {
            const userManagementService = await initUserService();
            const user = await userManagementService.getUser();
            context.commit(types.mutations.SET_USER, user);
        },
        async getUserToken(context: PlantConnectorContext): Promise<void> {
            const userTokenService = await initUserTokenService();
            let customerKey = context.state.user.activeCustomerKey;
            if (context.state.specificCustomerKey) {
                customerKey = context.state.specificCustomerKey;
            }
            await userTokenService.getUserToken(customerKey).then((response) => {
                context.commit(types.mutations.SET_USER_TOKEN, response);
            });
        },
        async loadAll(context: PlantConnectorContext): Promise<void> {
            const agentsService = await initAgentsService();
            if (context.state.user.activeCustomerKey) {
                await agentsService.getAgents(context.state.user.activeCustomerKey).then((response) => {
                    context.commit(types.mutations.SET_AGENTS_LIST, response);
                });
            }
        },
        async loadSpecificCustomerData(context: PlantConnectorContext): Promise<void> {
            const agentsService = await initAgentsService();
            if (context.state.specificCustomerKey) {
                await agentsService.getAgents(context.state.specificCustomerKey).then((response) => {
                    context.commit(types.mutations.SET_AGENTS_LIST, response);
                });
            }
        },
        async getLatestAgentVersion(context: PlantConnectorContext): Promise<void> {
            const agentsService = await initAgentsService();
            if (context.state.specificCustomerKey) {
                agentsService.lastestAgentVersion(context.state.specificCustomerKey).then((response) => {
                    context.commit(types.mutations.SET_LATEST_AGENT_VERSION, response);
                });
            }
        }
    } as PlantConnectorStoreActions
};

