export interface IAvailableDataState {
  availableDataLoaded: boolean;
  availableAgentLoaded: boolean;
  availableDataIsEnabled: boolean;
  availableDataList: IAvailableDataResponse[];
  readings: ICustomerReadings;
  results: ICustomerResults;
  generatedInputData: string;
  generatedOutputData: string;
  isBusy: boolean;
  correlationKey: string;
  isLoadingViewData: boolean;
  showPayloadModal: boolean;
  showCompareModal: boolean;
  modalTitle: string;
  currentItem: IAvailableDataResponse;
  currentModalState: AvailableDataModalState;
  hasPermissions: boolean;
  setAvailableAgentLoaded(flag: boolean): Promise<{availableAgentLoaded: boolean}>;
  setAvailableDataLoaded(flag: boolean): Promise<{availableDataLoaded: boolean}>;
  setPayloadModal(showPayloadModal: boolean): Promise<{showPayloadModal: boolean}>;
  setCompareModal(showCompareModal: boolean): Promise<{showCompareModal: boolean}>;
  setResults(results: ICustomerResults): Promise<{results: ICustomerResults}>;
  setCurrentItem(currentItem: IAvailableDataResponse): Promise<{currentItem: IAvailableDataResponse}>;
  setModalTitle(modalTitle: string): Promise<{modalTitle: string}>;
  setGeneratedInputData(generatedInputData: string): Promise<{generatedInputData: string}>;
  setGeneratedOutputData(generatedOutputData: string): Promise<{generatedOutputData: string}>;
  setGeneratedLogs(generatedLogs: string): Promise<{generatedLogs: string}>;
  setAvailableDataList(availableDataList: IAvailableDataResponse[]): Promise<{availableDataList: IAvailableDataResponse[]}>;
  setIsBusy(isBusy: boolean): Promise<{isBusy: boolean}>;
  setIsLoadingViewData(isLoadingViewData: boolean): Promise<{isLoadingViewData: boolean}>;
  setAvailableDataFlag(flag: boolean): Promise<{availableDataIsEnabled: boolean}>;
  setCorrelationKey(correlationKey: string): Promise<{correlationKey: string}>;
  setCurrentModalState(currentModalState: AvailableDataModalState): Promise<{currentModalState: AvailableDataModalState}>;
  setPermission():  Promise<{hasPermissions: boolean}>;
  resetState(): Promise<void>;
  getInputs(inputPayload: ICustomerReadingsInputPayload): Promise<void>;
  getOutputs(outputPayload: ICustomerReadingsOutputPayload): Promise<void>;
  resetModalState(): Promise<void>;
  getAvailableDataByAgentKey(payload: {customerKey: string, agentKey: string}): Promise<void>;
  uploadAvailableData(payload: IAvailableDataUploadPayload): Promise<any>;
}

import { PermissionRoles } from '@/enum/permissions-roles';
import { AvailableDataService } from '@/services/available-data/available-data-service';
import ConfigFactory from '@/services/config';
import store from '@/store';
import { IAvailableDataResponse } from '@/view-models/i-available-data-view-model';
import { IUserViewModel } from '@/view-models/user-view-model';
import { VuexModule, Module, getModule, MutationAction, Action} from 'vuex-module-decorators';
import sharedAxiosInstance from '@/services/api-service';
import { IAvailableDataReadingResults, ICustomerReadings, ICustomerReadingsInputPayload } from '@/view-models/i-readings-view-model';
import { ICustomerReadingsOutputPayload, ICustomerResults } from '@/view-models/i-results-view-model';
import { AvailableDataModalState } from '@/enum/AvailableDataModalState.enum';
import { IAgentLogsResults, IAvailableDataLogsPayload, IAvailableDataUploadPayload } from '@/view-models/i-available-data-logs';
import { FileType } from '@/enum/file-type';
import { AxiosResponse } from 'axios';
import { initAgentLogService } from '../agentLogsStore/agentLogStore';
import { IHierarchy } from '@/view-models/i-hierarchy-model';
import { initHierarchyBuilderService } from '../hierarchyBuilderStore/hierarchy-builder';
import { HierarchyApplication } from '@/enum/task-definition-type';
import { initAuthService } from '../taskDefinitionStore/task-definition';
import { upperFirst } from 'lodash';
import { AssetType } from '@/enum/asset-type';
import { TypeAheadItem } from '@/components/common/TypeAheadDropdown.vue';
import { Moment } from 'moment';

@Module({dynamic: true, name: 'availableDataStore', store})
export class AvailableDataStore extends VuexModule implements IAvailableDataState {
  public currentModalState = '' as AvailableDataModalState;
  public generatedInputData: string = '';
  public generatedOutputData: string = '';
  public uploadAvailableDataPayload: string = '';
  public generatedLogs: string = '';
  public modalTitle: string = '';
  public readings: ICustomerReadings = {} as ICustomerReadings;
  public results: ICustomerResults = {} as ICustomerResults;
  public correlationKey: string = '';
  public availableDataAgentList: IAvailableDataResponse[] = [];
  public availableDataList: IAvailableDataResponse[] = [];
  public availableDataIsEnabled: boolean = true;
  public isBusy: boolean = true;
  public isLoadingViewData: boolean = false;
  public showPayloadModal = false;
  public showCompareModal = false;
  public showUploadAvailableData = false;
  public currentItem: IAvailableDataResponse = {} as IAvailableDataResponse;
  public fileType = FileType.Json;
  public heading = '';
  public hasPermissions = false;
  public assetOptions: TypeAheadItem[] = [];
  public towerViewAssetOptions: TypeAheadItem[] = [];
  public availableDataLoaded: boolean = false;
  public availableAgentLoaded: boolean = false;
  public generateJsonLoaded: boolean = false;

  @MutationAction({ mutate: ['generateJsonLoaded'], rawError: true })
  public async setGenerateJsonLoaded(generateJsonLoaded: boolean): Promise<{generateJsonLoaded: boolean}> {
    return { generateJsonLoaded };
  }
  @MutationAction({ mutate: ['availableAgentLoaded'], rawError: true })
  public async setAvailableAgentLoaded(availableAgentLoaded: boolean): Promise<{availableAgentLoaded: boolean}> {
    return { availableAgentLoaded };
  }
  @MutationAction({ mutate: ['availableDataLoaded'], rawError: true })
  public async setAvailableDataLoaded(availableDataLoaded: boolean): Promise<{availableDataLoaded: boolean}> {
    return { availableDataLoaded };
  }
  @MutationAction({ mutate: ['showPayloadModal'], rawError: true })
  public async setPayloadModal(showPayloadModal: boolean): Promise<{showPayloadModal: boolean}> {
    return { showPayloadModal };
  }
  
  @MutationAction({ mutate: ['showUploadAvailableData'], rawError: true })
  public async setUploadAvailableDataModal(showUploadAvailableData: boolean): Promise<{showUploadAvailableData: boolean}> {
    return { showUploadAvailableData };
  }

  @MutationAction({ mutate: ['fileType'], rawError: true })
  public async setFileType(fileType: FileType): Promise<{fileType: FileType}> {
    return { fileType };
  }

  @MutationAction({ mutate: ['heading'], rawError: true })
  public async setHeading(heading: string): Promise<{heading: string}> {
    return { heading };
  }
 
  @MutationAction({ mutate: ['assetOptions'], rawError: true })
  public async setAssetOptions(assetOptions: TypeAheadItem[]): Promise<{assetOptions: TypeAheadItem[]}> {
    return { assetOptions };
  }
  
  @MutationAction({ mutate: ['towerViewAssetOptions'], rawError: true })
  public async setTowerViewAssetOptions(towerViewAssetOptions: TypeAheadItem[]): Promise<{towerViewAssetOptions: TypeAheadItem[]}> {
    return { towerViewAssetOptions };
  }
  
  @MutationAction({ mutate: ['showCompareModal'], rawError: true })
  public async setCompareModal(showCompareModal: boolean): Promise<{showCompareModal: boolean}> {
    return { showCompareModal };
  }
  
  @MutationAction({ mutate: ['uploadAvailableDataPayload'], rawError: true })
  public async setUploadAvailableData(uploadAvailableDataPayload: string): Promise<{uploadAvailableDataPayload: string}> {
    return { uploadAvailableDataPayload };
  }
  
  @MutationAction({ mutate: ['modalTitle'], rawError: true })
  public async setModalTitle(modalTitle: string): Promise<{modalTitle: string}> {
    return { modalTitle };
  }

  @MutationAction({ mutate: ['generatedInputData'], rawError: true })
  public async setGeneratedInputData(generatedInputData: string): Promise<{generatedInputData: string}> {
    return { generatedInputData };
  }

  @MutationAction({ mutate: ['generatedLogs'], rawError: true })
  public async setGeneratedLogs(generatedLogs: string): Promise<{generatedLogs: string}> {
    return { generatedLogs };
  }
  
  @MutationAction({ mutate: ['generatedOutputData'], rawError: true })
  public async setGeneratedOutputData(generatedOutputData: string): Promise<{generatedOutputData: string}> {
    return { generatedOutputData };
  }

  @MutationAction({ mutate: ['availableDataList'], rawError: true })
  public async setAvailableDataList(availableDataList: IAvailableDataResponse[]): Promise<{availableDataList: IAvailableDataResponse[]}> {
    return { availableDataList };
  }

  @MutationAction({ mutate: ['availableDataAgentList'], rawError: true })
  public async setAvailableDataAgentList(availableDataAgentList: IAvailableDataResponse[]): Promise<{availableDataAgentList: IAvailableDataResponse[]}> {
    return { availableDataAgentList };
  }

  @MutationAction({ mutate: ['readings'], rawError: true })
  public async setReadings(readings: ICustomerReadings): Promise<{readings: ICustomerReadings}> {
    return { readings };
  }
  
  @MutationAction({ mutate: ['currentItem'], rawError: true })
  public async setCurrentItem(currentItem: IAvailableDataResponse): Promise<{currentItem: IAvailableDataResponse}> {
    return { currentItem };
  }
  @MutationAction({ mutate: ['results'], rawError: true })
  public async setResults(results: ICustomerResults): Promise<{results: ICustomerResults}> {
    return { results };
  }

  @MutationAction({ mutate: ['isBusy'], rawError: true })
  public async setIsBusy(isBusy: boolean): Promise<{isBusy: boolean}> {
    return { isBusy };
  }
  
  @MutationAction({ mutate: ['currentModalState'], rawError: true })
  public async setCurrentModalState(currentModalState: AvailableDataModalState): Promise<{currentModalState: AvailableDataModalState}> {
    return { currentModalState };
  }
  @MutationAction({ mutate: ['isLoadingViewData'], rawError: true })
  public async setIsLoadingViewData(isLoadingViewData: boolean): Promise<{isLoadingViewData: boolean}> {
    return { isLoadingViewData };
  }
  
  @MutationAction({ mutate: ['correlationKey'], rawError: true })
  public async setCorrelationKey(correlationKey: string): Promise<{correlationKey: string}> {
    return { correlationKey };
  }

  @MutationAction({ mutate: ['availableDataIsEnabled'], rawError: true })
  public async setAvailableDataFlag(flag: boolean): Promise<{availableDataIsEnabled: boolean}> {
    return { availableDataIsEnabled: flag };
  }

  @MutationAction({ mutate: ['hasPermissions'], rawError: true})
  public async setPermission(): Promise<{hasPermissions: boolean}> {
    const user = store.getters['agent/user'] as IUserViewModel;
    return {hasPermissions : user?.permissions?.includes(PermissionRoles.ManagePlantConnectorApi) ?? false};
  }

  @Action({rawError: true})
  public async getAvailableData(customerKey: string): Promise<void> {
    this.setIsBusy(true);
    const availableDataService = initAvailableDataService();
    try {
      const results = (await availableDataService).getAll(customerKey);
      this.setAvailableDataList(await results);
    } catch (error) {
      store.dispatch('error/setError', {
        error,
        errorString: 'Error while trying to Get Available Data\n',
        handleError: true,
        routeHomeAfterError: false
      });
    } finally {
      this.setIsBusy(false);
    }
  }

  @Action({rawError: true})
  public async getAvailableDataByAgentKey(payload: {customerKey: string, agentKey: string, startDate: Moment, endDate: Moment}) {
    this.setIsBusy(true);
    const availableDataService = await initAvailableDataService();
    try {
      const startDate = payload.startDate.toISOString();
      const endDate =  payload.endDate.toISOString();
      const results = await (availableDataService).filterAvailableData(payload.customerKey, startDate, endDate, payload.agentKey);
      if (payload.agentKey !== '') {
        await this.setAvailableDataAgentList(results);
        this.setAvailableAgentLoaded(true);
      } else {
        await this.setAvailableDataList(results);
        this.setAvailableDataLoaded(true);
      }
    } catch (error) {
      store.dispatch('error/setError', {
        error,
        errorString: 'Error while trying to Get Available Data\n',
        handleError: true,
        routeHomeAfterError: false
      });
    } finally {
      this.setIsBusy(false);
    }
  }

  @Action({rawError: true})
  public async getInputs(inputPayload: ICustomerReadingsInputPayload): Promise<void> { 
    this.setIsLoadingViewData(true);
    const availableDataService = initAvailableDataService();
    try {
      const results = (await availableDataService).getCustomerReadings(inputPayload.customerKey, inputPayload.correlationKey);
      this.setReadings(await results);
      this.setGeneratedInputData(JSON.stringify(await results, undefined, 4));
    } catch (error) {
      const statusResponse = error as AxiosResponse;
      if (!!statusResponse?.status && statusResponse?.status !== 404){
        store.dispatch('error/setError', {
          error,
          errorString: 'Error while trying to Get Inputs\n',
          handleError: true,
          routeHomeAfterError: false
        });
      }
    } finally {
      this.setIsLoadingViewData(false);
    }
  }
  @Action({rawError: true})
  public async getOutputs(outputPayload: ICustomerReadingsOutputPayload): Promise<void> { 
    this.setIsLoadingViewData(true);
    const availableDataService = initAvailableDataService();
    try {
      const results = (await availableDataService).getCustomerResults(outputPayload.customerKey, outputPayload.correlationKey);
      this.setResults(await results);
      this.setGeneratedOutputData(JSON.stringify(await results, undefined, 4));
    } catch (error) {
      const statusResponse = error as AxiosResponse;
      if (!!statusResponse?.status && statusResponse?.status !== 404){
        store.dispatch('error/setError', {
          error,
          errorString: 'Error while trying to Get Outputs\n',
          handleError: true,
          routeHomeAfterError: false
        });
      }
    } finally {
      this.setIsLoadingViewData(false);
    }
  }

  @Action({rawError: true})
  public async getLogs(logPayload: IAvailableDataLogsPayload): Promise<void> {
    this.setIsLoadingViewData(true);
    const availableDataService = initAvailableDataService();
    try {
      const results = (await availableDataService).getAvailableDataLogs(logPayload.customerKey, logPayload.correlationKey);
      this.setGeneratedLogs(JSON.stringify(await results, undefined, 4));
    } catch (error) {
      store.dispatch('error/setError', {
        error,
        errorString: 'Error while trying to Get Logs\n',
        handleError: true,
        routeHomeAfterError: false
      });
    } finally {
      this.setIsLoadingViewData(false);
    }
  }
  
  @Action
  public async getAssetOptions(): Promise<void> {
    const hierarchyBuilderService = await initHierarchyBuilderService('customers');
    const custKey = store.getters['agent/specificCustomerKey'];
    if (custKey) {
      try {
        const response: IHierarchy[] = await hierarchyBuilderService.getPublishedHierarchies(custKey);
        // Ember
        const type = Object.keys(HierarchyApplication).indexOf(upperFirst(HierarchyApplication.Ember.toLowerCase()));
        if (!!response) {
          const options =  response.filter(asset => asset.application === type).map((asset) => ({value: asset.assetKey, name: asset.assetName}));
          this.setAssetOptions(options as TypeAheadItem[]);
        }
      } catch (error) {
          store.dispatch('error/setError', {
              error,
              errorString: 'Error while getting hierarchy list\n',
              handleError: true,
              routeHomeAfterError: false
          });
      }
    }
  }
  @Action
  public async getTowerViewSimulatorOptions(): Promise<void> {
    const authService = await initAuthService();
    try {
      const response = await authService.getCustomerAssets();
      if (!!response) {
        const options =  response.filter((a) => 
          (a.type === AssetType.TowerType && a.entityType === AssetType.TowerType))
          .map((asset) => ({value: asset.key, name: asset.name}));
        this.setTowerViewAssetOptions(options as TypeAheadItem[]);
      }
    } catch (error){
      store.dispatch('error/setError', {
        error,
        errorString: 'Error while getting assets \n',
        handleError: true,
        routeHomeAfterError: false
    });
    }
  }

  @Action({rawError: true})
  public async viewAgentLogs(downloadPayload: IAgentLogsResults): Promise<any> {
    this.setIsLoadingViewData(true);
    const agentService = initAgentLogService();
    try {
      const results = (await agentService).getAgentLog(downloadPayload.customerKey, downloadPayload.agentKey, downloadPayload.name);
      this.setGeneratedLogs(JSON.stringify(await results, undefined, 4));
    } catch (error) {
      store.dispatch('error/setError', {
        error,
        errorString: 'Error while trying to Get Logs\n',
        handleError: true,
        routeHomeAfterError: false
      });
    } finally {
      this.setIsLoadingViewData(false);
    }
  }

  @Action({rawError: true})
  public async resetState(): Promise<void> {
    this.resetModalState();
  }

  @Action({rawError: true})
  public async rerunByCorrelationKey(payload: IAvailableDataLogsPayload): Promise<IAvailableDataReadingResults> {
    const availableDataService = await initAvailableDataService();
    return availableDataService.postByCorrelationKey(payload.customerKey, payload.correlationKey);
  }

  @Action({rawError: true})
  public async resetModalState(): Promise<void> {
    this.setPayloadModal(false);
    this.setReadings({} as ICustomerReadings);
    this.setResults({} as ICustomerResults);
    this.setGeneratedInputData('');
    this.setGeneratedOutputData('');
  }
  @Action({rawError: true})
  public async uploadAvailableData(payload: IAvailableDataUploadPayload): Promise<any> {
    const availableData = await initAvailableDataService();
    return availableData.uploadAvailableData(payload.customerKey, payload.type, payload.assetKey, payload.file);     
  }
}

export default getModule(AvailableDataStore, store);

export const initAvailableDataService = async () => {
  const conf = await ConfigFactory.GetConfig();
  return new AvailableDataService(sharedAxiosInstance.sharedAvailableDataAxiosInstance,
      process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL ?
        process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL :
        conf.get('pcApiUrl'));
};
