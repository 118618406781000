export class Trie {
  public root!: TrieNode;
  private possibilities: TrieNode[] = [];
  private currentSearchValue!: string;

  constructor(items?: string[]) {
    this.root = new TrieNode('root');
    if (items != null && items?.length > 0) {
      items.map((item) => this.insert(item));
    }
  }

  public insert(value: string) {
    this.add(value, 0, this.root);
  }

  public search(value: string): TrieNode[] {
    this.possibilities = [];
    this.searchWord(value.toLowerCase(), 0, this.root.children);
    return this.possibilities;
  }

  public delete(value: string) {
    this.remove(value.toLowerCase(), 0, this.root);
  }

  public update(value:string, updatedValue: string) {
    this.remove(value.toLowerCase(), 0, this.root);
    this.add(updatedValue, 0, this.root);
  }
  
  private edit(value: string, updatedValue: string, index:number, trie: TrieNode){
    if (trie == null) {
      return;
    }
    
    trie.children.forEach((item) => {
      if (item.word !== '' && item.word.toLowerCase() === value) {
        item.word = updatedValue;
      } else {
        this.edit(value, updatedValue, index + 1, item);
      }
    });
  }
  // search for value and print out all children.
  private searchWord(value: string, index:number, trie:Map<string, TrieNode>) {
    if (value === '') {
      return;
    }
    this.currentSearchValue = value;
    if (trie.size > 0) {
      this.getAllWords(value, trie, index);
    }
  }

  private getAllWords(needle: string, trie:Map<string, TrieNode>, index: number){
    const searchValue = this.currentSearchValue;
    trie.forEach((value) => {
      if (value.word !== '' && value.word.toLowerCase().includes(searchValue)) {
        if (needle.charAt(0) !== value.word.charAt(0).toLowerCase()) {
          this.possibilities.push(value);
        }
        if (needle.charAt(0) === value.word.charAt(0).toLowerCase()) {
          this.possibilities.unshift(value);
        }
      }
      
      if (value.children.size > 0) {
        this.getAllWords(needle, value.children, index + 1);
      }
    });
  }
  private remove(value:string, index: number, trie: TrieNode) {
    if (trie == null) {
      return;
    }
    
    trie.children.forEach((item) => {
      if (item.word !== '' && item.word.toLowerCase() === value) {
        item.word = '';
      } else {
        this.remove(value, index + 1, item);
      }
    });
  }
  private add(value: string, index: number, trie: TrieNode): void {
   if (trie == null || value == null) {
     return;
   }

   const alpha = value.charAt(index).toLowerCase();
   if (!trie.children.has(alpha)) {
    trie.children.set(value.charAt(index).toLowerCase(), new TrieNode(value.charAt(index)));
   }

   if (value.length === 0 || index === value.length - 1) {
     const existing = trie.children.get(value.charAt(index).toLowerCase());
     if (!!existing) {
        existing.word = value;
        trie.children.set(value.charAt(index).toLowerCase(), existing);
     } else {
        trie.children.set(value.charAt(index).toLowerCase(), new TrieNode(value.charAt(index), value));
     }
     return;
   }
   
   const updated = trie.children.get(value.charAt(index).toLowerCase());
   if (updated) {
     return this.add(value, index + 1, updated);
   }
  }
}

export class TrieNode {
  public key: string;
  public word: string;
  public children: Map<string, TrieNode>;

  constructor(key: string, word = '') {
    this.key = key.toLowerCase();
    this.word = word;
    this.children = new Map<string, TrieNode>();
  }
}
