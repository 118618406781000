import i18n from '@/i18n';

export function getDefaultCrumbs() {
  return [ 
    {
      text: i18n.t(`global.Admin`, { action : ''}).toString(),
      href: `${getOrigin()}/admin`
    },
    {
      text: i18n.t(`global.title`, { action : ''}).toString(),
      to: '/'
    }
 ];
}

function getOrigin() {
  if (!window.location.origin) {
    return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
  }
  return window.location.origin;
}