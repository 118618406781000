




















































































// Libraries
import { Component, Vue } from 'vue-property-decorator';
import { maskStringAsAsterisk } from '@/shared/mask-string';
import { copyToClipBoard } from '@/shared/helper-methods';
// View Models
// Components
import IconButton from '@/components/dsm/IconButton.vue';
import { IApiKeyModel, IApiKeyResponse } from '@/view-models/i-api-management-view-model';
import apiManagementStore from '@/store/apiManagementStore/apiManagementStore';
import store from '@/store';
import { BTable } from 'bootstrap-vue';
import Loading from '@/components/common/Loading.vue';

@Component({
  name: 'api-management-table',
  components: {
    IconButton,
    Loading
  }
})
export default class ApiManagementTable extends Vue {
  get specificCustomerKey() {
    return store.getters['agent/specificCustomerKey'];
  }
  get apiData(): IApiKeyResponse[] {
    return apiManagementStore.apiKeyList;
  }
  get hasData(): boolean {
    return apiManagementStore.apiKeyList && apiManagementStore.apiKeyList.length > 0;
  }
  get totalRows() {
    return apiManagementStore.apiKeyList.length ?? 0;
  }
  get isBusy() {
    return apiManagementStore.isBusy;
  }
  public mask = maskStringAsAsterisk;
  public alertTimer = 0;
  public fields = [
    { key: 'apiKey', label: 'API Key', tdClass:'align-middle api-key-width' },
    { key: 'label', label: 'Label', tdClass:'align-middle label-width'},
    { key: 'createdByName', label: 'Created By', tdClass:'col-width align-middle'},
    { key: 'createdByDate', label: 'Created Date', tdClass:'align-middle'},
    { key: 'lastModifiedByDate', label: 'Last Modified', tdClass:'align-middle'},
    { key: 'actions', label: 'Actions', tdClass: 'actions-width' }
  ];
  public ref: BTable = {} as BTable;
  public perPageItems: number = 6;
  public currentPage: number = 1;
  public data: any = [];
  public copy(key: string) {
    copyToClipBoard(key);
    this.alertTimer = 3;
  }

  public async mounted(): Promise<void> {
    this.ref = this.$refs.apiTableRef as BTable;

    if (!apiManagementStore.apiManagementLoaded) {
      await this.refreshTable();
      apiManagementStore.setApiManagementLoaded(true);
    }
  }

  public async deleteConfirm(data: IApiKeyResponse) {
    const apiSelected = {apiKey: data.apiKey, label: data.label} as IApiKeyModel;
    await apiManagementStore.setShowApiDeleteModal(true);
    await apiManagementStore.setSelectedApi(apiSelected);
  }

  public async updateKey(data: IApiKeyResponse) {
    if (data.label === data.previousLabel) {
      this.unselectAll();
      return;
    }
    apiManagementStore.setIsBusy(true);
    const payload = {
      customerKey: this.specificCustomerKey,
      apiKey: data.apiKey,
      label: data.label
    };

    await apiManagementStore.updateApiKey(payload);
  }

  public async refreshTable(): Promise<void> {
    await apiManagementStore.getApiKeys(this.specificCustomerKey);
    this.ref.refresh();
  }

  public reset(data: BTable) {
    const temp = [...apiManagementStore.apiKeyList];
    temp[data.index].label = temp[data.index].previousLabel;
    apiManagementStore.setApiKeyList(temp);
    this.ref.refresh();
  }

  public selectRow(data: BTable) {
    this.unselectAll();
    data.item.editing = true;
  }

  private unselectAll(): void {
    const temp = [...apiManagementStore.apiKeyList];
    temp.forEach((record) => record.editing = false);
    apiManagementStore.setApiKeyList(temp);
    this.ref.refresh();
  }
}
