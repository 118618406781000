



















































import {Component} from 'vue-property-decorator';
import store from '@/store';
import Loading from '@/components/common/Loading.vue';
import BaseComponent from '@/shared/BaseComponent.vue';
import BreadCrumb from '@/components/common/BreadCrumb.vue';
import StepProgress from '@/components/common/StepProgress.vue';
import TaskDetailsView from '@/components/create/TaskDetails.vue';
import TagDetailsView from '@/components/create/TagDetails.vue';
import KeyValuesView from '@/components/create/KeyValue.vue';
import RootNodeDetailsView from '@/components/create/RootNodeDetails.vue';
import HierarchyDetailsView from '@/components/create/HierarchyDetails.vue';
import AssetServiceDetailsView from '@/components/create/AssetServiceDetails.vue';
import {IBreadCrumbItem} from '@/view-models/i-breadcrumb';
import {IAgentViewModel} from '@/view-models/i-agent-view-model';
import {StepProgressState} from '@/enum/step-progress-state';
import { IAccordianDetails } from '@/view-models/i-accordian';
import {IDataSourceViewModel} from '@/view-models/i-data-source-view-model';
import {IDictionary, ITaskDefinitionViewModel} from '@/view-models/i-task-definition-view-model';
import {translatableResources} from '@/static/locales/translatableResources';
import {ITag} from '../../view-models/i-tag-view-model';
import {TagBasedTaskTypes} from '@/enum/task-definition-type';
import { getDefaultCrumbs } from '@/router/bread-crumb-routes';
import { IAsset } from '@/view-models/i-asset-view-model';
import { TagBasedDataSourceTypes } from '@/enum/data-source-types';
import assetServiceStore from '@/store/assetServiceStore/assetServiceStore';

@Component({
  name: 'task-definition',
  components: {
    BreadCrumb,
    Loading,
    StepProgress,
    TaskDetailsView,
    RootNodeDetailsView,
    TagDetailsView,
    HierarchyDetailsView,
    KeyValuesView,
    AssetServiceDetailsView
  }
})
export default class TaskDefinition extends BaseComponent {
  private showProgress: boolean = false;
  private submitProgress: boolean = false;
  private componentKey: number = 0;
  private existTags: ITag[] = [];

  get isLoading() {
    return store.getters['app/isLoading'];
  }
  get isHealthy(): boolean {
    return this.specificTaskDefinition.status === this.runningStatuses.Healthy;
  }
  get taskDetails(): string {
    return translatableResources.taskDefinition.taskDetails;
  }
  get keyValues(): string {
    return translatableResources.taskDefinition.specifyKeyValuesOptional;
  }
  get rootNodeDetails(): string {
    return translatableResources.taskDefinition.assetRootNode;
  }
  get assetServiceDetails(): string {
    return translatableResources.taskDefinition.assetServiceDetails;
  }
  get assetNode(): string {
    return translatableResources.taskDefinition.asset;
  }
  get tagDetails(): string {
    return translatableResources.taskDefinition.selectTags;
  }
  get hierarchyDetails(): string {
    return translatableResources.hierarchyDetails.selectHierarchy;
  }
  get breadCrumbItems(): IBreadCrumbItem[] {
    return [
      ...getDefaultCrumbs(),
      {
        text: this.selectedAgent.name,
        to: `/${this.selectedAgent.agentKey}/data-sources/`
      },
      {
        text: this.specificTaskDefinition.name ? this.specificTaskDefinition.name : `${this.$t(`taskDefinition.task`, { text : 'New'}).toString()}`,
      }
    ];
  }
  get selectedTaskType(): string {
    return store.state.taskDefinition.selectedTaskType;
  }
  get isHierarchyBuilderType(): boolean {
    return this.specificTaskDefinition.type ? this.specificTaskDefinition.type === TagBasedTaskTypes.HierarchyBuilder :
        this.selectedTaskType === TagBasedTaskTypes.HierarchyBuilder;
  }
  get isAssetServiceType(): boolean {
    return this.selectedTaskType === TagBasedTaskTypes.AssetService;
  }
  get accordianDetails(): IAccordianDetails[] {
   return store.state.taskDefinition.accordianDetails;
  }
  get dataSourceList(): IDataSourceViewModel[] {
    return store.state.dataSource.dataSourceList;
  }
  get selectedAgent(): IAgentViewModel {
    return store.state.agentSelected.selectedAgent;
  }
  get selectedAssetForHierarchy(): IAsset {
    return store.getters['hierarchyBuilder/selectedAsset'];
  }
  get specificTaskDefinition(): ITaskDefinitionViewModel {
    return store.getters['taskDefinition/getSpecificTaskDefinition'];
  }
  get sectionDetails(): ITaskDefinitionViewModel {
    return store.state.taskDefinition.sectionDetails;
  }
  get selectedType(): string {
    return store.state.dataSource.selectedDataSource?.type;
  }
  get keyValuePairs(): IDictionary[] {
    return this.isHierarchyBuilderType ? store.state.hierarchyBuilder.keyValue
        : store.state.taskDefinition.keyValuePairs;
  }
  get availableTags(): ITag[] {
    return store.state.dataSource.availableTags.tags;
  }
  get selectedTags(): ITag[] {
    if (this.isHierarchyBuilderType) {
      return store.state.hierarchyBuilder.mappedHierarchySelectedTags;
    }

    if (this.isAssetServiceType) {
      return assetServiceStore.mappedAssetTags.tagMetaData;
    }

    return this.isHierarchyBuilderType ? store.state.hierarchyBuilder.mappedHierarchySelectedTags :
        store.state.dataSource.selectedTags;
  }
  get isFileType(): boolean {
    return store.state.dataSource.selectedDataSource.type === TagBasedDataSourceTypes.File;
  }

  public refresh(): void {
    this.componentKey += 1;
  }
  public mounted(): void {
    this.loadTaskList();
  }

  public async loadTaskList(): Promise<void> {
    await store.dispatch('taskDefinition/getAccordianDetails');
    await this.onLoad();
    await store.dispatch('taskDefinition/loadAssets')
              .catch((err) => {
                  store.dispatch('error/setError', {
                    error: err,
                    errorString: 'Error loading assets\n',
                    handleError: true,
                    routeHomeAfterError: false
                  });
              });
    await this.resolveSelectedHierarchy();
  }

  public isCompleted(data: IAccordianDetails[]): boolean {
    return data.some((item) => (item.state !== StepProgressState.Completed && item.title !== this.keyValues));
  }
  public isValidKeyValues(): boolean {
    if (this.keyValuePairs && this.keyValuePairs.length > 0) {
      return this.accordianDetails.some((item) => item.state !== StepProgressState.Completed);
    }
    return false;
  }
  public disableSubmit(): boolean {
    if (this.isCompleted(this.accordianDetails)) {
      return true;
    }
    return false;
  }
  public async submitTaskData(): Promise<void> {
    let taskDefinitionSectionDetails = Object.assign({}, this.sectionDetails);

    try {
      if (this.isHierarchyBuilderType) {
        taskDefinitionSectionDetails.assetName = this.selectedAssetForHierarchy.name;
        taskDefinitionSectionDetails.assetKey = this.selectedAssetForHierarchy.key;
        taskDefinitionSectionDetails.hierarchyApplication = store.getters['hierarchyBuilder/getSelectedApplicationType'] ?? this.specificTaskDefinition.hierarchyApplication;
      }

      taskDefinitionSectionDetails.agentKey = this.selectedAgent.agentKey;
      taskDefinitionSectionDetails.customerKey = this.selectedAgent.customerKey;

      if (!this.isFileType) {
        const retreiveLatest = store.state.taskDefinition.sectionDetails?.retrieveLatest;
        taskDefinitionSectionDetails.retrieveLatest = this.sectionDetails.retrieveLatest ?? false;
      }

      if (this.$route.params.taskid) {
        await this.editTaskDefinition(taskDefinitionSectionDetails);
      } else {
        await this.createTaskDefinition(taskDefinitionSectionDetails);
      }
    } catch (err) {
      store.dispatch('error/setError', {
          error: err,
          errorString: 'Failed to save task.\n',
          handleError: true,
          routeHomeAfterError: false
        });
    }
  }
  public async editTaskDefinition(taskDefinitionInfo: ITaskDefinitionViewModel): Promise<void> {
    this.submitProgress = true;
    taskDefinitionInfo.taskDefinitionKey = this.$route.params.taskid;
    taskDefinitionInfo.selectedTags = this.selectedTags;
    taskDefinitionInfo.hierarchyApplication = store.getters['hierarchyBuilder/getSelectedApplicationType'] ?? this.specificTaskDefinition.hierarchyApplication;
    store.dispatch('taskDefinition/updateTaskDefinition',taskDefinitionInfo).then(() => {
       this.backToEdit();
    })
    .catch((err) => {
      store.dispatch('error/setError', {
        error: err,
        errorString: 'Error while editing task definition\n',
        handleError: true,
        routeHomeAfterError: false
      });
    }).finally(() => {
      this.submitProgress = false;
    });
   
  }
  public async createTaskDefinition(taskDefinitionInfo: ITaskDefinitionViewModel): Promise<void> {
    this.submitProgress = true;
    taskDefinitionInfo.selectedTags = this.selectedTags;
    store.dispatch('taskDefinition/createTaskDefinition',taskDefinitionInfo).then(() => {
      this.backToEdit();
    })
    .catch((err) => {
      store.dispatch('error/setError', {
        error: err,
        errorString: 'Error while creating task definition\n',
        handleError: true,
        routeHomeAfterError: false
      });
    }).finally(() => {
      this.submitProgress = false;
    });
  }
  public backToEdit(): void {
    this.$router.push('/' + this.selectedAgent.agentKey);
  }

  public async destroyed() {
    await store.dispatch('taskDefinition/resetState');
  }

  public async resolveSelectedHierarchy() {
    const storeTaskDefinition = store.getters['taskDefinition/getSpecificTaskDefinition'] ?? {};
    const taskDefinitionkeyValues = JSON.parse(JSON.stringify(storeTaskDefinition));
    const hierarchyKey = taskDefinitionkeyValues?.keyValues?.HierarchyKey ?? null;
    const hierarchy = await store.dispatch('hierarchyBuilder/getHiearchyFromPublishedHierarchy', hierarchyKey);
    if (hierarchy) {
      await store.dispatch('hierarchyBuilder/setSelectedHierarchy', hierarchy);
    }
  }
}
