import { render, staticRenderFns } from "./TaskDetails.vue?vue&type=template&id=0d5bab06&scoped=true&"
import script from "./TaskDetails.vue?vue&type=script&lang=ts&"
export * from "./TaskDetails.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./TaskDetails.vue?vue&type=style&index=0&id=0d5bab06&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0d5bab06",
  null
  ,true
)

export default component.exports