import availableDataStore from '@/store/availableDataStore/availableDataStore';
import { getAuthClient } from '@/services/auth';

export const availableDataGuard = async (to: any, from: any, next: ({}?) => void) => {
  const client = await getAuthClient();
  try {
    if (availableDataStore.availableDataIsEnabled) {
      next();
    } else {
      next({name: 'PlantConnectorList'});
    }
  } catch (e) {
    client.loginWithRedirect();
  }
};

