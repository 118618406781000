

















































import { Component, Watch } from 'vue-property-decorator';
import store from '@/store';
import TableComponent from '@/components/common/TableComponent.vue';
import Loading from '@/components/common/Loading.vue';
import { IBTableField } from '@/view-models/shared/b-table';
import { Route } from 'vue-router';
import { AgentViewModel, IAgentViewModel } from '@/view-models/i-agent-view-model';
import BaseComponent from '@/shared/BaseComponent.vue';
import { ActiveStatus, AgentStatus } from '@/enum/agent-status';
import { translatableResources } from '@/static/locales/translatableResources';
import TabbedNav from '@/components/common/TabbedNav.vue';
import { IBreadCrumbItem } from '@/view-models/i-breadcrumb';
import BreadCrumb from '@/components/common/BreadCrumb.vue';
import { getDefaultCrumbs } from '@/router/bread-crumb-routes';
import AgentManagementTable from '@/components/list/AgentManagementTable.vue';

@Component({
  name: 'plant-connector-list',
  components: {
    TableComponent,
    Loading,
    TabbedNav,
    BreadCrumb,
    AgentManagementTable
  }
})
export default class PlantConnectorList extends BaseComponent {
  public agentsLoaded: boolean = false;
  get agentsFields(): IBTableField[] {
    const agentFields = [
          {label: this.$t(`pcHome.table.name`).toString(), key: 'nameHighlighted', tdClass: 'col-width text-truncate'},
          {label: this.$t(`pcHome.table.status`).toString(), key: 'statusColored', tdClass: 'status-width text-truncate'},
          {label: this.$t(`pcHome.table.date`).toString(), key: 'createdByDate'},
          {label: this.$t(`pcHome.table.updatedby`).toString(), key: 'lastModifiedByName', tdClass: 'text-truncate'},
          {label: '', key: 'showActions', tdClass: 'actions-section'}
    ];
    if (this.agentVersionIsEnabled) {
      agentFields.splice(2, 0, {label: this.$t(`pcHome.table.version`).toString(), key: 'version', tdClass: 'col-width text-truncate'});
    }
    return agentFields;
  }
  get latestAgentVersion(): string {
    return `v${store.getters['agent/latestAgentVersion']}`;
  }
  get agents(): IAgentViewModel[] {
    return this.getListByStatus(store.state.agent.agents, ActiveStatus);
  }
  get agentVersionIsEnabled(): boolean {
    return store.getters['agent/agentVersionIsEnabled'];
  }
  get emptyText(): string {
    return this.$t(translatableResources.agent.statusAlert.emptyText).toString();
  }
  get searchConfiguringAgents(): string {
    return this.$t(`pcHome.configuring`, {text: 'Agents'}).toString().toLowerCase();
  }
  get searchMonitoringAgents(): string {
    return this.$t(`pcHome.monitoring`,{text: 'Agents'}).toString().toLowerCase();
  }
  get setupAgents(): IAgentViewModel[] {
    return this.getListByStatus(store.state.agent.agents, AgentStatus);
  }
  get specificCustomerKey() {
    return store.state.agent.specificCustomerKey;
  }
  get breadCrumbItems(): IBreadCrumbItem[] {
    return [
      ...getDefaultCrumbs(),
      {
        text: this.$t(`pcHome.title`, { action : ''}).toString(),
      }
    ];
  }

  public async mounted() {
    // Handle route changes from parent
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$on('onpoint::routeChange', this.handleRouteChange);
    }
    if (!store.state.agent.agentsLoaded) {
      await this.refreshData();
    } else {
      this.agentsLoaded = true;
    }
  }
  public beforeDestroy() {
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$off('onpoint::routeChange', this.handleRouteChange);
    }
  }
  public async handleRouteChange(parentRoute: Route) {
    if (parentRoute.path.match(/^\/?admin\/plant-connector[\/]?/)) {
      store.commit('app/updateIsLoading', false);
      let currentRelativeRoute = parentRoute.path.replace(/^\/?admin\/plant-connector/, '/');
      currentRelativeRoute = currentRelativeRoute.replace(/\/\//g, '/');
      if (currentRelativeRoute === '/') {
        await this.refreshData();
      }
    }
  }

  @Watch('specificCustomerKey')
  private specificCustomerKeyChanged(){
    store.commit('agent/setAgentsLoaded', false);
    this.refreshData();
  }
  
  private async refreshData() {
    await this.onLoad();
    if (this.specificCustomerKey) {
      await this.loadCustomerData();
    } else {
      await this.loadData();
    }
    this.agentsLoaded = true;
  }
  private async loadData() {
    this.agentsLoaded= false;
    await store.dispatch('agent/loadAll')
            .then(() => {
              this.agentsLoaded = true;
              store.commit('agent/setAgentsLoaded', true);
            })
            .catch((err) => {
              store.dispatch('error/setError', {
                error: err,
                errorString: 'Error Loading Plant Connector\n',
                handleError: true,
                routeHomeAfterError: false
              });
            });
    
  }
  private async loadCustomerData() {
    this.agentsLoaded= false;
    await store.dispatch('agent/loadSpecificCustomerData')
          .then(() => {
            this.agentsLoaded = true;
            store.commit('agent/setAgentsLoaded', true);
          })
          .catch((err) => {
            store.dispatch('error/setError', {
              error: err,
              errorString: 'Error Loading Plant Connector\n',
              handleError: true,
              routeHomeAfterError: false
            });
          });
  }

  private navigate() {
    store.commit('agentSelected/setSelectedAgent',new AgentViewModel());
    store.commit('dataSource/setDataSourcesLoaded', false);
    store.commit('taskDefinition/setTaskDefinitionsLoaded', false);
    this.$router.push('/create');
  }
  private getListByStatus( data: IAgentViewModel[], filterObj: any) {
    const statusValues = Object.keys(filterObj);
    const list = data.filter((obj) => statusValues.some((d) => obj.status === d));
    return list;
  }
}

