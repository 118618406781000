































// Libraries
import { Component, Vue } from 'vue-property-decorator';
import TabbedNav from '@/components/common/TabbedNav.vue';
import BreadCrumb from '@/components/common/BreadCrumb.vue';
import { getDefaultCrumbs } from '@/router/bread-crumb-routes';
import { IBreadCrumbItem } from '@/view-models/i-breadcrumb';
import AvailableDataTable from '@/components/available-data/AvailableDataTable.vue';
import ViewAvailableData from '@/components/available-data/ViewAvailableData.vue';
import CompareAvailableData from '@/components/available-data/CompareAvailableData.vue';
import UploadAvailableData from '@/components/available-data/UploadAvailableData.vue';
import store from '@/store';
import availableDataStore from '@/store/availableDataStore/availableDataStore';
import Loading from '@/components/common/Loading.vue';
import moment from 'moment';
import DatePickerRange, { DatePickerRangeEvent, SideOptions } from '@/components/dsm/DatePickerRange.vue';
import IconButton from '@/components/dsm/IconButton.vue';
import SearchBar from '@/components/common/SearchBar.vue';

@Component({
  name: 'available-data',
  components: {
    AvailableDataTable,
    TabbedNav,
    BreadCrumb,
    ViewAvailableData,
    CompareAvailableData,
    UploadAvailableData,
    Loading,
    DatePickerRange,
    IconButton,
    SearchBar
  }
})
export default class AvailableData extends Vue {
  public initialStartDateTime =  moment().hours(8).minutes(0);
  public initialEndDateTime = moment().hours(23).minutes(59);
  public date: DatePickerRangeEvent = {startDate: this.initialStartDateTime, endDate: this.initialEndDateTime} as DatePickerRangeEvent;
  public dateEvent: DatePickerRangeEvent = {startDate: this.initialStartDateTime, endDate: this.initialEndDateTime} as DatePickerRangeEvent;
  public sideOptions = [
    {name: 'Today', dates: [this.initialStartDateTime.toDate(), this.initialEndDateTime.toDate()], active: true},
    {name: 'Yesterday', dates: [moment().add(-24,'hours').hours(8).minutes(0).toDate(), moment().add(-24,'hours').hours(23).minutes(59).toDate()], active: false},
  ] as SideOptions[];
  public initialDates = Array.from(this.sideOptions[0].dates);
  public searchCorrelationKey = '';

  get maxDate() {
    return moment().toDate();
  }

  get breadCrumbItems(): IBreadCrumbItem[] {
    return [
      ...getDefaultCrumbs(),
      {
        text: this.$t(`availableData.title`, { action : ''}).toString(),
      }
    ];
  }
  get isLoading() {
    return store.getters['app/isLoading'];
  }

  get isBusy(): boolean {
    return availableDataStore.isBusy;
  }
  
  public submitReport() {
    this.$set(this, 'date', this.dateEvent);
    this.$nextTick(() => {
      (this.$refs['availableDataTable'] as AvailableDataTable).refreshTable();
    });
  }
  public refresh() {
    this.$set(this, 'date', {startDate: this.initialStartDateTime, endDate: this.initialEndDateTime} as DatePickerRangeEvent);
    this.$set(this, 'initialDates', Array.from(this.sideOptions[0].dates));
    this.$nextTick(() => {
      (this.$refs['availableDataTable'] as AvailableDataTable).refreshTable();
    });
  }

  public destroyed() {
    availableDataStore.resetState();
  }
  public showUploadDataModal(): void {
    availableDataStore.setUploadAvailableDataModal(true);
  }
  public setDate($event: DatePickerRangeEvent) {
    this.$set(this, 'dateEvent', $event);
  }
}
