















































import { Component, Vue } from 'vue-property-decorator';
import TextAreaCopy from '@/components/common/TextAreaCopy.vue';
import Loading from '@/components/common/Loading.vue';
import availableDataStore from '@/store/availableDataStore/availableDataStore';
import { FileType } from '@/enum/file-type';
import i18n from '@/i18n';
import { IAvailableDataResponse } from '@/view-models/i-available-data-view-model';
import StatusTimeLineView from '@/components/available-data/StatusTimeLineView.vue';
import { BFormFile } from 'bootstrap-vue';
import store from '@/store';
import { IHierarchy } from '@/view-models/i-hierarchy-model';
import { IBSelect } from '@/view-models/shared/b-select';
import { TagBasedTaskTypes } from '@/enum/task-definition-type';
import { toCamelCase } from '@/shared/helper-methods';
import { IAvailableDataUploadPayload } from '@/view-models/i-available-data-logs';
import notificationsStore from '@/store/notificationsStore/notificationsStore';
import TypeAheadDropdown, { TypeAheadItem } from '@/components/common/TypeAheadDropdown.vue';
import { cloneDeep } from 'lodash';
import assetServiceStore from '@/store/assetServiceStore/assetServiceStore';

@Component({
  name: 'upload-available-data',
  components: {
    TextAreaCopy,
    Loading,
    StatusTimeLineView,
    TypeAheadDropdown
  }
})
export default class UploadAvailableData extends Vue {
  private lmodalState = false;
  private file:File = new File([],'');
  private fileState: boolean | null = null;
  private assetChosen: string | null = null;
  private applicationType: string | null = null;
  private availableDataUpload: string | ArrayBuffer | null = null;
  public isUploading = false;

  get fileUploadState(): boolean | null {
    return this.fileState;
  }

  get title(): string {
    return i18n.t(`availableData.uploadAvailableData`).toString();
  }

  get hasSize(): boolean {
    return this.file?.size !== 0;
  }

  get hierarchies(): IHierarchy[] {
    return store.state.hierarchyBuilder.hierarchyList;
  }

  get assetOptions(): TypeAheadItem[] {
    let sorted;
    if (this.applicationType === TagBasedTaskTypes.HierarchyBuilder) {
      sorted = cloneDeep(availableDataStore.assetOptions);
    }

    if (this.applicationType === TagBasedTaskTypes.AssetService) {
      sorted = cloneDeep(assetServiceStore.assets).map((value) => ({name: value.assetName, value: value.assetKey}));
    }

    if (sorted) {
      sorted.sort((a: TypeAheadItem, b: TypeAheadItem) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      return sorted;
    }

    return [];
  }

  get applicationTypes(): IBSelect[] {
    const taskTypes = TagBasedTaskTypes;
    const applicationTypes = Object.values(taskTypes).map((equipment) => ({
      text: this.$t(`taskDefinition.applicationType.${toCamelCase(equipment)}`).toString(),
      value: equipment
    }));

    if (assetServiceStore.assetServiceIsEnabled) {
      return applicationTypes;
    } else {
      return applicationTypes.filter((value) => value.value !== TagBasedTaskTypes.AssetService);
    }
  }

  get currentItem(): IAvailableDataResponse {
    return availableDataStore.currentItem;
  }

  get fileType(): FileType {
    return availableDataStore.fileType;
  }

  get modalState(): boolean {
    return availableDataStore.showUploadAvailableData;
  }

  get isLoadingState(): boolean {
    return availableDataStore.isLoadingViewData;
  }

  get specificCustomerKey() {
    return store.getters['agent/specificCustomerKey'];
  }

  set modalState(flag: boolean) {
    this.lmodalState = flag;
    availableDataStore.setUploadAvailableDataModal(flag);
  }

  public async mounted() {
    if (availableDataStore.assetOptions.length === 0) {
      availableDataStore.getAssetOptions();
    }

    if (availableDataStore.towerViewAssetOptions.length === 0) {
      availableDataStore.getTowerViewSimulatorOptions();
    }

    if (assetServiceStore.assets.length === 0) {
      assetServiceStore.getAssetHierarchy(this.specificCustomerKey);
    }
  }

  public handleFileUpload(event: File): void {
    this.file = event;
  }

  public async upload(): Promise<void>{
    const payload = {
      customerKey: this.specificCustomerKey,
      assetKey: this.assetChosen,
      type: this.applicationType,
      file: this.file
    } as IAvailableDataUploadPayload;

    try {
      availableDataStore.setIsLoadingViewData(true);
      await availableDataStore.uploadAvailableData(payload);
      this.onClose();
      this.$emit('reload');
      availableDataStore.setUploadAvailableDataModal(false);
      notificationsStore.setMessage(`${i18n.t(`availableData.success`)}`);
      notificationsStore.toastContext.show('success-toast');
    } catch (error) {
      store.dispatch('error/setError', {
        error,
        errorString: 'Error while trying to upload data\n',
        handleError: true,
        routeHomeAfterError: false
      });
    } finally {
      availableDataStore.setIsBusy(false);
      availableDataStore.setIsLoadingViewData(false);
    }
  }

  private async onClose(): Promise<void> {
    await availableDataStore.resetModalState();
    await availableDataStore.setUploadAvailableDataModal(false);
    await availableDataStore.setCorrelationKey('');
    (this.$refs.availableDataUploadRef as BFormFile).reset();
    this.applicationType = null;
    this.assetChosen = null;
    this.resetTypeAhead();
    this.file = new File([],'');
  }

  public resetTypeAhead() {
    (this.$refs['typeahead'] as TypeAheadDropdown).resetAll();
  }

  public destroyed() {
    availableDataStore.resetModalState();
  }
}
