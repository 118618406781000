import Vue from 'vue';
import Vuex from 'vuex';
import { AppStore, IAppStoreState } from './appStore/appStore';
import { ErrorStore, IErrorStoreState } from './errorStore/errorStore';
import { AgentStore, IAgentStoreState } from './agentStore/agent';
import { DataSourceStore, IDataSourceStoreState } from './dataSourceStore/data-sources';
import { TaskDefinitionStore, ITaskDefinitionStoreState } from './taskDefinitionStore/task-definition';
import { AgentSelectedStore, IAgentSelectedStoreState } from './agentStore/selected-agent';
import { HierarchyBuilderStore, IHierarchyBuilderStoreState} from '@/store/hierarchyBuilderStore/hierarchy-builder';
import { IApiManagementState } from './apiManagementStore/apiManagementStore';
import { INotificationsState } from './notificationsStore/notificationsStore';
import { IAvailableDataState } from './availableDataStore/availableDataStore';
import { IAgentLogState } from './agentLogsStore/agentLogStore';
import { IAssetServiceState } from './assetServiceStore/assetServiceStore';
Vue.use(Vuex);

export interface IRootState {
  app: IAppStoreState;
  error: IErrorStoreState;
  agent: IAgentStoreState;
  agentSelected: IAgentSelectedStoreState;
  dataSource: IDataSourceStoreState;
  taskDefinition: ITaskDefinitionStoreState;
  hierarchyBuilder: IHierarchyBuilderStoreState;
  apiManagament: IApiManagementState;
  availableData: IAvailableDataState;
  notificationsStore: INotificationsState;
  agentLog: IAgentLogState;
  assetService: IAssetServiceState;
}

export default new Vuex.Store({
  modules: {
    app: AppStore,
    error: ErrorStore,
    agent: AgentStore,
    agentSelected: AgentSelectedStore,
    dataSource: DataSourceStore,
    taskDefinition: TaskDefinitionStore,
    hierarchyBuilder: HierarchyBuilderStore
  }
});
