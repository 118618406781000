import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import { IAssetServiceHierarchyModel } from '@/view-models/i-asset-service-hierarchy-model';
import { ITag } from '@/view-models/i-tag-view-model';
import { IAssetServiceAgentTags } from '@/view-models/i-asset-service-tag';

export class AssetService extends BaseService {
  constructor(axiosInstance: AxiosInstance, portalApiUrl: string) {
      super(portalApiUrl, '', axiosInstance, 'customers');
  }

  public async getPublishedAssets(customerKey: string): Promise<IAssetServiceHierarchyModel[]> {
    return this.httpClient.get<IAssetServiceHierarchyModel[]>(`${customerKey}/asset-service/published`);
  }

  public async getInputs(customerKey: string, assetKey: string): Promise<any> {
    return this.httpClient.get<ITag[]>(`${customerKey}/asset-service/${assetKey}/inputs`);
  }

  public async getTags(customerKey:string, assetKey:string): Promise<IAssetServiceAgentTags> {
    return this.httpClient.get<IAssetServiceAgentTags>(`${customerKey}/asset-service/${assetKey}/tags`);
  }
}
