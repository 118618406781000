


































































































import { Component } from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';
import DatePicker from '@/components/dsm/DatePicker.vue';
import moment, { Moment } from 'moment';
import AvailableDataTable from '@/components/available-data/AvailableDataTable.vue';
import ViewAvailableLogs from '@/components/available-data/ViewAvailableLogs.vue';
import IconButton from '@/components/dsm/IconButton.vue';
import availableDataStore from '@/store/availableDataStore/availableDataStore';
import agentLogStore from '@/store/agentLogsStore/agentLogStore';
import { AgentLogType, IAgentLogsDownloadPayload, IAgentLogsPayload, IAgentLogsResults } from '@/view-models/i-available-data-logs';
import store from '@/store';
import { IAgentViewModel } from '@/view-models/i-agent-view-model';
import notificationsStore from '@/store/notificationsStore/notificationsStore';
import i18n from '@/i18n';
import { downloadTextAsFile, downloadZip } from '@/shared/helper-methods';
import { FileType } from '@/enum/file-type';
import { AvailableDataModalState } from '@/enum/AvailableDataModalState.enum';
import { IBTableField } from '@/view-models/shared/b-table';
import Loading from '@/components/common/Loading.vue';
import { IBSelect } from '@/view-models/shared/b-select';
import { ITaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import { uniqBy } from 'lodash';
import formatDateLong from '@/shared/filters/formatDateLong';
import SearchBar from '@/components/common/SearchBar.vue';

@Component({
  name: 'agent-available-data-tab',
  components: {
    DatePicker,
    AvailableDataTable,
    ViewAvailableLogs,
    IconButton,
    Loading,
    SearchBar
  }
})
export default class AgentLogDataTab extends BaseComponent {
  private isRequestingLogs: boolean = false;
  private isDownloading: boolean = false;
  private isRefreshing: boolean = false;
  private dateFormatString = 'M/D/YYYY';
  private dateDisplayFormat = 'MMM-DD-YYYY';
  private momentDate!: Moment;
  private displayDate = moment().format(this.dateDisplayFormat);

  public initialDate = moment().toDate();
  public date: string = '';
  public logs: IAgentLogsResults[] = [] as IAgentLogsResults[];
  public currentPage = 1;
  public sortBy = 'name';
  public sortDesc = false;
  public perPageItems = 10;
  
  public typeKey: AgentLogType | null = null;
  public taskNameKey: string | null = null;
  public filter: string | null = null;
  public size = 0;
  public searchString: string = '';

  get typeOptions(): AgentLogType[] {
    return Object.values(AgentLogType);
  }
  
  public getTaskNameOptions(): IBSelect[] {
    const taskDefinitionList = store.getters['taskDefinition/getTaskDefinitionsList'] as ITaskDefinitionViewModel[];
    const results = uniqBy(this.logs?.map((log) => {
       const name = taskDefinitionList.filter((task) => task.taskDefinitionKey === log.taskDefinitionKey).map((taskName) => taskName.name);
       return ({value: log.taskDefinitionKey, text: name[0], type: log.type});
    }), 'text').filter((value) => value.text != null);
    
    if (this.typeKey === AgentLogType.TASK) {
      return results.filter((result) => result.type === AgentLogType.TASK);
    } else if (this.typeKey === AgentLogType.DATAUPDATE) {
      return results.filter((result) => result.type === AgentLogType.DATAUPDATE);
    } else {
      return results;
    }
  }

  get taskSrcOptions(): IBSelect[]  {
    const taskDefinitionList = store.getters['taskDefinition/getTaskDefinitionsList'] as ITaskDefinitionViewModel[];
  
     const results = this.logs?.map((value) => {
       const name = taskDefinitionList.filter((task) => task.taskDefinitionKey === value.taskDefinitionKey).map((taskName) => taskName.name);
       return ({value: value.taskDefinitionKey, text: name[0]});
     });

     return results as IBSelect[];
  }

  get maxDate() {
    return moment().toDate();
  }

  get minDate() {
    return moment().add(-2,'weeks').toDate();
  }
  
  get hasData(): boolean {
    return this.logs?.length > 0;
  }

  get selectedAgent(): IAgentViewModel {
    return store.state.agentSelected.selectedAgent;
  }

  get isHealthy(): boolean {
    return this.selectedAgent.status === this.runningStatuses.Healthy;
  }

  get totalRows() {
    return  this.size ?? 0;
  }

  get logFields(): IBTableField[] {
    return [
      {label: this.$t(`AgentLogs.fields.name`).toString(), key: 'displayName', tdClass: 'name text-truncate', sortable: true},
      {label: this.$t(`AgentLogs.fields.type`).toString(), key: 'type', tdClass: 'type text-truncate', sortable: true},
      {label: this.$t(`AgentLogs.fields.lastModified`).toString(), key: 'updatedOn', tdClass: 'last-modified text-truncate', formatter: 'lastModifiedSort', sortable: true, sortByFormatted: true},
      {label: this.$t(`AgentLogs.fields.actions`).toString(), key: 'showActions', tdClass: 'actions actions-section', sortable: false},
    ];
  }

  public async mounted(): Promise<void> {
    this.isRefreshing = true;
    this.date = moment().format(this.dateFormatString);
    await this.refreshAgentLogs();
    this.momentDate = moment();
  }
  
  public async refreshAgentLogs(): Promise<void> {
    const payload = {
      customerKey: this.selectedAgent.customerKey,
      agentKey: this.selectedAgent.agentKey,
      date: this.date
    } as IAgentLogsPayload;

    try {
      this.isRequestingLogs = true;
      this.isRefreshing = true;
      this.logs = await agentLogStore.refreshAgentLogs(payload) as IAgentLogsResults[];
      this.size = this.logs.length;
    } finally {
      this.isRequestingLogs = false;
      this.isRefreshing = false;
    }
  }
  public async requestAgentLogs(): Promise<void> {
    const payload = {
      customerKey: this.selectedAgent.customerKey,
      agentKey: this.selectedAgent.agentKey,
      date: this.date
    } as IAgentLogsPayload;

    try {
      this.isRequestingLogs = true;
      await agentLogStore.requestAgentLogs(payload);
      notificationsStore.setMessage(`${i18n.t(`AgentLogs.requestMessage`)}`);
      notificationsStore.toastContext.show('warning-toast');
      // Give the api some time to request files since we do not have response from requesting logs.
      setTimeout(() => this.refreshAgentLogs(), 1000);
    } finally {
      this.isRequestingLogs = false;
    }
  }

  public async changeDate(event: {date: Moment}) {
    this.displayDate = event.date.format(this.dateDisplayFormat);
    this.date = event.date.format(this.dateFormatString);
    this.taskNameKey = null;
    this.typeKey = null;
    const payload = {
      customerKey: this.selectedAgent.customerKey,
      agentKey: this.selectedAgent.agentKey,
      date: this.date
    } as IAgentLogsPayload;

    this.logs = await agentLogStore.refreshAgentLogs(payload) as IAgentLogsResults[];
    this.refreshAgentLogs();
  }
  
  public async downloadAllLogs(): Promise<void> {
    const payload = {
      customerKey: this.selectedAgent.customerKey,
      agentKey: this.selectedAgent.agentKey,
      date: this.date
    } as IAgentLogsPayload;
    
    const fileName = `${this.date}-${this.selectedAgent.agentKey}-logs`;

    try {
      this.isDownloading = true;
      this.isRefreshing = true;
      const results = await agentLogStore.downloadAll(payload);
      downloadZip(results, fileName);
    } finally {
      this.isDownloading = false;
      this.isRefreshing = false;
    }
  }

  public async download(data: IAgentLogsResults) {
    const payload = {
      customerKey: this.selectedAgent.customerKey,
      agentKey: this.selectedAgent.agentKey,
      fileName: data.name
    } as IAgentLogsDownloadPayload;

    try {
      this.isDownloading = true;
      (data as any).isLoading = true;
      const results = await agentLogStore.downloadAgentLogs(payload);

      if (results) {
        downloadTextAsFile(results, data.name, FileType.Text);
      }
    } finally {
      this.isDownloading = false;
      this.isRefreshing = false;
      (data as any).isLoading = false;
    }
  }
  
  public agentLogsFilter(row: IAgentLogsResults, filter: string) {
    // We need this since type task can only be TASK, UPDATE, AGENT, UNKNOWN
    if (filter === AgentLogType.ALL) {
      return true;
    }

    if (!!this.typeKey && !!this.taskNameKey && this.searchString === '') {
      return row.type?.toLowerCase() === this.typeKey?.toLowerCase() &&
                      row.taskDefinitionKey?.toLowerCase() === this.taskNameKey?.toLowerCase();
    }
    
    if (this.searchString !== '') {
      return row.name?.toLowerCase().toString().includes(this.searchString?.toLowerCase());
    } else {
      return row.type?.toLowerCase() === this.typeKey?.toLowerCase() || 
            row.taskDefinitionKey?.toLowerCase() === this.taskNameKey?.toLowerCase();
    }
  }
  public async show(data: IAgentLogsResults) {    
    try {
      this.isDownloading = true;
      await availableDataStore.setGeneratedLogs('');
      await availableDataStore.setFileType(FileType.Text);
      await availableDataStore.setCurrentModalState(AvailableDataModalState.Logs);
      await availableDataStore.setHeading(data.name);
      await availableDataStore.setPayloadModal(true);
      await availableDataStore.viewAgentLogs(data);
      await availableDataStore.setModalTitle(i18n.t(`availableData.logs`).toString());

    } finally {
      this.isDownloading = false;
    }
  }

  public clearFilter(): void {
    this.typeKey = null;
    this.taskNameKey = null;
    this.filter = null;
    this.searchString = '';
  }

  public resetDateTime() {
    this.initialDate = moment().toDate();
  }

  public onPageChange(item: number | null = null): void {
    this.$emit('onPageChange', item);
  }
  
  public lastModifiedSort(value:string): string {
    return formatDateLong(value);
  }

  public onFiltered(filteredItems: IAgentLogsResults[]) {
    this.size = filteredItems.length;
  }
}

