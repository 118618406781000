




import { Component, Prop } from 'vue-property-decorator';
import { IBreadCrumbItem } from '@/view-models/i-breadcrumb';
import BaseComponent from '@/shared/BaseComponent.vue';

@Component({
    name: 'bread-crumb'
})

export default class BreadCrumb extends BaseComponent {
  @Prop({ required: true })
  private breadCrumbItems!: IBreadCrumbItem[];
}
