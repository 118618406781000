

















































// Libraries
import { Component, Vue, Watch } from 'vue-property-decorator';
// View Models
import apiManagementStore from '@/store/apiManagementStore/apiManagementStore';
// Components
import TextAreaCopy from '@/components/common/TextAreaCopy.vue';
import Loading from '@/components/common/Loading.vue';
import store from '@/store';
import { IHierarchy } from '@/view-models/i-hierarchy-model';
import { downloadTextAsFile, toCamelCase} from '@/shared/helper-methods';
import { FileType } from '@/enum/file-type';
import TypeAheadDropdown, { TypeAheadItem } from '@/components/common/TypeAheadDropdown.vue';
import { cloneDeep } from 'lodash';
import { HierarchyApplication, TagBasedTaskTypes } from '@/enum/task-definition-type';
import { IBSelect } from '@/view-models/shared/b-select';
import assetServiceStore from '@/store/assetServiceStore/assetServiceStore';

@Component({
  name: 'generate-json-payload-confirm',
  components: {
    TextAreaCopy,
    Loading,
    TypeAheadDropdown
  }
})
export default class GenerateJsonPayloadConfirm extends Vue {
  private lmodalState = false;
  private assetChosen: string | null = null;
  private applicationType: string | null = null;
  public generatedPayload: string = '';
  public isLoading = false;

  get applicationTypes(): IBSelect[] {
    const taskTypes = TagBasedTaskTypes;
    const applicationTypes = Object.values(taskTypes).map((equipment) => ({
      text: this.$t(`taskDefinition.applicationType.${toCamelCase(equipment)}`).toString(),
      value: equipment
    }));

    if (assetServiceStore.assetServiceIsEnabled) {
      return applicationTypes;
    } else {
      return applicationTypes.filter((value) => value.value !== TagBasedTaskTypes.AssetService);
    }
  }
  get modalState(): boolean {
    return apiManagementStore.showPayloadModal;
  }
  get isLoadingState(): boolean {
    return this.isLoading;
  }
  get customerKey(): string {
    return store.getters['agent/specificCustomerKey'];
  }
  get hierarchies(): IHierarchy[] {
    return store.getters['hierarchyBuilder/hierarchyList'];
  }

  get assetOptions(): TypeAheadItem[] {
    let sorted;
    if (this.applicationType === TagBasedTaskTypes.HierarchyBuilder) {
      sorted = cloneDeep(this.hierarchies).map((value) => ({name: value.assetName, value: value.assetKey}));
    }

    if (this.applicationType === TagBasedTaskTypes.AssetService) {
      sorted = cloneDeep(assetServiceStore.assets).map((value) => ({name: value.assetName, value: value.assetKey}));
    }

    if (sorted) {
      sorted.sort((a: TypeAheadItem, b: TypeAheadItem) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      return sorted;
    }

    return [];
  }

  set modalState(flag: boolean) {
    this.lmodalState = flag;
    apiManagementStore.setExamplePayloadModal(flag);
  }

  @Watch('assetChosen')
  public async generatePayload() {
    if (this.assetChosen == null) return;

    this.generatedPayload = '';
    let assetChosen;
    this.isLoading = true;
    if (this.applicationType === TagBasedTaskTypes.HierarchyBuilder) {
      assetChosen = this.hierarchies.filter((hierarchy => hierarchy.assetKey === this.assetChosen))[0];

      if (!assetChosen) {
        store.dispatch('error/setError', {
                      error: new Error('Failed to find hierarchy id'),
                      errorString: 'Failed to find hierarchy id, please try again\n',
                      handleError: true,
                      routeHomeAfterError: false
                    });
        return;
      }

      await apiManagementStore.setSelectedHierarchy(assetChosen);
      await apiManagementStore.setTagsForApiManagement(this.customerKey);
      this.generatedPayload = await apiManagementStore.getExamplePayload(TagBasedTaskTypes.HierarchyBuilder);
      this.isLoading = false;
    }

    if (this.applicationType === TagBasedTaskTypes.AssetService) {
      await assetServiceStore.getAssetTags({customerKey: this.customerKey, assetKey: this.assetChosen});
      const tags = assetServiceStore.mappedAssetTags.tagMetaData;
      let keyValues = assetServiceStore.mappedAssetTags.keyValues;
      await apiManagementStore.setTags(tags.map((value) => value.name));

      const mandatoryKeyValues = {
        CustomerKey: this.customerKey,
        Application: HierarchyApplication.Ember,
        AssetKey: this.assetChosen
      };

      if (Object.entries(keyValues).length === 0) {
        keyValues = Object.assign({}, mandatoryKeyValues) as any;
      } else {
        keyValues = Object.assign({}, keyValues, mandatoryKeyValues);
      }

      await apiManagementStore.setKeyValues(keyValues);
      this.generatedPayload = await apiManagementStore.getExamplePayload(TagBasedTaskTypes.AssetService);
      this.isLoading = false;
    }
  }

  public download() {
    const assetChosen = this.hierarchies.filter((hierarchy => hierarchy.assetKey === this.assetChosen))[0];
    downloadTextAsFile(this.generatedPayload, `${assetChosen.hierarchyName}-${new Date().toISOString()}.json`, FileType.Json);
  }
  public resetTypeAhead() {
    (this.$refs['typeahead'] as TypeAheadDropdown).resetAll();
    this.generatedPayload = '';
  }
  private async onClose(): Promise<void> {
    await apiManagementStore.resetModalState();
    await apiManagementStore.setExamplePayloadModal(false);
    this.generatedPayload = '';
    this.assetChosen = null;
    this.applicationType = null;
  }
}
