











































import { FileType } from '@/enum/file-type';
import { downloadTextAsFile } from '@/shared/helper-methods';
import availableDataStore from '@/store/availableDataStore/availableDataStore';
import { Component, Vue } from 'vue-property-decorator';
import TextAreaCopy from '@/components/common/TextAreaCopy.vue';
import Loading from '@/components/common/Loading.vue';
import { AvailableDataModalState } from '@/enum/AvailableDataModalState.enum';

@Component({
  name: 'view-available-logs',
  components: {
    TextAreaCopy,
    Loading
  }
})
export default class ViewAvailableLogs extends Vue {
  private lmodalState = false;

  get title(): string {
    return availableDataStore.modalTitle;
  }

  get currentHeading(): string {
    return availableDataStore.heading;
  }

  get type(): AvailableDataModalState {
    return availableDataStore.currentModalState;
  }

  get fileType(): FileType {
    return availableDataStore.fileType;
  }

  get modalState(): boolean {
    return availableDataStore.showPayloadModal;
  }

  get isLoadingState(): boolean {
    return availableDataStore.isLoadingViewData;
  }

  get generatedPayload(): string {
    if (availableDataStore.currentModalState === AvailableDataModalState.Logs) {
      return availableDataStore.generatedLogs;
    }

    return '';
  }

  set modalState(flag: boolean) {
    this.lmodalState = flag;
    availableDataStore.setPayloadModal(flag);
  }
  
  public download() {
    const extension = this.fileType === FileType.Json ? '.json' : '.txt';
    downloadTextAsFile(this.generatedPayload,
                      `${this.currentHeading}${extension}`
                      , this.fileType);
  }
  private async onClose(): Promise<void> {
    await availableDataStore.resetModalState();
    await availableDataStore.setPayloadModal(false);
  }

  public destroyed() {
    availableDataStore.resetModalState();
  }
}
