










































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';
import { IDictionary, ITaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import store from '@/store';
import { IAccordianDetails } from '@/view-models/i-accordian';
import { StepProgressState } from '@/enum/step-progress-state';
import HelperMethods from '@/shared/helper-methods';
import {TagBasedTaskTypes} from '@/enum/task-definition-type';
import {IHierarchy} from '@/view-models/i-hierarchy-model';
import IconButton from '@/components/dsm/IconButton.vue';

@Component({
  name: 'key-values-view',
  components: {
    IconButton
  }
})
export default class KeyValuesView extends BaseComponent {
  @Prop({required: true})
  private stepData!: IAccordianDetails;
  private keyValuePairs: IDictionary[] = [];

  get isHealthy(): boolean {
    return this.specificTaskDefinition.status === this.runningStatuses.Healthy;
  }
  get selectedTaskType(): any {
    return store.state.taskDefinition.selectedTaskType;
  }
  get specificTaskDefinition(): ITaskDefinitionViewModel {
    return store.state.taskDefinition.specificTaskDefinition;
  }
  get sectionDetails(): ITaskDefinitionViewModel {
    return store.state.taskDefinition.sectionDetails;
  }
  get isHierarchyBuilderType(): boolean {
    return this.selectedTaskType === TagBasedTaskTypes.HierarchyBuilder;
  }
  get hierarchyKeyValue(): IDictionary[] {
    return store.state.hierarchyBuilder.keyValue;
  }
  get isHierarchiesEmpty(): boolean {
    return this.checkHierarchy();
  }
  get isInvalidKey(): boolean {
    const uniqueValues = new Set(this.keyValuePairs.map((item) => item.key));
    if (uniqueValues.size < this.keyValuePairs.length) {
      return true;
    }
    return false;
  }
  get isEmpty(): boolean {
    return this.keyValuePairs.some((item) => HelperMethods.isStringEmpty(item.key));
  }
  get hierarchies(): IHierarchy[] {
    return store.state.hierarchyBuilder.hierarchyList;
  }
  public checkHierarchy(): boolean {
    return !this.hierarchies || (this.hierarchies && this.hierarchies.length ===0);
  }
  public mounted(): void {
    this.loadTaskData();
    this.watchValues();
  }
  public async loadTaskData(): Promise<void> {
    if (this.$route.params.taskid) {
      this.setTaskDefinitionInfo(this.specificTaskDefinition);
    } else {
      this.isHierarchyBuilderType ? this.keyValuePairs = this.hierarchyKeyValue
          : this.resetTaskDefinitionInfo();
    }
  }
  public async watchValues(): Promise<void> {

    if (this.isHierarchyBuilderType && this.hierarchyKeyValue) {
      this.keyValuePairs = this.transformObject(this.hierarchyKeyValue);
    }

    this.isHierarchyBuilderType ? store.commit('hierarchyBuilder/setKeyValue', this.keyValuePairs)
        : store.commit('taskDefinition/updateKeyValuePairs',this.keyValuePairs);

    const updatedObject = {} as ITaskDefinitionViewModel;
    this.updateStepProgress();

    updatedObject.keyValues = this.transformArray(this.keyValuePairs);
    await store.commit('taskDefinition/updateSectionDetails',updatedObject);
  }
  public updateStepProgress(): void {
    if (this.keyValuePairs && this.keyValuePairs.length > 0 && !this.isEmpty && !this.isInvalidKey) {
      this.stepData.state = StepProgressState.Completed;
    } else {
      this.stepData.state = StepProgressState.Pending;
    }
    store.dispatch('taskDefinition/updateAccordianDetail', this.stepData);
  }
  public setTaskDefinitionInfo(data: ITaskDefinitionViewModel): void {
    if (data.name) {
      this.keyValuePairs = this.transformObject(data.keyValues);
    }
  }
  public resetTaskDefinitionInfo(): void {
    this.keyValuePairs = this.transformObject({});
  }
  public addPair(): void {
    const addObj: IDictionary = {
      key: '',
      value: ''
    };
    this.keyValuePairs.push(addObj);
    this.watchValues();
  }
  public deletePair(pair: IDictionary): void {
    const keyIndex: number = this.keyValuePairs.
      findIndex((pairs: IDictionary) => pair.key === pairs.key);
    if (keyIndex > -1) {
      this.keyValuePairs.splice(keyIndex, 1);
    }
    this.watchValues();
  }
  public transformObject(objKeyValuePairs: object): IDictionary[] {
    if ( objKeyValuePairs && Object.entries(objKeyValuePairs)) {
      const keyValuePairs = Object.entries(objKeyValuePairs).map((item) => ({
        key: item[0],
        value: item[1]
      })).filter((value => value.key !== 'HierarchyKey' && value.key !== 'HierarchyName'));
      return keyValuePairs;
    }
    return [];
  }
  public transformArray(keyValuePairs: IDictionary[]): object {
    if (keyValuePairs && keyValuePairs.length > 0) {
      const objKeyValuePairs = Object.assign({}, ...keyValuePairs.map((item) => ({[item.key]:item.value})));
      return objKeyValuePairs;
    }
    return {};
  }
}
