export interface INotificationsState {
  message: string;
  toastContext: BvToast;
  setMessage(message: string): Promise<{message: string}>;
  setToastContext(toastContext: BvToast): Promise<{toastContext: BvToast}>;
}

import store from '@/store';
import { BvToast } from 'bootstrap-vue';
import { VuexModule, Module, getModule, MutationAction} from 'vuex-module-decorators';

@Module({dynamic: true, name: 'notificationsStore', store})
export class NotificationsStore extends VuexModule implements INotificationsState {
  public message: string = '';
  public toastContext: BvToast = {} as BvToast;

  @MutationAction({ mutate: ['message'], rawError: true })
  public async setMessage(message: string): Promise<{message: string}> {
    return { message };
  }
  @MutationAction({ mutate: ['toastContext'], rawError: true })
  public async setToastContext(toastContext: BvToast): Promise<{toastContext: BvToast}> {
    return { toastContext };
  }
}

export default getModule(NotificationsStore, store);
