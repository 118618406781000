























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SearchBar from '@/components/common/SearchBar.vue';

@Component({
  name: 'type-ahead-dropdown',
  components: {
    SearchBar
  }
})
export default class TypeAheadDropdown extends Vue {
  @Prop({required: true})
  public dropdownOptions!: TypeAheadItem[];

  @Prop({required: false, default: 'Placeholder'})
  public placeholder!:string;

  @Prop({required: false, default:'Add Option'})
  public addOptionText!: string;

  public dOptions: TypeAheadItem[] = [];
  
  public showDropdown: boolean = false;
  public selectedType: string = '';
  
  public created() {
    this.dOptions = [...this.dropdownOptions];
  }

  @Watch('dropdownOptions')
  public setdOptions(){
    this.dOptions = [];
    this.dOptions = [...this.dropdownOptions];
  }
  
  private parentEl: HTMLElement = {} as HTMLElement;

  public mounted() {
    this.parentEl = this.$el.closest('#app') as HTMLElement;
    this.parentEl.addEventListener('click', this.clickedOutside);
  }

  public updated() {
    const selectedOption = this.dOptions.filter(value => value.active);
    
    if (selectedOption.length > 0) {
      this.selectedType = selectedOption[0].name;
    }
    
  }

  public clickedOutside(event: MouseEvent) {
    const isDropDown = (this.$refs.typeaheaddropdown as HTMLElement) ? 
      (this.$refs.typeaheaddropdown as HTMLElement).contains(event.target as HTMLElement) : false;

    if (!isDropDown) {
      this.hideDropDown();
    }
  }

  public select(option: TypeAheadItem) {
    this.selectedType = option.name;
    this.resetAll();
    this.$set(option, 'active', true);
    this.$emit('selectedType', option);
    this.hideDropDown();
  }

  public resetAll() {
    this.dOptions.forEach((value) => value.active = false);
    this.dropdownOptions.forEach((value) => value.active = false);
    this.selectedType = '';
  }

  public hideDropDown() {
    this.showDropdown = false;
    this.dOptions = [];
    this.dOptions = [...this.dropdownOptions];
  }

  public filterTypes(search: string) {
    const results = this.dropdownOptions.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
    this.dOptions = [];
    this.dOptions = [...results];
  }

  public destroy() {
    this.parentEl.removeEventListener('click', this.clickedOutside);
  }
}
export interface TypeAheadItem {
  name: string;
  value: any;
  active?: boolean;
}
