






















































































import { Component, Prop } from 'vue-property-decorator';
import StatusVisual from '@/components/common/StatusVisual.vue';
import BaseComponent from '@/shared/BaseComponent.vue';
import { ITaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import store from '@/store';
import notificationsStore from '@/store/notificationsStore/notificationsStore';
import i18n from '@/i18n';
import { ActiveStatus } from '@/enum/agent-status';
import { BDropdown } from 'bootstrap-vue';

@Component({
  name: 'tasks',
  components: {
    StatusVisual
  }
})
export default class Tasks extends BaseComponent {
  @Prop({ required: true })
  public taskDefinition!: ITaskDefinitionViewModel;

  get isInProgress(): boolean {
    return this.taskDefinition.status !== this.taskDefinition.desiredStatus;
  }
  get isHealthy(): boolean {
    return this.taskDefinition.status === this.runningStatuses.Healthy;
  }
  get agentHealth(): boolean {
    return store.state.agentSelected.selectedAgent.status === this.runningStatuses.Healthy;
  }
  get isValidating(): boolean {
    return this.taskDefinition.status === this.runningStatuses.PendingValidation;
  }
  public frequencyFormat(value: string): string {
    return value.slice(0, -1) + ' ' + this.$t(`taskDefinition.frequencyVal.` + value.slice(-1));
  }
  public async editTaskDef(taskInfo: ITaskDefinitionViewModel) {
    this.$router.push('/' + taskInfo.agentKey + '/task/' + taskInfo.taskDefinitionKey);
  }
  public async toggleTaskDefStatus(taskInfo: ITaskDefinitionViewModel): Promise<void> {
    const isPendingValidation = taskInfo.status === ActiveStatus.PendingValidation;
    await store.dispatch('taskDefinition/toggleTaskDefinitionStatus',taskInfo).catch((error) => {
         store.dispatch('error/setError', {
            error: isPendingValidation ? '' : error,
            errorString: isPendingValidation ? 'This task is currently validating tags. Please wait until it is completed before you can enable/disable.': 'Failed to disable or enable the current task.\n',
            handleError: true,
            routeHomeAfterError: false
          });
    });
  }
  public async triggerRun(taskInfo: ITaskDefinitionViewModel): Promise<void> {
    store.dispatch('taskDefinition/triggerRun',taskInfo).then(() => {
      notificationsStore.toastContext.show('success-toast');
      notificationsStore.setMessage(`${i18n.t(`taskDefinition.successTrigger`)}`);
    }).catch((e) => {
      notificationsStore.toastContext.hide();
      notificationsStore.toastContext.show('failure-toast');
      notificationsStore.setMessage(`${i18n.t(`taskDefinition.errorTrigger`)} ${e}`);
    });
  }
  public show() :void {
    (this.$refs[`dropdown-${this.taskDefinition.taskDefinitionKey}`] as BDropdown).show();
  }
  public validateTags(): void {
    const payload = {
      agentKey: this.taskDefinition.agentKey,
      taskKey: this.taskDefinition.taskDefinitionKey
    };
    store.dispatch('taskDefinition/validateTags', payload)
      .then(() => {
        notificationsStore.setMessage(`${i18n.t('taskDefinition.validatingTagsMessage')}`);
        notificationsStore.toastContext.show('success-toast');
      })
      .catch((error) => {
        store.dispatch('error/setError', {
          error,
          errorString: 'Error Validating Tags\n',
          handleError: true,
          routeHomeAfterError: false
        });
     });
  }
}
