import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import store, { IRootState } from '@/store';
import ConfigFactory from '@/services/config';
import sharedAxiosInstance from '@/services/api-service';
import { IHierarchy } from '@/view-models/i-hierarchy-model';
import { HierarchyService } from '@/services/hierarchy-service';
import { ITag, ITagData } from '@/view-models/i-tag-view-model';
import { IDictionary, TaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import { IHierarchyAgentTags, TagMetaData } from '@/view-models/i-hierarchy-tags';
import { IAsset } from '@/view-models/i-asset-view-model';
import { ActiveStatus } from '@/enum/agent-status';
import { TagsService } from '@/services/tags-service';

export const name: string = 'hierarchy';

export const types = {
    getters: {
        GET_HIERARCHY_SELECTED_TAGS:'getHierarchySelectedTags',
        IS_HIERARCHY_LOADED: 'isHierarchyLoaded',
        HAS_SELECTED_HIERARCHY: 'hasSelectedHierarchy'
    },
    mutations: {
        SET_HIERARCHY_LIST: 'setHierarchyList',
        SET_HIERARCHY_LOADED:'setHierarchyLoaded',
        SET_SELECTED_HIERARCHY: 'setSelectedHierarchy',
        SET_TAG_LIST: 'setTagList',
        SET_KEY_VALUE: 'setKeyValue',
        SET_MAPPED_HIERARCHY_SELECTED_TAGS: 'setMappedHierarchySelectedTags',
        SET_HIERARCHY_SELECTED_TAGS:'setHierarchySelectedTags',
        SET_SELECTED_ASSET: 'setSelectedAsset',
        SET_SELECTED_APPLICATION : 'setSelectedApplication'
    },
    actions: {
        GET_HIERARCHY_LIST: 'getHierarchyList',
        GET_TAGS_BY_HIERARCHY_ID: 'getTagsByHierarchyId',
        CONVERT_HIERARCHY_SELECTED_TAGS: 'convertHierarchySelectedTags',
        GET_HIERARCHY_FROM_PUBLISHED_HIERARCHY:'getHiearchyFromPublishedHierarchy',
        SET_SELECTED_HIERARCHY: 'setSelectedHierarchy',
    }
};

export interface IHierarchyBuilderStoreState {
    hierarchyList: IHierarchy[];
    selectedHierarchy: IHierarchy;
    selectedAsset: IAsset;
    isHierarchyLoaded: boolean;
    tagList: string[];
    keyValue: IDictionary[];
    mappedHierarchySelectedTags: ITagData [];
    hierarchySelectedTags: ITagData[];
    hierarchyApplicationType: string;
}

export interface HierarchyApplicationPayload {
    assetKey: string;
    applicationType: string;
}

export interface HierarchyBuilderStoreGetters extends GetterTree<IHierarchyBuilderStoreState, IRootState> {
    getHierarchySelectedTags(state: IHierarchyBuilderStoreState): ITag[];
    isHierarchyLoaded(state: IHierarchyBuilderStoreState): boolean;
    hasSelectedHierarchy(state: IHierarchyBuilderStoreState): boolean;
    selectedHierarchy(state: IHierarchyBuilderStoreState): IHierarchy;
    selectedAsset(state: IHierarchyBuilderStoreState): IAsset;
    hierarchyList(state: IHierarchyBuilderStoreState): IHierarchy[];
    getSelectedApplicationType(state: IHierarchyBuilderStoreState): string;
}

export interface HierarchyBuilderStoreMutations extends MutationTree<IHierarchyBuilderStoreState> {
    setSelectedHierarchy(state: IHierarchyBuilderStoreState,
                         selectedHierarchy: IHierarchy): void;
    setHierarchyLoaded(state: IHierarchyBuilderStoreState): void;
    setHierarchyList(state: IHierarchyBuilderStoreState,
                     hierarchyList: IHierarchy[]): void;
    setTagList(state: IHierarchyBuilderStoreState,
               hierarchyList: string[]): void;
    setKeyValue(state: IHierarchyBuilderStoreState,
                keyValue: IDictionary[]): void;
    setMappedHierarchySelectedTags(state: IHierarchyBuilderStoreState,
                                   mappedHierarchyTags: ITag[]): void;
    setHierarchySelectedTags(state: IHierarchyBuilderStoreState,
                             hierarchySelectedTags: ITag[]): void;
    setSelectedAsset(state: IHierarchyBuilderStoreState, asset: IAsset): void;
    setSelectedApplication(state: IHierarchyBuilderStoreState, type: string): void;
}

export interface HierarchyBuilderStoreActions extends ActionTree<IHierarchyBuilderStoreState, IRootState> {
    getHierarchyList(context: HierarchyBuilderContext): void;
    getTagsByHierarchyId(context: HierarchyBuilderContext, hierarchy: IHierarchy): void;
    getHierarchyListBySpecificCustomerKey(context: HierarchyBuilderContext): void;
    convertHierarchySelectedTags(context: HierarchyBuilderContext, data: TagMetaData[]): void;
    getHiearchyFromPublishedHierarchy(context: HierarchyBuilderContext,
                                      hierarchyKey: string): Promise<IHierarchy | null>;
    setSelectedHierarchy(context: HierarchyBuilderContext, hierarchy: IHierarchy): Promise<void>;
    updateHierarchyList(context: HierarchyBuilderContext, hierarchyList: IHierarchy[]): Promise<void>;
    setSelectedAsset(context: HierarchyBuilderContext, assetKey: string): void;
    setSelectedApplication(context: HierarchyBuilderContext, type: string): Promise<void>;
}

export const initHierarchyBuilderService = async (path: string) => {
    const conf = await ConfigFactory.GetConfig();
    return new HierarchyService(sharedAxiosInstance.sharedHierarchyAxiosInstance,
      process.env.VUE_APP_HIERARCHY_BUILDER_API_BASE_URL ?
        process.env.VUE_APP_HIERARCHY_BUILDER_API_BASE_URL :
        conf.get('hbApiUrl'), path);
};

export const initTagsService = async (path: string) => {
    const conf = await ConfigFactory.GetConfig();
    return new TagsService(sharedAxiosInstance.sharedTagsAxiosInstance,
      process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL ?
        process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL :
        conf.get('pcApiUrl'), path);
};

export type HierarchyBuilderContext = ActionContext<IHierarchyBuilderStoreState, IRootState>;

export const HierarchyBuilderStore = {
    namespaced: true,
    state: {
        hierarchyList: Array<IHierarchy>(),
        selectedHierarchy: {},
        isHierarchyLoaded: false,
        selectedAsset: {}
    } as IHierarchyBuilderStoreState,
    getters: {
        getHierarchySelectedTags(state: IHierarchyBuilderStoreState): ITag[] {
            return state.hierarchySelectedTags;
        },
        getSelectedApplicationType(state: IHierarchyBuilderStoreState): string {
            return state.hierarchyApplicationType;
        },
        isHierarchyLoaded(state: IHierarchyBuilderStoreState): boolean {
            return state.isHierarchyLoaded;
        },
        hasSelectedHierarchy(state: IHierarchyBuilderStoreState): boolean {
            return state.selectedHierarchy &&
                   Object.keys(state.selectedHierarchy).length !== 0 &&
                   state.selectedHierarchy.constructor === Object;
        },
        selectedHierarchy(state: IHierarchyBuilderStoreState): IHierarchy {
            return state.selectedHierarchy;
        },
        selectedAsset(state: IHierarchyBuilderStoreState): IAsset {
            return state.selectedAsset;
        },
        hierarchyList(state: IHierarchyBuilderStoreState): IHierarchy[] {
            return state.hierarchyList;
        }
    } as HierarchyBuilderStoreGetters,
    mutations: {
        setHierarchyList(state: IHierarchyBuilderStoreState,
                         hierarchyList: IHierarchy[]): void {
            state.hierarchyList = hierarchyList;
        },
        setSelectedHierarchy(state: IHierarchyBuilderStoreState, hierarchy: IHierarchy): void {
            state.selectedHierarchy = hierarchy;
        },
        setSelectedAsset(state: IHierarchyBuilderStoreState, asset: IAsset): void {
            state.selectedAsset = asset;
        },
        setHierarchyLoaded(state: IHierarchyBuilderStoreState, isLoaded: boolean): void {
            state.isHierarchyLoaded = isLoaded;
        },
        setTagList(state: IHierarchyBuilderStoreState, tags: string[]): void {
            state.tagList = tags;
        },
        setKeyValue(state: IHierarchyBuilderStoreState,
                    keyValue: IDictionary[]): void {
            state.keyValue = keyValue;
        },
        setMappedHierarchySelectedTags(state: IHierarchyBuilderStoreState,
                                       mappedHierarchyTags: ITagData[]): void {
            state.mappedHierarchySelectedTags = mappedHierarchyTags;
        },
        setHierarchySelectedTags(state: IHierarchyBuilderStoreState,
            hierarchySelectedTags: ITagData[]): void {
            state.hierarchySelectedTags = hierarchySelectedTags;
        },
        setSelectedApplication(state: IHierarchyBuilderStoreState, type: string): void {
            state.hierarchyApplicationType = type;
        }
    } as HierarchyBuilderStoreMutations,
    actions: {
        async getHierarchyList(context: HierarchyBuilderContext): Promise<void> {
            const hierarchyBuilderService = await initHierarchyBuilderService('customers');
            const custKey = context.rootState.agentSelected?.selectedAgent?.customerKey;
            context.commit(types.mutations.SET_HIERARCHY_LOADED, false);
            if (custKey) {
                try {
                    const response: IHierarchy[] = await hierarchyBuilderService.getPublishedHierarchies(custKey);
                    context.commit(types.mutations.SET_HIERARCHY_LIST, response);
                    context.commit(types.mutations.SET_HIERARCHY_LOADED,true);
                } catch (error) {
                    store.dispatch('error/setError', {
                        error,
                        errorString: 'Error while getting hierarchy list\n',
                        handleError: true,
                        routeHomeAfterError: false
                    });
                } finally {
                    context.commit(types.mutations.SET_HIERARCHY_LOADED,true);
                }
            }
        },

        async updateHierarchyList(context: HierarchyBuilderContext, hierarchyList: IHierarchy []): Promise<void> {
            context.commit(types.mutations.SET_HIERARCHY_LIST, hierarchyList);
        },
        async getHierarchyListBySpecificCustomerKey(context: HierarchyBuilderContext): Promise<void> {
            const hierarchyBuilderService = await initHierarchyBuilderService('customers');
            const custKey = store.getters['agent/specificCustomerKey'];
            context.commit(types.mutations.SET_HIERARCHY_LOADED, false);
            if (custKey) {
                try {
                    const response: IHierarchy[] = await hierarchyBuilderService.getPublishedHierarchies(custKey);
                    context.commit(types.mutations.SET_HIERARCHY_LIST, response);
                    context.commit(types.mutations.SET_HIERARCHY_LOADED,true);
                } catch (error) {
                    store.dispatch('error/setError', {
                        error,
                        errorString: 'Error while getting hierarchy list\n',
                        handleError: true,
                        routeHomeAfterError: false
                    });
                }
            }
        },
        convertHierarchySelectedTags(context: HierarchyBuilderContext, data: TagMetaData[]): void {
            const selectedTask = store.getters['taskDefinition/getSpecificTaskDefinition'] as TaskDefinitionViewModel;
            const selectedTagsFromTask = selectedTask?.selectedTags;
            const hierarchySelectedTags: ITagData[] = data.map((value) => {
                if (selectedTagsFromTask?.length > 0 && selectedTask?.status !== ActiveStatus.PendingValidation) {
                    for (let tag of selectedTagsFromTask) {
                        if (tag.name.toLocaleLowerCase() === value.name.toLowerCase()) {
                            return { 
                                     name: value?.name, 
                                     isValid: tag?.isValid, 
                                     dataType: tag?.dataType, 
                                     sourceDataType: tag?.sourceDataType, 
                                     key: tag?.key,
                                     direction: value?.direction
                            } as ITagData;
                        }
                    }
                }
                return { name: value.name, isValid: null, direction: value.direction } as ITagData;
            });
            context.commit(types.mutations.SET_HIERARCHY_SELECTED_TAGS, hierarchySelectedTags);
        },
        async getTagsByHierarchyId(context: HierarchyBuilderContext, hierarchy: IHierarchy): Promise<void> {
            if (context.rootState.dataSource.selectedDataSource && hierarchy) {
                const tagsService = await initTagsService('customers');
                const custKey = context.rootState.agentSelected?.selectedAgent?.customerKey;
                try {
                    const response: IHierarchyAgentTags =  await tagsService.getTagsByHierarchyId(custKey, hierarchy);
                    context.commit(types.mutations.SET_KEY_VALUE, response.keyValues);
                    await context.dispatch(types.actions.CONVERT_HIERARCHY_SELECTED_TAGS, response.tagMetaData);
                    const hierarchySelectedTags: ITagData[] = context.state.hierarchySelectedTags;
                    context.commit(types.mutations.SET_MAPPED_HIERARCHY_SELECTED_TAGS, hierarchySelectedTags);
                } catch (error) {
                    store.dispatch('error/setError', {
                        error,
                        errorString: 'Error while getting tags for selected hierarchy\n',
                        handleError: true,
                        routeHomeAfterError: false
                    });
                }
            }
        },
        async getHiearchyFromPublishedHierarchy(context: HierarchyBuilderContext,
                                                hierarchyKey: string): Promise<IHierarchy | null> {
            const storeHierarchyList = context.state.hierarchyList ?? {};
            const hierarchyList = JSON.parse(JSON.stringify(storeHierarchyList));
            return hierarchyList.find((hierarchy: IHierarchy) => hierarchy.key === hierarchyKey ) ?? null;
        },
        async setSelectedHierarchy(context: HierarchyBuilderContext, hierarchy: IHierarchy): Promise<void> {
            context.commit(types.mutations.SET_SELECTED_HIERARCHY, hierarchy);
        },
        async setSelectedAsset(context: HierarchyBuilderContext, assetKey: string): Promise<void> {
            try {
                const assets = store.getters['taskDefinition/getAssets'] as IAsset[];
                const [asset] = assets.filter((asset) => asset.key === assetKey);
                context.commit(types.mutations.SET_SELECTED_ASSET, asset);
            } catch (error) {
                store.dispatch('error/setError', {
                    error,
                    errorString: 'Failed to set Asset\n',
                    handleError: true,
                    routeHomeAfterError: false
                });
            }
        },
        async setSelectedApplication(context: HierarchyBuilderContext, type: string): Promise<void> {
            context.commit(types.mutations.SET_SELECTED_APPLICATION, type);
        }
    } as HierarchyBuilderStoreActions
};
