import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '@/store';
import { IErrorBusMessage, ErrorBusMessage, IErrorMessageConfig } from '@/view-models/error-model';
import { AxiosError } from 'axios';

const EVENT_NAME = 'GLOBAL_ERROR';
const APP_TAG = 'PLANT_CONNECTOR';

export interface IErrorStoreState {
    error: any; // Error message
    handleError: boolean; // Handle error within child app
    routeHomeAfterError: boolean; // Force user to return to home screen
    config: IErrorMessageConfig; // Parent app error configs
    showModal: boolean; // Show child app error modal
    eventBus: any; // Parent app error event bus
}

export interface IErrorStoreGetters extends GetterTree<IErrorStoreState, IRootState> {
  getError(state: IErrorStoreState): string;
}

export interface IErrorStoreMutations extends MutationTree<IErrorStoreState> {
  clearError(state: IErrorStoreState): void;
}

export interface IErrorStoreActions extends ActionTree<IErrorStoreState, IRootState> {
  setError(context: IErrorContext, payload: {error: any, config?: IErrorMessageConfig}): Promise<void>;
  parseError(context: IErrorContext, payload: any): string;
  parseClientError(context: IErrorContext, payload: any): Promise<string>;
  parseServiceError(context: IErrorContext, payload: any): Promise<string>;
  tryExecute(context: IErrorContext, action: any): Promise<void>;
}

export type IErrorContext = ActionContext<IErrorStoreState, IRootState>;

export const ErrorStore = {
    namespaced: true,
    state: {
        error: null,
        handleError: false,
        routeHomeAfterError: false,
        showModal: false,
        config: {
          logSilently: false,
          showMessage: false
        }
    } as IErrorStoreState,
    getters: {
        getError(state: IErrorStoreState) {
            return state.error;
        },
        getRouteHomeAfterError(state: IErrorStoreState) {
            return state.routeHomeAfterError;
        }
    } as IErrorStoreGetters,
    mutations: {
      clearError: (state: IErrorStoreState) => {
          state.error = null;
          state.handleError = false;
          state.routeHomeAfterError = false;
          state.showModal = false;
        }
    } as IErrorStoreMutations,
    actions: {
      async setError(context: IErrorContext, payload: {error: any, errorString: string,
        handleError: boolean, routeHomeAfterError: boolean}): Promise<void> {
          // Checking if payload has an error instance
          if (payload.error) {
              // Check if error has a response
              if (payload.error.response) {
                  const statusCode = payload.error.response.status;
                  if (statusCode >= 400 && statusCode < 500) {
                    context.state.error = await context.dispatch('parseClientError', payload);
                  } else if (statusCode >= 500) {
                    context.state.error = await context.dispatch('parseServiceError', payload);
                  } else {
                    context.state.error = payload.errorString + '.\nUnknown Error: ' + statusCode;
                  }
              } else if (payload.error.message) {
                context.state.error = payload.errorString + payload.error.message;
              } else if (payload.error.error_description) {
                context.state.error = payload.errorString + '.\n' + payload.error.error_description;
              } else { // This is handling non http error codes
                context.state.error = payload.errorString;
              }
          } else { // Setting an error with error string passed if error instance is not present
              context.state.error = payload.errorString;
          }
          context.state.handleError = payload.handleError;
          context.state.routeHomeAfterError = payload.routeHomeAfterError;
          emitError();
      },
      async tryExecute(context: IErrorContext, payload: any): Promise<void> {
          try {
              if (payload.action) {
                  await payload.action();
              }
          } catch (err) {
              const errorString = `${payload?.errorMsg}\n`;
              await context.dispatch('setError', {
                  error: err,
                  errorString,
                  handleError: true,
                  routeHomeAfterError: payload?.routeHomeAfterError ?? false
              });
          }
      },
      async parseClientError(context: IErrorContext, payload: any): Promise<string> {
          const error = (payload.error as AxiosError).response;
          switch (payload.error.response.status) {
              case 400:
                if (!!error && (error.data != null && error?.data !== '')) {
                  if (typeof error?.data === 'string') {
                    return `${payload.errorString}${error.data}`;
                  }
                  if (error?.data?.value) {
                    return `${payload.errorString}${error.data.value}`;
                  }
                }
                if (!!error && (error?.data !== '') && error?.data?.errors) {
                  return  `${payload.errorString}${JSON.stringify(error.data.errors)}`;
                }
                return payload.errorString + 'Bad request.';
              case 404: {
                if (!!error && (error.data != null && error?.data !== '' && typeof error?.data === 'string')) {
                  return `${payload.errorString}${error.data}`;
                }
                if (!!error && (error?.data !== '') && error?.data?.errors) {
                  return  `${payload.errorString}${JSON.stringify(error.data.errors)}`;
                }
                return payload.errorString + 'Request Not found.';
              }
              case 401:
              case 403: {
                  if (payload.error.message) {
                      return payload.error.message + '.\n' + 'Access Denied, You do not have permission to access.';
                  } else {
                      return payload.errorString + 'Access Denied, You do not have permission to access.';
                  }
              }
              default: {
                return await context.dispatch('parseError', payload);
              }
          }
      },
      async parseServiceError(context: IErrorContext, payload: any): Promise<string> {
        switch (payload.error.response.status) {
                  case 500: {
                    if (payload.error.response?.data.message) {
                      return `${payload.errorString}${payload.error.response.data.message}.`;
                    }
                    else if (payload.error.message) {
                      return payload.errorString + payload.error.message + '.\n' + 'Service error.';
                    } else {
                      return payload.errorString + 'Service error.';
                    }
                  }
                  case 501:
                  case 502:
                  case 503:
                  case 504: {
                      if (payload.error.message) {
                        return payload.errorString + payload.error.message + '.\n' + 'Service unavailable.';
                      } else {
                          return payload.errorString + 'Service unavailable.';
                      }
                  }
                  default: {
                    return await context.dispatch('parseError', payload);
                 }
          }
      },
      parseError(context: IErrorContext, payload: any): string {
          if (payload.error.message) {
              return payload.error.message + '.';
          } else if (payload.error.error_description) {
              return payload.error.error_description + '.';
          } else {
              return payload.errorString;
          }
      }
    } as IErrorStoreActions
};

const emitError = () => {
  // Show internal error modal if handle error is true
  if (ErrorStore.state.handleError) {
    ErrorStore.state.showModal = true;
  }
  // Log message within parent app
  if ((window as any).eftEventBus != null) {
      const emitEvent: IErrorBusMessage = new ErrorBusMessage(APP_TAG, ErrorStore.state.error, ErrorStore.state.config);
      (window as any).eftEventBus.$emit(EVENT_NAME, emitEvent);
  }
};
