export enum HierarchicalTaskTypes {
  Ember = 'Ember',
  TowerView = 'TowerView'
}

export enum TagBasedTaskTypes {
  HierarchyBuilder = 'HierarchyBuilder',
  // TowerViewSimulator = 'TowerViewSimulator',
  AssetService= 'AssetService'
}


export enum TaskFrequency {
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days'
}

export enum TaskTimeoutFrequency {
  Seconds = 'Seconds',
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days'
}
export interface TaskTypes {
  text: string;
  value: HierarchicalTaskTypes | TagBasedTaskTypes;
}

export enum HierarchyApplication {
  None = 'NONE',
  Ember = 'EMBER',
  TowerView = 'TOWERVIEW',
  ZoloScan = 'ZOLOSCAN'
}
