












































import { FileType } from '@/enum/file-type';
import { downloadTextAsFile } from '@/shared/helper-methods';
import availableDataStore from '@/store/availableDataStore/availableDataStore';
import { Component, Vue } from 'vue-property-decorator';
import TextAreaCopy from '@/components/common/TextAreaCopy.vue';
import Loading from '@/components/common/Loading.vue';
import { AvailableDataModalState } from '@/enum/AvailableDataModalState.enum';
import { IAvailableDataResponse } from '@/view-models/i-available-data-view-model';
import StatusTimeLineView from '@/components/available-data/StatusTimeLineView.vue';

@Component({
  name: 'view-available-data',
  components: {
    TextAreaCopy,
    Loading,
    StatusTimeLineView
  }
})
export default class ViewAvailableData extends Vue {
  private lmodalState = false;

  get title(): string {
    return `${availableDataStore.modalTitle}`;
  }

  get correlationKey(): string {
    return availableDataStore.correlationKey;
  }

  get currentItem(): IAvailableDataResponse {
    return availableDataStore.currentItem;
  }

  get type(): AvailableDataModalState {
    return availableDataStore.currentModalState;
  }

  get item(): IAvailableDataResponse {
    return availableDataStore.currentItem;
  }

  get fileType(): FileType {
    return availableDataStore.fileType;
  }

  get modalState(): boolean {
    return availableDataStore.showPayloadModal;
  }
  
  get hasOutput(): boolean {
    return availableDataStore?.currentItem?.completedOn != null;
  }

  get isLoadingState(): boolean {
    return availableDataStore.isLoadingViewData;
  }

  get generatedPayload(): string {
    if (availableDataStore.currentModalState === AvailableDataModalState.Logs) {
      return availableDataStore.generatedLogs;
    }

    return '';
  }

  set modalState(flag: boolean) {
    this.lmodalState = flag;
    availableDataStore.setPayloadModal(flag);
  }
  
  public download() {
    const extension = this.fileType === FileType.Json ? '.json' : '.txt';
    downloadTextAsFile(this.generatedPayload,
                      `${this.title.replace(' ','').toLowerCase()}-${this.correlationKey}-${new Date().toISOString()}${extension}`
                      , this.fileType);
  }
  private async onClose(): Promise<void> {
    await availableDataStore.resetModalState();
    await availableDataStore.setPayloadModal(false);
  }

  public destroyed() {
    availableDataStore.resetModalState();
  }
}
