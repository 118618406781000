export enum AgentStatus {
  AwaitingCertificate = 'AwaitingCertificate',
  ReadyForDataSource = 'ReadyForDataSource',
  ReadyForScheduledTask = 'ReadyForScheduledTask',
  AwaitingApiKey = 'AwaitingApiKey'
}

export enum ActiveStatus {
  AwaitingCredentials = 'AwaitingCredentials',
  Healthy = 'Healthy',
  Degraded = 'Degraded',
  Down = 'Down',
  Disabled = 'Disabled',
  PendingValidation = 'PendingValidation'
}
