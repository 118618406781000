

























import { Component, Vue } from 'vue-property-decorator';
import apiManagement from '@/store/apiManagementStore/apiManagementStore';
import availableData from '@/store/availableDataStore/availableDataStore';

@Component({
  name: 'tabbed-nav',
})
export default class TabbedNav extends Vue {
  get currentRoute() {
    return this.$route.name;
  }
  get enableApiMangement() {
    return apiManagement.apiManagementIsEnabled;
  }
  get enableAvailableData() {
    return availableData.availableDataIsEnabled;
  }
  get hasApiManagementPermission() {
    return apiManagement.hasPermissions;
  }
  get hasAvailableDataPermission() {
    return availableData.hasPermissions;
  }
}
