

















import { Component, Prop } from 'vue-property-decorator';
import store from '@/store';
import { IAgentViewModel } from '@/view-models/i-agent-view-model';
import StatusVisual from '@/components/common/StatusVisual.vue';
import BaseComponent from '@/shared/BaseComponent.vue';
import { LogLevel } from '@/enum/log-level';
import i18n from '@/i18n';
import { cloneDeep } from 'lodash';
import notificationsStore from '@/store/notificationsStore/notificationsStore';

@Component({
  name: 'installer-certificate-view',
  components: {StatusVisual}
})
export default class InstallerCertificateView extends BaseComponent {
  private showDownloadInstallerLoading: boolean = false;
  private showDownloadCertificateLoading: boolean = false;
  private logLevelSelected: LogLevel = LogLevel.Information;
  private isUpdating = false;

  private logLevelOptions = [
    {
      text: `${i18n.t(`global.Info`)}`,
      value: LogLevel.Information
    },
    {
      text: `${i18n.t(`global.Verbose`)}`,
      value: LogLevel.Trace
    },
 
  ];
  @Prop({ required: true })
  private agent!: IAgentViewModel;
  
  public mounted(): void {
    this.logLevelSelected = this.agent.agentLogLevel;
  }

  get isLatestVersion(): boolean {
    if (this.agentVersionIsEnabled) {
      return this.agent.status !== this.setupStatuses.AwaitingCertificate && this.agent.version === this.latestAgentVersion;
    } 

    return true;
  }

  get latestAgentVersion(): string {
    return store.getters['agent/latestAgentVersion'];
  }

  get agentVersionIsEnabled(): boolean {
    return store.getters['agent/agentVersionIsEnabled'];
  }

  public async downloadCertificate(): Promise<void> {
    this.showDownloadCertificateLoading = true;
    try {
      await store.dispatch('agentSelected/getCertificate');
      this.showDownloadCertificateLoading = false;
    } catch {
      this.showDownloadCertificateLoading = false;
    }
  }
  public async downloadInstaller(): Promise<void> {
    this.showDownloadCertificateLoading = true;
    try {
      await store.dispatch('agentSelected/getInstaller');
      this.showDownloadCertificateLoading = false;
    } catch (error) {
        store.dispatch('error/setError', {
          error,
          errorString: 'Failed to get Installer\n',
          handleError: true,
          routeHomeAfterError: false
      });
      this.showDownloadCertificateLoading = false;
    }
  }
  public async setLogLevel(level: LogLevel): Promise<void> {
    this.isUpdating = true;
    const agent = cloneDeep(this.agent);
    agent.agentLogLevel = level;

    try {
      await store.dispatch('agentSelected/updateAgent', agent);
      notificationsStore.setMessage(`${i18n.t(`dataSource.successLogLevelChange`)}`);
      notificationsStore.toastContext.show('success-toast');
    } catch (error) {
      store.dispatch('error/setError', {
          error,
          errorString: 'Failed to set log level for agent\n',
          handleError: true,
          routeHomeAfterError: false
      });
    } finally {
      this.isUpdating = false;
    }
  }
}
