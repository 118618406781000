



























// Libraries
import { Component, Prop, Vue } from 'vue-property-decorator';
// View Models
// Components
import Loading from '@/components/common/Loading.vue';

@Component({
  name: 'text-area-copy',
  components: {
    Loading
  }
})
export default class TextAreaCopy extends Vue {
  // VUE.JS Props
  @Prop({required: false, default: ''})
  public data!: string;
  @Prop({required: false, default: 'Copy'})
  public buttonText!: string;
  @Prop({required: false, default: false})
  public isReadonly!: boolean;
  @Prop({required: true})
  public canCopy: boolean = true;
  @Prop({required: false, default: false})
  public canDownload!: boolean;
  @Prop({required: false, default: 10})
  public rows!: number;
  @Prop({required: false, default: false})
  public noResize!: boolean;

  private textarea!: HTMLTextAreaElement;

  public mounted() {
    this.textarea = this.$refs.payloadArea as HTMLTextAreaElement;
  }

  // copy function move this to another component called the texteditor with copy
  public copy() {
    this.textarea.select();
    document.execCommand('copy');
    setTimeout((): void => {
      this.textarea.blur();
      window.getSelection()?.removeAllRanges();
    }, 100);
  }
}
