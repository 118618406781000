import { HierarchicalTaskTypes, HierarchyApplication, TagBasedTaskTypes } from '@/enum/task-definition-type';
import { ActiveStatus, AgentStatus } from '@/enum/agent-status';
import IStatus from '@/view-models/i-status';
import ITracking from '@/view-models/i-tracking';
import { ITag } from '@/view-models/i-tag-view-model';

export interface IDictionary {
    [key: string]: any;
}

export interface ITaskDefinitionViewModel extends IStatus, ITracking {
    agentKey: string;
    taskDefinitionKey: string;
    dataSourceKey: string;
    name: string;
    type: HierarchicalTaskTypes | TagBasedTaskTypes;
    frequency: string;
    rootNodePath: string;
    assetKey: string;
    assetName: string;
    customerKey: string;
    selectedTags: ITag[];
    keyValues: IDictionary;
    hierarchyApplication: HierarchyApplication;
    retrieveLatest?: boolean;
    timeoutInterval: string;
}
export class TaskDefinitionViewModel implements ITaskDefinitionViewModel {
    public agentKey: string = '';
    public taskDefinitionKey: string = '';
    public dataSourceKey: string = '';
    public name: string = '';
    public status: AgentStatus | ActiveStatus = ActiveStatus.Disabled;
    public desiredStatus: AgentStatus | ActiveStatus = ActiveStatus.Disabled;
    public type: HierarchicalTaskTypes | TagBasedTaskTypes = HierarchicalTaskTypes.Ember;
    public frequency: string = '';
    public timeoutInterval: string = '';
    public rootNodePath: string = '';
    public assetKey: string = '';
    public assetName: string = '';
    public customerKey: string = '';
    public createdByDate: string = '';
    public createdByName: string = '';
    public createdByUserKey: string = '';
    public lastModifiedByDate: string = '';
    public lastModifiedByName: string = '';
    public lastModifiedByUserKey: string = '';
    public selectedTags: ITag[] = [];
    public keyValues: IDictionary = {};
    public hierarchyApplication = HierarchyApplication.None;

    constructor(taskDefinitionViewModel?: ITaskDefinitionViewModel) {
        if (taskDefinitionViewModel != null) {
            Object.assign(this, taskDefinitionViewModel);
        }
    }
}
