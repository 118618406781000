import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import { IUserViewModel } from '@/view-models/user-view-model';

export class UserManagementService extends BaseService {

    constructor(axiosInstance: AxiosInstance, userManagementApiUrl: string) {
    super(userManagementApiUrl, '', axiosInstance, '');
  }

    public async getUser(): Promise<IUserViewModel> {
        return (await this.httpClient.get<IUserViewModel>('users/current'));
    }

}
