import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import { IDataSourceViewModel } from '@/view-models/i-data-source-view-model';

export class DataSourceService extends BaseService {

  constructor(axiosInstance: AxiosInstance, portalApiUrl: string) {
    super(portalApiUrl, '', axiosInstance,'agents');
  }

  public async getDataSource(agentKey: string): Promise<IDataSourceViewModel[]> {
    return (await this.httpClient.get<IDataSourceViewModel[]>(`${agentKey}/data-sources`));
  }
  public async createDataSource(createInfo: IDataSourceViewModel): Promise<IDataSourceViewModel> {
    return (await this.httpClient.post<IDataSourceViewModel>(`${createInfo.agentKey}/data-sources`,
        createInfo));
  }
  public async updateDataSource(updateInfo: IDataSourceViewModel): Promise<IDataSourceViewModel> {
    return (await this.httpClient
      .put<IDataSourceViewModel>(`${updateInfo.agentKey}/data-sources/${updateInfo.dataSourceKey}`,
          updateInfo));
  }
  public async enableDataSource(updateInfo: IDataSourceViewModel): Promise<IDataSourceViewModel> {
    return (await this.httpClient
      .put<IDataSourceViewModel>(`${updateInfo.agentKey}/data-sources/${updateInfo.dataSourceKey}/enable`,
          updateInfo));
  }
  public async disableDataSource(updateInfo: IDataSourceViewModel): Promise<IDataSourceViewModel> {
    return (await this.httpClient
      .put<IDataSourceViewModel>(`${updateInfo.agentKey}/data-sources/${updateInfo.dataSourceKey}/disable`,
          updateInfo));
  }
}
