















// Libraries
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
// View Models
// Components

@Component({
  name: 'search-bar'
})
export default class SearchBar extends Vue {
  @Prop()
  public value?: string;
  @Prop()
  public name?: string;

  get placeholder(): string {
    return (this.name == null
      ? this.$t('search.placeholder')
      : this.$t('search.placeholderFor', { name: this.name })).toString();
  }
  get searchTerm(): string {
    return this.value ?? '';
  }
  set searchTerm(value: string) {
    this.emitSearchTerm(value);
  }

  @Watch('searchTerm')
  public emitSearchTerm(val: string): void {
    this.$emit('input', val);
  }
}
