
import { ActiveStatus, AgentStatus } from '@/enum/agent-status';
import BootstrapVue, { BootstrapVueIcons } from 'bootstrap-vue';
import { Vue, Component } from 'vue-property-decorator';
import { HierarchicalDataSourceTypes, TagBasedDataSourceTypes } from '@/enum/data-source-types';
import { HierarchicalTaskTypes, TagBasedTaskTypes} from '@/enum/task-definition-type';
import format from 'date-fns/format';
import store from '@/store';
import HelperMethods from '@/shared/helper-methods';
import { IDataSourceViewModel } from '@/view-models/i-data-source-view-model';
import assetServiceStore from '@/store/assetServiceStore/assetServiceStore';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.filter('dateFormat', (value: string) => {
  if (value) {
    return format(new Date(value), 'MMMM dd, yyyy');
  }
});

@Component({
  name: 'BaseComponent'
})

export default class BaseComponent extends Vue {
  protected setupStatuses = AgentStatus;
  protected runningStatuses = ActiveStatus;
  protected hierarchicalDataSourceTypes = HierarchicalDataSourceTypes;
  protected tagBasedDataSourceTypes = TagBasedDataSourceTypes;
  protected hierarchicalTaskTypes = HierarchicalTaskTypes;
  protected tagBasedTaskTypes = TagBasedTaskTypes;
  protected bToastAutoHideDelay = 3000;

  protected showRefreshProgress: boolean = false;

  get dataSourceList(): IDataSourceViewModel[] {
    return store.state.dataSource.dataSourceList;
  }

  protected async onLoad(): Promise<void> {
    const isHierarchyLoaded = store.getters['hierarchyBuilder/isHierarchyLoaded'];
    const isAssetsLoaded = assetServiceStore.assetsLoaded;
    const specificTaskDefinition = store.getters['taskDefinition/getSelectedTaskType'];
    const customerKey = store.getters['agent/specificCustomerKey'];

    this.showRefreshProgress = true;
    store.commit('app/updateIsLoading', true);
    store.dispatch('agent/getLatestAgentVersion');
    if (!HelperMethods.isStringEmpty(this.$route.params.id)
      && store.state.agentSelected.selectedAgent?.agentKey !== this.$route.params.id) {
      try {
        await store.dispatch('agentSelected/getSpecificAgent', this.$route.params.id);
      } catch (err) {
        if (this.$route.path !== '/') {
          this.$router.push('/');
        }
        store.dispatch('error/setError', {
          error: err,
          errorString: 'We were unable to find this Agent.\n',
          handleError: true,
          routeHomeAfterError: false
        });
      }
    }
    if (!HelperMethods.isStringEmpty(this.$route.params.taskid)
        && store.state.taskDefinition.specificTaskDefinition?.taskDefinitionKey !== this.$route.params.taskid) {
      await store.dispatch('taskDefinition/getSpecificTaskDefinition', this.$route.params.taskid)
        .catch(() => {
          this.$router.push('/' + this.$route.params.id + '/data-sources');
        });
      this.setDataSource(store.state.taskDefinition.specificTaskDefinition.dataSourceKey);
    }
    if (!isHierarchyLoaded && specificTaskDefinition === TagBasedTaskTypes.HierarchyBuilder) {
      await store.dispatch('hierarchyBuilder/getHierarchyList');
    }
    if (!isAssetsLoaded && specificTaskDefinition === TagBasedTaskTypes.AssetService) {
      await assetServiceStore.getAssetHierarchy(customerKey);
    }
    this.showRefreshProgress = false;
    store.commit('app/updateIsLoading', false);
  }

  protected setDataSource(dsKey: string) {
    const dataSource = this.dataSourceList.find((x) => x.dataSourceKey === dsKey);
    store.commit('dataSource/setSelectedDataSource', dataSource);
  }
}
