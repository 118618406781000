import { AxiosInstance, AxiosRequestConfig, Method } from 'axios';

export default class HttpClient {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly baseUri: string
  ) {
  }

  public async get<T>(uri: string, options?: { query?: {}, headers?: {}, responseType?: string, axiosResponse?: boolean}): Promise<T> {
    const config = options ? { 
      headers: options.headers, 
      params: options.query, 
      responseType: options.responseType == null ? '' : options.responseType
    } as AxiosRequestConfig : undefined;

    if (options?.axiosResponse) {
      return (await this.axios.get(this.uri(uri), config));
    } else {
      return (await this.axios.get<T>(this.uri(uri), config)).data;
    }
  }

  public async post<T>(uri: string, payload?: any, 
    options?: { query?: {}, headers?: {}, responseType?: string, axiosResponse?: boolean}): Promise<T> {
    const config = options ? {
      headers: options.headers,
      params: options.query,
      responseType: options.responseType == null ? '' : options.responseType
    } as AxiosRequestConfig : undefined;

    if (options?.axiosResponse) {
      return (await this.axios.post(this.uri(uri), payload, config));
    } else {
      return (await this.axios.post<T>(this.uri(uri), payload, config)).data;
    }
  }

  public async put<T>(uri: string, payload?: any): Promise<T> {
    return (await this.axios.put<T>(this.uri(uri), payload)).data;
  }

  public async delete<T>(uri: string): Promise<T> {
    return (await this.axios.delete<T>(this.uri(uri))).data;
  }

  public async request<T>(method: Method, uri: string, data?: any) {
    return (await this.axios.request<T>({ data, method, url: uri })).data;
  }

  protected uri(uri?: string) {
    return uri ? `${this.baseUri}/${uri}` : this.baseUri;
  }
}
