


















// Libraries
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FunctionalCalendar } from 'vue-functional-calendar';
import moment, { Moment } from 'moment';
import { BvTimeCtxEvent } from 'bootstrap-vue';

@Component({
  name: 'date-picker',
  components: {
    FunctionalCalendar
  }
})
export default class DatePicker extends Vue {
  @Prop({required: false, default: () => [new Date(), new Date()]})
  public initialDate!: Date;
  
  @Prop({required: false})
  public minDate?: Date;

  @Prop({required: false})
  public maxDate?: Date;

  @Prop({required: false, default: false})
  public showSeconds?: boolean;

  public startDate!: Moment;
  public endDate!: Moment;

  public displayDate: string = '';
  public displayStartTime: string = 'hh:mm';
  public displayEndTime: string = 'hh:mm'; 
  public displayStartDate: string = '';
  public displayEndDate: string = '';
  public btimeStartModel: string = '';
  public btimeEndModel: string = '';
  public startDateError: string = '';
  public endDateError: string = '';
  public startTimeError: string = '';
  public endTimeError: string = '';
  public config = {
    isDark: true,
    isDatePicker: true,
    dateFormat: 'mm/dd/yyyy',
    dayNames: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    isTypeable: true,
    sundayStart: true,
  } as DatePickerRangeConfig;
  public isOpen = false;
  public date: DatePickerModel = {selectedDate: new Date, dateRange: {start: '', end: ''}} as DatePickerModel;
  private parentEl: HTMLElement = {} as HTMLElement;
  public timeFormat = '';
  public get displayDates(): string {
    return this.displayDate;
  }
  public defaultSideOptionIndex!: number;

  private dateFormatString = 'M/D/YYYY';
  
  @Watch('initialDate')
  public reset() {
    this.setupDates();
    this.emit();
  }

  public created() {
    // set disabledDates
    const maxDate = moment(this.maxDate).add(1, 'day').format(this.dateFormatString) ?? undefined;
    if (this.maxDate != null) {
      this.config.disabledDates = ['afterToday', maxDate.toString()];
    }

    this.timeFormat = this.showSeconds ? 'HH:mm:ss' : 'HH:mm';
  }

  public mounted() {
    this.setupDates();
    this.registerClickEvent();
  }
  
  public updateDateFromCalendar() {  
    this.startDate = moment(this.date.selectedDate, this.dateFormatString, true);
    this.displayDate = this.getDisplayDate();
    this.setInputDisplayDate();

    this.emit();
  }

  public setupDates(): void {
    const start = moment(this.initialDate).format(this.dateFormatString);
    this.startDate =  moment(this.initialDate);
    this.displayDate = this.getDisplayDate();
    this.setInputDisplayDate();
    this.setDatesInCalendar(start);
  }

  public setupTimes(): void {
    this.startDate = moment(this.initialDate);

    this.btimeStartModel = `${this.startDate.hours()}:${this.startDate.minutes()}`;
    this.btimeEndModel =  `${this.endDate.hours()}:${this.endDate.minutes()}`;

    if (this.showSeconds) {
      this.btimeStartModel = `${this.btimeStartModel}:${this.startDate.seconds()}`;
      this.btimeEndModel = `${this.btimeEndModel}:${this.endDate.seconds()}`;
    }
  }

  public setDatesInCalendar(start: string) {
    this.date.dateRange.start = start;
  }

  public registerClickEvent(): void {
    this.parentEl = this.$el.closest('#app') as HTMLElement;
    this.parentEl.addEventListener('click', this.clickedOutside);
  }

  public clickedOutside(event: MouseEvent) {
    const isDatePicker = (this.$refs.datetimepickerrange as HTMLElement) ? 
      (this.$refs.datetimepickerrange as HTMLElement).contains(event.target as HTMLElement) : false;

    if (!isDatePicker) {
      this.isOpen = false;
    }
  }
  public destroy() {
    this.parentEl.removeEventListener('click', this.clickedOutside);
  }

  public getDisplayDate(): string {
    const inputDateFormat = 'MMMM DD, YYYY';
    const start = this.startDate.isValid() ? this.startDate.format(inputDateFormat) : '';
    return `${start}`;
  }

  public setInputDisplayDate(): void {
    this.displayStartDate = this.startDate.isValid() ? this.startDate.format('MM/DD/YYYY') : '';
  }

  public setStartTime(context: BvTimeCtxEvent) {
    if (context.value !== '') {
      this.displayStartTime = context.formatted;
      const hour = context.hours ?? 0;
      const minute = context.minutes ?? 0;
      const second = context.seconds ?? 0;

      this.startDate.hours(hour);
      this.startDate.minutes(minute);
      this.startDate.seconds(second);

      this.validateTime();

      this.emit();
    }
  }

  public validateTime() {
    // if the time is not valid.
    this.startTimeError = !this.startDate.isValid() ? 'Please provide a valid time format' : '';
    this.endTimeError = !this.endDate.isValid() ? 'Please provide a valid time format' : '';

    if (this.startDate.isAfter(this.endDate)) {
      this.startTimeError = `Enter time on/before ${this.displayEndTime}`;
    }

    if (this.endDate.isBefore(this.startDate)) {
       this.endTimeError = `Enter time on/after ${this.displayStartTime}`;
    }
  }

  public emit() {
    const event = {
      date: this.startDate
    };

    if (this.startDate.isValid()) {
      this.$emit('onUpdate', event);
      this.isOpen = false;
    }
  }

  private getDates(starDate: Date, endDate: Date): Date[] {
    const arr = new Array(), dt = new Date(starDate);
    while (dt <= endDate) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }
}

export interface DatePickerModel {
  selectedDate: Date,
  dateRange: { start: string, end: string},
}

export interface DatePickerRangeConfig {
  sundayStart: boolean,
  newCurrentDate: Date,
  limits: {} | boolean,
  minSelDays: number | boolean,
  maxSelDays: number | boolean,
  placeholder: string | boolean,
  dateFormat: string,
  isDatePicker: boolean,
  isMultipleDatePicker: boolean,
  isMultipleDateRange: boolean,
  isDateRange: boolean,
  withTimePicker: boolean,
  isMultiple: boolean,
  calendarsCount: number,
  isSeparately: boolean,
  isModal: boolean,
  isAutoCloseable: boolean,
  isTypeable: boolean,
  changeMonthFunction: boolean,
  changeYearFunction: boolean,
  changeYearStep: number,
  changeMonthStep: number,
  markedDates: string[],
  markedDateRange: {start: string, end: string},
  disabledDayName: string[],
  disabledDates: string[],
  dayNames: string[],
  monthNames: string[],
  shortMonthNames: string[],
  showWeekNumbers: boolean,
  transition: boolean,
  hiddenElements: string[],
  titlePosition: string,
  arrowsPosition: string,
  isDark: boolean,
  isLayoutExpandable: boolean,
  alwaysUseDefaultClasses: boolean,
  enabledDates?: string[]
}
