


























import { Component, Prop } from 'vue-property-decorator';
import { StepProgressState } from '@/enum/step-progress-state';
import BaseComponent from '@/shared/BaseComponent.vue';
import { IAccordianDetails } from '@/view-models/i-accordian';
import store from '@/store';

@Component({
  name: 'step-progress'
})
export default class StepProgress extends BaseComponent {
  @Prop({ required: true })
  public stepDetails!: IAccordianDetails;
  private stepIconState = StepProgressState;

  get accordianDetails(): IAccordianDetails[] {
    return store.state.taskDefinition.accordianDetails;
  }
  public getCollapseIcon(value: boolean): string {
    return value ? 'dash-square' : 'plus-square';
  }
  public collapse(): void {
    this.accordianDetails.map((item) => {
      if (item.id === this.stepDetails.id) {
        item.expand = !item.expand;
      } else {
        item.expand = false;
      }
      return item;
    });
  }
}
