












import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import { authGuard } from '@/services/auth';
import i18n from './i18n';
import PlantConnectorList from '@/components/list/PlantConnectorList.vue';
import PlantConnectorCreate from '@/components/create/PlantConnectorCreate.vue';
import PageNotFound from '@/components/common/PageNotFound.vue';
import Loading from '@/components/common/Loading.vue';
import Error from '@/components/common/Error.vue';
import ErrorPage from '@/components/common/ErrorPage.vue';
import IoT from '@/components/common/IoT.vue';
import store from '@/store';
import { EventBus } from '@/shared/event-bus';
import TaskDefinition from '@/components/create/TaskDefinition.vue';
import ApiManagement from '@/components/api-management/ApiManagement.vue';
import VueTimeAgo from 'vue-timeago';
import { appRouteBase } from './shared/from-parent';
import apiManagementStore from './store/apiManagementStore/apiManagementStore';
import { apiManagementGuard } from '@/services/api-management/api-management-guard';
import { availableDataGuard } from '@/services/available-data/available-data-guard';
import availableDataStore from './store/availableDataStore/availableDataStore';
import AvailableData from './components/available-data/AvailableData.vue';
import formatDateLongFilter from '@/shared/filters/formatDateLong';
import formatDateLongSecondsFilter from '@/shared/filters/formatDateLongSeconds';
import Toast from '@/components/common/Toast.vue';
import notificationsStore from './store/notificationsStore/notificationsStore';
import assetServiceStore from './store/assetServiceStore/assetServiceStore';

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(VueTimeAgo, {
  locale: 'en'
});

// Realtime websocket notifications
Vue.component('iot', IoT);

const routes = [
  { path: '/', component: PlantConnectorList, name: 'PlantConnectorList' },
  { path: '/create', component: PlantConnectorCreate, name: 'PlantConnectorCreate' },
  { path: '/api-management',
    component: ApiManagement,
    name: 'ApiManagement',
    beforeEnter: apiManagementGuard
  },
  { path: '/available-data',
    component: AvailableData,
    name: 'AvailableData',
    beforeEnter: availableDataGuard
  },
  { path: '/error-403', component: ErrorPage, name: 'ErrorPage' },
  { path: '/:id', component: PlantConnectorCreate, name: 'PlantConnectorUpdate' },
  { path: '/:id/task/:taskid?', component: TaskDefinition, name: 'TaskDefinition' },
  { path: '/:id/data-sources', component: PlantConnectorCreate, name: 'DataSourceUpdate' },
  { path: '/:id/data-exports', component: PlantConnectorCreate, name: 'DataExportCreate' },
  { path: '/:id/data-exports/new', component: PlantConnectorCreate, name: 'DataExportCreateNew' },
  { path: '/:id/available-data', component: PlantConnectorCreate, name: 'AgentAvailableData' },
  { path: '/:id/agent-logs', component: PlantConnectorCreate, name: 'AgentLogs' },
  { path: '*', component: PageNotFound, name: 'NotFound' }
];

export const plantConnectorRouter = new VueRouter({
  mode: 'history',
  base: appRouteBase(),
  routes
});

plantConnectorRouter.beforeEach(authGuard);

@Component({
  name: 'app',
  router: plantConnectorRouter,
  components: {
    Loading,
    Error,
    Toast
  },
  filters: {
    formatDateLongFilter,
    formatDateLongSecondsFilter,
  },
  i18n
})
export default class App extends Vue {
  @Prop({ required: true })
  public customerKey!: string;

  public deactivated() {
    this.$destroy();
    store.commit('app/updateIsLoading', false);
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$off('onpoint::routeChange', this.handleRouteChange);
    }
  }

  public mounted() {
    // Handle route changes from parent
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$on('onpoint::routeChange', this.handleRouteChange);
    }
    notificationsStore.setToastContext(this.$bvToast);
  }

  public async created() {
    await this.initialize();
  }

  @Watch('customerKey', { immediate: true, deep: true })
  public async customerKeyChange(updatedCustomerKey: string) {
    store.commit('app/updateIsLoading', true);
    if (updatedCustomerKey) {
      await this.fetchUserInfo();
    }
    store.commit('agent/setSpecificCustomerKey', updatedCustomerKey);
    store.commit('hierarchyBuilder/setHierarchyLoaded', false);
    store.commit('agent/setAgentsLoaded', false);
    await this.resetStates();
    // route back to plant connector root since we do not know how to handle child paths
    // that are dependent on customer key.
     if (!routes.some(route => `${route.path}/` === this.$route.path) && this.$route.path !== '/') {
      this.$router.push({path: '/'});
    }
    store.commit('app/updateIsLoading', false);
  }

  public async resetStates() {
    await apiManagementStore.setApiManagementLoaded(false);
    await availableDataStore.setAvailableAgentLoaded(false);
    await availableDataStore.setAvailableDataLoaded(false);
    await availableDataStore.setGenerateJsonLoaded(false);
    await availableDataStore.setTowerViewAssetOptions([]);
    await availableDataStore.setAssetOptions([]);
    await assetServiceStore.setAssetHierarchy([]);
  }

  public handleRouteChange(parentRoute: Route) {
    if (parentRoute.path.startsWith(appRouteBase())) {
      store.commit('app/updateIsLoading', false);
      const currentRelativeRoute = parentRoute.path.replace(appRouteBase(), '/').replace(/\/\//g, '/');
      if (currentRelativeRoute !== this.$route.path) {
        this.$router.push(currentRelativeRoute);
      }
    }
  }

  public clearAdEventBus() {
    EventBus.$off();
  }

  private async initialize(): Promise<void> {
    store.commit('app/updateIsLoading', true);
    if (store.state.agent.user.activeCustomerKey === undefined) {
      this.fetchUserInfo();
      store.commit('app/updateIsLoading', false);
    }
    store.commit('agent/setSpecificCustomerKey',this.customerKey);
    store.commit('hierarchyBuilder/setHierarchyLoaded', false);
    this.clearAdEventBus();
    this.$router.afterEach(() => {
      store.commit('app/updateIsLoading', false);
    });
  }

  private async fetchUserInfo(): Promise<void> {
    await store.dispatch('agent/getUser');
    // This also triggers wiring up the IOT channel
    await store.dispatch('agent/getUserToken')
      .catch((err) => {
        store.dispatch('error/setError', {
          error: err,
          errorString: 'Error Loading User Token\n',
          handleError: true,
          routeHomeAfterError: false
        });
      });
      availableDataStore.setPermission();
      apiManagementStore.setPermission();
  }

  get isLoading() {
    return store.getters['app/isLoading'];
  }

}
