









































import { Component, Prop } from 'vue-property-decorator';
import DataSourceCard from '@/components/create/DataSourceCard.vue';
import { IDataSourceViewModel, DataSourceViewModel } from '@/view-models/i-data-source-view-model';
import store from '@/store';
import BaseComponent from '@/shared/BaseComponent.vue';
import Loading from '@/components/common/Loading.vue';
import { ActiveStatus, AgentStatus } from '@/enum/agent-status';
import { IAgentViewModel } from '@/view-models/i-agent-view-model';
import HelperMethods from '@/shared/helper-methods';
import { ITaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import IconButton from '@/components/dsm/IconButton.vue';

@Component({
  name: 'data-source-tab',
  components: {
    DataSourceCard,
    Loading,
    IconButton
  }
})
export default class DataSourceTab extends BaseComponent {
  @Prop({required: true})
  private agentStatus !: AgentStatus | ActiveStatus;
  private showProgress: boolean = false;

  get dataSourceList(): IDataSourceViewModel[] {
    return store.state.dataSource.dataSourceList;
  }
  get selectedAgent(): IAgentViewModel {
    return store.state.agentSelected.selectedAgent;
  }
  get hasDataSources(): boolean {
    return this.dataSourceList && this.dataSourceList.length === 0;
  }
  get hasCreateDataSource(): boolean {
    return this.dataSourceList.some((a) => HelperMethods.isStringEmpty(a.dataSourceKey));
  }
  get taskDefinitionsList(): ITaskDefinitionViewModel[] {
    return store.state.taskDefinition.taskDefinitionsList;
  }
  get isAnyTaskDefinitionHealthy(): boolean {
    return this.taskDefinitionsList.some((a) => a.status === this.runningStatuses.Healthy);
  }
  public mounted(): void {
    this.loadDataSourceList();
  }
  public async loadDataSourceList() {
    this.showProgress = true;
    try {
      await store.dispatch('dataSource/getDataSourceList');
      await store.dispatch('taskDefinition/getTaskDefinitionsList');
      this.showProgress = false;
    } catch {
      this.showProgress = false;
    }
  }
  public async refresh() {
    await store.dispatch('dataSource/setDataSourcesLoaded', false);
    await store.dispatch ('taskDefinition/setTaskDefinitionsLoaded', false);
    this.loadDataSourceList();
  }
  public createDataSource() {
    const createDataSourceObj = new DataSourceViewModel();
    createDataSourceObj.customerKey = this.selectedAgent.customerKey;
    createDataSourceObj.agentKey = this.selectedAgent.agentKey;
    this.dataSourceList.unshift(createDataSourceObj);
  }
  public createTask(): void {
    this.$router.push('/' + this.selectedAgent.agentKey + '/task');
  }
  public async enableAgent() {
    await store.dispatch('agentSelected/toggleAgentStatus', this.selectedAgent);
  }
}
