






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'tags-pop-up'
})
export default class TagsPopUp extends Vue {
  @Prop({required: true})
  private data: any;
  private parentEl: HTMLElement = {} as HTMLElement;
  public isOpen = false;
  public id!: string;
  private isRegistered = false;

  public updated() {
    if (this.isOpen && !this.isRegistered) {
      this.registerClickEvent();
    }
  }

  public registerClickEvent(): void {
    if (!!this.$el) {
      this.parentEl = this.$el.closest('#app') as HTMLElement;
      this.parentEl.addEventListener('click', this.clickedOutside);
      this.isRegistered = true;
    }
  }

  public destroy() {
    this.parentEl.removeEventListener('click', this.clickedOutside);
  }

  public clickedOutside(event: MouseEvent) {
    const isPopup = (this.$refs.popup as HTMLElement) ? 
      (this.$refs.popup as HTMLElement).contains(event.target as HTMLElement) : false;
    const isPopupIcon = ((event.target as HTMLElement).className as any).baseVal === `bi-exclamation-circle b-icon bi tags-delta tags-delta-${this.id}`;
    const isPageLinks = (event.target as HTMLElement).className === 'page-link';
    if ((!isPopup && this.isOpen) && !isPopupIcon && !isPageLinks) {
      this.close();
    }
  }
  
  public open(id: string) {
    this.isOpen = true;
    this.id = id;
  }

  public close() {
    this.isOpen = false;
    this.resetTagTable();
  }
  
  public resetTagTable() {
    this.$set(this.data.item, 'hovered', false);
    this.$set(this.data.item, 'currentPage', 1);
  }
}
