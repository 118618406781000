import BaseService from '../base-service';
import { AxiosInstance } from 'axios';
import { IApiKeyDeleteModel, IApiKeyResponse } from '@/view-models/i-api-management-view-model';
import { IApiKeyUpdateModel } from '@/view-models/i-api-management-view-model';

export class ApiManagementService extends BaseService {

  constructor(axiosInstance: AxiosInstance, portalApiUrl: string) {
    super(portalApiUrl, '', axiosInstance, 'customers');
  }

  public async get(customerKey: string): Promise<IApiKeyResponse[]> {
    return (await this.httpClient.get<IApiKeyResponse[]>(`${customerKey}/api-management`));
  }

  public async delete(customerKey: string, payload: IApiKeyDeleteModel): Promise<Partial<IApiKeyResponse>> {
    return (await this.httpClient.delete<Partial<IApiKeyResponse>>(`${customerKey}/api-management/${payload.apiKey}`));
  }

  public async update(customerKey: string, payload: IApiKeyUpdateModel): Promise<Partial<IApiKeyResponse>> {
    return (await this.httpClient.put<Partial<IApiKeyResponse>>(`${customerKey}/api-management`, payload));
  }

  public async create(customerKey: string, label: string = ''): Promise<Partial<IApiKeyResponse>> {
    return (await this.httpClient.post<Partial<IApiKeyResponse>>(`${customerKey}/api-management`, {label}));
  }
}
