export enum HierarchicalDataSourceTypes {
    PiAssetFramework = 'PiAssetFramework'
}

export enum TagBasedDataSourceTypes {
    InfoPlus21 = 'InfoPlus21',
    PiServer = 'PiServer',
    File = 'File'
}

export enum SqlDatabaseType {
    MySql = 'MySql'
}
