import { ActiveStatus, AgentStatus } from '@/enum/agent-status';
import { LogLevel } from '@/enum/log-level';
import IStatus from '@/view-models/i-status';
import ITracking from '@/view-models/i-tracking';

export interface IAgentViewModel extends IStatus, ITracking {
  agentKey: string;
  name: string;
  customerKey: string;
  lastHeartbeatDate: string | null;
  agentLogLevel: LogLevel;
  version: string;
}

export class AgentViewModel implements IAgentViewModel {
  public agentKey: string = '';
  public createdByDate: string = '';
  public createdByName: string = '';
  public createdByUserKey: string = '';
  public customerKey: string = '';
  public lastModifiedByDate: string= '';
  public lastModifiedByName: string = '';
  public lastModifiedByUserKey: string = '';
  public name: string = '';
  public status: AgentStatus | ActiveStatus = AgentStatus.AwaitingCertificate;
  public desiredStatus: AgentStatus = AgentStatus.AwaitingCertificate;
  public lastHeartbeatDate: string | null = '';
  public agentLogLevel: LogLevel = LogLevel.Information;
  public version = '';

  constructor(agentViewModel?: IAgentViewModel) {
    if (agentViewModel != null) {
      Object.assign(this, agentViewModel);
    }
  }
}
