

































import { AvailableDataStatus } from '@/enum/available-data-status';
import { IAvailableDataResponse } from '@/view-models/i-available-data-view-model';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'status-time-line-view'
})
export default class StatusTimeLineView extends Vue {
  @Prop({required: true, default: null})
  public currentItem!: IAvailableDataResponse;

  get getColor(): string {
    if (this.currentItem.status === AvailableDataStatus.Complete) {
      return 'color: var(--success)';
    }
    if (this.currentItem.status === AvailableDataStatus.Failed) {
      return 'color: var(--danger)';
    }
    if (this.currentItem.status === AvailableDataStatus.Received || 
        this.currentItem.status === AvailableDataStatus.UpdatingHistorian) {
      return 'color: var(--warning)';
    }
    if (this.currentItem.status === AvailableDataStatus.Started) {
      return 'color: var(--primary)';
    }

    return '';
  }
}
