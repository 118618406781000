












































































import {Component, Prop, Watch} from 'vue-property-decorator';
import { IBTableField } from '@/view-models/shared/b-table';

import BaseComponent from '@/shared/BaseComponent.vue';
import SearchBar from '@/components/common/SearchBar.vue';
import { ITag } from '@/view-models/i-tag-view-model';
import HelperMethods from '@/shared/helper-methods';
import { BTable } from 'bootstrap-vue';
import { Trie } from './Trie';
import IconButton from '@/components/dsm/IconButton.vue';
import Loading from '@/components/common/Loading.vue';

export interface TableItem {
  name: string;
  [name:string]: string;
}

@Component({
  name: 'table-component',
  components: {
    SearchBar,
    IconButton,
    Loading
  }
})
export default class TableComponent extends BaseComponent {
  public currentPage: number = 1;
  public disableSelectAll: boolean = false;
  public selectAll: boolean = false;
  public filteredData:TableItem[] = [];
  public totalRows: number = 1;
  public currentSearchTerm = '';
  
  @Prop ({required: true})
  public tableData!: TableItem[];
  @Prop ({required: true})
  private emptyText!: string;
  @Prop ({required: true})
  private tableFields!: IBTableField[];
  @Prop ({required: false})
  private searchName!: string;
  @Prop ({required: true})
  private perPageItems!: number;
  @Prop ({required: false, default: ''})
  private applicationType!: string;
  @Prop ({required: false, default: ''})
  private eventName!: string;
  @Prop ({required: false})
  private primaryKey?: string;
  @Prop ({required: false, default: 'radio-select'})
  private radioName!: string;
  @Prop({required: false})
  private isLoading!:boolean;

  private filteredTableData: TableItem[] = [];
  public trie: Trie = new Trie();
  public dictionary:Map<string, TableItem> = new Map();

  private filter: string = '';
  private hoveredRow = {};

  get hasData(): boolean {
    return this.tableData && this.tableData.length > 0;
  }

  get hasSearchValue(): boolean {
    return !HelperMethods.isStringEmpty (this.searchName);
  }

  public mounted(): void {
    if (this.hasData) {
      this.filteredData = this.tableData;
      this.totalRows = this.tableData.length;
      this.filteredTableData = [...this.tableData];
      this.hydrateTrie();
      this.hydrateDictionary();
    }
  }

  @Watch('tableData')
  public updateRowSize() {
    this.totalRows = this.tableData.length;
    this.filteredTableData = [];
    this.filteredTableData = [...this.tableData];
    this.hydrateTrie();
    this.hydrateDictionary();
    this.getSearchResults(this.currentSearchTerm);
  }
  
  public hydrateDictionary() {
    this.tableData.forEach((value) => {
      this.dictionary.set(value.name, value);
    });
  }

  public hydrateTrie() {
    if (this.trie.root.children.size === 0) {
      // insert into trie
      this.tableData.forEach((value) => {
        this.trie.insert(value.name);
      });
    }
  }

  public addToTrie(value: string) {
    this.trie.insert(value);
  }

  public removeFromTrie(value: string) {
    this.dictionary.delete(value);
    this.trie.delete(value);
  }

  public addNewTagRow(value: TableItem) {
    this.tableData.unshift(value);
    this.refreshTable();
  }

  public updateTrie(value: string, updatedValue: string) {
    this.trie.update(value, updatedValue);
    const previous = this.dictionary.get(value) ?? null;
    if (previous) {
      this.dictionary.set(updatedValue, {name: updatedValue} as TableItem);
      this.dictionary.delete(value);
    }
  }

  public rowHovered(item: ITag) {
    this.hoveredRow = item;
  }

  public rowUnHovered(): void {
    this.hoveredRow = {};
  }

  public isHovered(item: ITag) {
    return item === this.hoveredRow;
  }

  public sendMessageToParent(data: any): void {
    this.$emit(this.eventName, data);
  }

  public refreshTable() {
    ((this.$refs.tableComponent) as BTable).refresh();
  }
  
  public onPageChange(item: number | null = null): void {
    this.$emit('onPageChange', item);
  }

  public getSearchResults(value: string) {
    if (value === '') {
      this.filteredTableData = [];
      this.filteredTableData = [...this.tableData];
      this.totalRows = this.filteredTableData.length;
      return;
    }
    const results = this.trie.search(value);

    const temp = [] as any;
    results.forEach((value) => {
      const record = this.dictionary.get(value.word);
      temp.push(record);
    });
    this.filteredTableData = [];
    this.filteredTableData = [...temp];
    this.totalRows = this.filteredTableData.length;
  }

  public getAllPageItems(arr: any[], size: number) {
    return arr.reduce ((acc, val, index) => {
      const idx = Math.floor (index / size);
      const page = acc[idx] || (acc[idx] = []);
      page.push (val);
      return acc;
    }, []);
  }
}
