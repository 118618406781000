





















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BTable } from 'bootstrap-vue';
import Loading from '@/components/common/Loading.vue';
import store from '@/store';
import { AvailableDataTaskRequestSource, IAvailableDataResponse, StatusTimeLine } from '@/view-models/i-available-data-view-model';
import availableDataStore from '@/store/availableDataStore/availableDataStore';
import { AvailableDataStatus } from '@/enum/available-data-status';
import moment from 'moment';
import i18n from '@/i18n';
import IconButton from '@/components/dsm/IconButton.vue';
import { ICustomerReadingsInputPayload } from '@/view-models/i-readings-view-model';
import { ICustomerReadingsOutputPayload } from '@/view-models/i-results-view-model';
import { AvailableDataModalState } from '@/enum/AvailableDataModalState.enum';
import { DatePickerRangeEvent } from '../dsm/DatePickerRange.vue';
import { startCase } from 'lodash';
import { ITaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import { IAvailableDataLogsPayload } from '@/view-models/i-available-data-logs';
import { FileType } from '@/enum/file-type';
import TagsPopUp from '@/components/common/TagsPopUp.vue';
import { BDropdown } from 'bootstrap-vue';
import notificationsStore from '@/store/notificationsStore/notificationsStore';
import HelperMethods from '@/shared/helper-methods';

@Component({
  name: 'available-data-table',
  components: {
    Loading,
    IconButton,
    TagsPopUp
  }
})
export default class AvailableDataTable extends Vue {
  @Prop({required: false})
  public fieldItems!: [];
  @Prop({required: false, default: ''})
  public filter!: string;
  @Prop({required: false, default: ''})
  public filterDate!: DatePickerRangeEvent;
  @Prop({required: false, default: null})
  public agentKey!: string;

  public totalRows: number = 0;

  public startCase = startCase;

  private taskRequestSource = AvailableDataTaskRequestSource;

  get taskDefinitionList(): ITaskDefinitionViewModel[] {
    return store.getters['taskDefinition/getTaskDefinitionsList'];
  }

  get specificCustomerKey() {
    return store.getters['agent/specificCustomerKey'];
  }
  get availableData(): IAvailableDataResponse[] {
    if (this.isAgentData) {
      return availableDataStore.availableDataAgentList;
    }
    return availableDataStore.availableDataList;
  }

  get hasData(): boolean {
    if (this.isAgentData) {
      return availableDataStore.availableDataAgentList && availableDataStore.availableDataAgentList.length > 0;
    }
    return  availableDataStore.availableDataList && availableDataStore.availableDataList.length > 0;
  }

  public updatePagination(filteredItems: IAvailableDataResponse[]){
    this.totalRows = filteredItems.length;
  }

  public show(id: string) :void {
    (this.$refs[`dropdown-${id}`] as BDropdown).show();
  }

  get isBusy() {
    return  availableDataStore.isBusy;
  }
  public AvailableDataStatus = AvailableDataStatus;
  public fields = [
    { key: 'assetName', label: `${i18n.t(`availableData.assetName`)}`, tdClass:'col-width align-middle'},
    { key: 'tagCount', label: `${i18n.t(`availableData.tagCount`)}`, tdClass:'col-width align-middle'},
    { key: 'status', label: `${i18n.t(`global.fields.status`)}`, tdClass:'col-width align-middle'},
    { key: 'duration', label: `${i18n.t(`availableData.duration`)}`, tdClass:'col-width align-middle' },
    { key: 'createdByDate', label: `${i18n.t(`global.fields.created`)}`, tdClass:'align-middle created-by-date' },
    { key: 'actions', label: `${i18n.t(`availableData.viewData`)}`, tdClass: 'actions-width', thClass: 'actions-section' },
  ];
  public tagFields = [
    { key: 'attributeName', label: `Attribute Name`, tdClass:'col-width align-middle'},
    { key: 'tagName', label: 'Tag Name', tdClass:'col-width align-middle'}
  ]
  public ref: BTable = {} as BTable;
  public perPageItems: number = 20;
  public currentPage: number = 1;

  public get isAgentData(): boolean {
    return !HelperMethods.isStringEmpty(this.agentKey);
  }
  public async mounted(): Promise<void> {
    this.ref = this.$refs.availableDataTableRef as BTable;

    if (!!this.fieldItems) {
      this.fields = [];
      this.fields = [...this.fieldItems];
    }
    // Handles agents.
    if (this.isAgentData && !availableDataStore.availableAgentLoaded) {
      await availableDataStore.getAvailableDataByAgentKey({customerKey:this.specificCustomerKey,
                                                           startDate: this.filterDate.startDate,
                                                           endDate:this.filterDate.endDate,
                                                           agentKey: this.agentKey});
      await availableDataStore.setAvailableAgentLoaded(true);
    } else if (!availableDataStore.availableDataLoaded) {
      // Handles non agents, main available data table.
      await availableDataStore.getAvailableDataByAgentKey({customerKey:this.specificCustomerKey,
                                                           startDate: this.filterDate.startDate,
                                                           endDate:this.filterDate.endDate,
                                                           agentKey: ''});

      await availableDataStore.setAvailableDataLoaded(true);
    }
    this.setTotalRows();
    this.ref.refresh();
  }

  public async fetchTasks(): Promise<void> {
    await store.dispatch ('taskDefinition/setTaskDefinitionsLoaded', false);
    await store.dispatch('taskDefinition/getTaskDefinitionsList');
  }
  public async refreshTable(): Promise<void> {
    await availableDataStore.getAvailableDataByAgentKey({customerKey:this.specificCustomerKey,
                                                          startDate: this.filterDate.startDate,
                                                          endDate:this.filterDate.endDate,
                                                          agentKey: this.isAgentData ? this.agentKey : ''});
    this.setTotalRows();
    this.ref.refresh();
    this.$emit('refreshed');
  }

  @Watch('specificCustomerKey')
  public async specificCustomerKeyChange() {
    await this.refreshTable();
  }

  public isInReceived(status: StatusTimeLine) {
    return !status.Complete && !status.Failed;
  }

  public triggerRun(data: IAvailableDataResponse) {
    const payload = {
      customerKey: data.customerKey,
      correlationKey: data.correlationKey
    } as IAvailableDataLogsPayload;
    availableDataStore.rerunByCorrelationKey(payload).then(() => {
      notificationsStore.setMessage(`Successfully reran correlation key: ${payload.correlationKey}`);
      notificationsStore.toastContext.show('success-toast');
    }).catch((error) => {
      store.dispatch('error/setError', {
          error: error,
          errorString: `Error rerunning correlation key ${payload.correlationKey}\n`,
          handleError: true,
          routeHomeAfterError: false
        });
    }).finally(() => {
      setTimeout((): void => {
        this.refreshTable();
      }, 100);
    });
  }

  public setTotalRows(): void {
    if (this.isAgentData) {
      this.totalRows = availableDataStore.availableDataAgentList.length ?? 0;
    } else {
      this.totalRows = availableDataStore.availableDataList.length ?? 0;
    }
  }

  public getElapsedTime(data: IAvailableDataResponse): string {
    if (!data.completedOn) {
      return '-';
    } else {
      const receivedOnMoment = moment(data.receivedOn).locale(i18n.locale);
      const completedOnMoment = moment(data.completedOn).locale(i18n.locale);

      const diff = completedOnMoment.diff(receivedOnMoment);
      return moment.duration(diff).humanize();
    }

  }

  public getRef(item: string) {
    return this.$refs[item];
  }

  public getCurrentTagPage(data:any) {
    if (data.item.currentPage) {
      return data.item.currentPage;
    } else {
      this.$set(data.item, 'currentPage', 1);
      return 1;
    }
  }

  public getTaskDefinitionName(taskDefinitionKey: string): string {
    if (this.taskDefinitionList.length === 0) {
      return 'N/A';
    }

    return this.taskDefinitionList.find((value) => value.taskDefinitionKey === taskDefinitionKey)?.name ?? 'N/A';
  }

  public nullCoalesceTagCount(tagCount: number | null) {
    return tagCount ?? `?`;
  }

  public availableDataFilter(row: IAvailableDataResponse, filter: string) {
    const hasFilter =
      row.assetName?.toLowerCase().includes(filter.toLowerCase()) ||
      row.correlationKey === filter ||
      row.customerKey === filter ||
      row.dataSourceKey === filter ||
      row.status?.toLowerCase().includes(filter.toLowerCase()) ||
      row.tagCountIn?.toString() === filter ||
      row.tagCountOut?.toString() === filter ||
      row.taskDefinitionKey === filter ||
      row.type?.toLowerCase().includes(filter.toLowerCase()) ||
      this.getTaskDefinitionName(row.taskDefinitionKey)?.toLowerCase().includes(filter.toLowerCase());
    if (hasFilter) {
      return true;
    }
  }

  public async compare(data: IAvailableDataResponse): Promise<void> {
    await availableDataStore.setModalTitle(``).toString();
    await availableDataStore.setCompareModal(true);
    await availableDataStore.setCurrentModalState(AvailableDataModalState.Compare);
    await availableDataStore.setCurrentItem(data);
    await this.generateInputs(data);

    if (data.status === AvailableDataStatus.Complete ||
        data.status === AvailableDataStatus.Failed   ||
        data.status === AvailableDataStatus.UpdatingHistorian) {
      await this.generateOutputs(data);
    } else {
      await availableDataStore.setGeneratedOutputData('');
    }
  }

  public async getLogs(data: IAvailableDataResponse): Promise<void> {
    await availableDataStore.setModalTitle(i18n.t(`availableData.logs`).toString());
    await availableDataStore.setCurrentItem(data);
    await availableDataStore.setGeneratedLogs('');
    await availableDataStore.setPayloadModal(true);
    await availableDataStore.setFileType(FileType.Text);
    await availableDataStore.setCorrelationKey(data.correlationKey);
    await availableDataStore.setCurrentModalState(AvailableDataModalState.Logs);

    const payload = {
      customerKey: this.specificCustomerKey,
      correlationKey: data.correlationKey
    } as IAvailableDataLogsPayload;

    await availableDataStore.getLogs(payload);
  }

  private async generateOutputs(data: IAvailableDataResponse): Promise<void> {
    const payload = {
      customerKey: this.specificCustomerKey,
      correlationKey: data.correlationKey
    } as ICustomerReadingsOutputPayload;

    if (data.correlationKey !== availableDataStore.results.correlationKey) {
      await availableDataStore.setGeneratedOutputData('');
      await availableDataStore.setCorrelationKey(data.correlationKey);
      await availableDataStore.setFileType(FileType.Json);
      await availableDataStore.setIsLoadingViewData(true);
      await availableDataStore.getOutputs(payload);
    }
  }

  private async generateInputs(data: IAvailableDataResponse): Promise<void> {
    const payload = {
      customerKey: this.specificCustomerKey,
      correlationKey: data.correlationKey
    } as ICustomerReadingsInputPayload;

    if (data.correlationKey !== availableDataStore.readings.correlationKey) {
      await availableDataStore.setGeneratedInputData('');
      await availableDataStore.setCorrelationKey(data.correlationKey);
      await availableDataStore.setFileType(FileType.Json);
      await availableDataStore.setIsLoadingViewData(true);
      await availableDataStore.getInputs(payload);
    }
  }

  public getStatusColors(status: AvailableDataStatus) {
    if (status === AvailableDataStatus.Received || status === AvailableDataStatus.UpdatingHistorian) {
      return 'color: var(--warning-low)';
    }
    else if (status === AvailableDataStatus.Complete ) {
      return 'color: var(--success)';
    }
    else if (status === AvailableDataStatus.Started) {
      return 'color: var(--primary)';
    }
    else {
      return 'color: var(--danger)';
    }
  }
}
