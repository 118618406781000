// provides a set of constant values that can be used instead
// of magic strings when refering to resources in the en.json file
// As other languages are implemented, they should provide values at each of these nodes.
export const translatableResources = {
    // todo: Add more paths as appropraite
    agent: {
        statusAlert: {
            emptyText: 'agent.statusAlert.emptyText'
        }
    },
    hierarchyDetails: {
        selectHierarchy: 'hierarchyDetails.selectAnAsset'
    },
    taskDefinition: {
        asset: 'taskDefinition.asset',
        assetRootNode: 'taskDefinition.assetRootNode',
        availableTagsEmpty: 'taskDefinition.availableTagsEmpty',
        maxLimitReached: 'taskDefinition.maxLimitReached',
        selectTags: 'taskDefinition.selectTags',
        selectedTagsEmpty: 'taskDefinition.selectedTagsEmpty',
        specifyKeyValuesOptional: 'taskDefinition.specifyKeyValuesOptional',
        tagInfoAfter: 'taskDefinition.tagInfoAfter',
        tagInfoBefore: 'taskDefinition.tagInfoBefore',
        taskDetails: 'taskDefinition.taskDetails',
        assetServiceDetails: 'taskDefinition.assetServiceDetails'
    }
};
