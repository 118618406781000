export interface IAssetServiceState {
  assets: IAssetServiceHierarchyModel[];
  setSelectedAsset(selectedAsset: string): Promise<{ selectedAsset: string | null}> 
  setAssetHierarchy(assets: IAssetServiceHierarchyModel[]): Promise<{ assets: IAssetServiceHierarchyModel[]}>;
  setAssetsLoaded(assetsLoaded: boolean): Promise<{ assetsLoaded: boolean}>
  getAssetHierarchy(customerKey: string): Promise<void>;
}
  
import { AssetService } from '@/services/asset-service';
import ConfigFactory from '@/services/config';
import store from '@/store';
import { IAssetServiceHierarchyModel } from '@/view-models/i-asset-service-hierarchy-model';
import { VuexModule, Module, getModule, MutationAction, Action} from 'vuex-module-decorators';
import sharedAxiosInstance from '@/services/api-service';
import { IAssetServiceAgentTags } from '@/view-models/i-asset-service-tag';

@Module({dynamic: true, name: 'assetServiceStore', store})
export class AssetServiceStore extends VuexModule implements IAssetServiceState {
  public assets: IAssetServiceHierarchyModel[] = [];
  public assetsLoaded: boolean = false;
  public mappedAssetTags: IAssetServiceAgentTags = {} as IAssetServiceAgentTags;
  public selectedAsset: string | null = null;
  public assetServiceIsEnabled: boolean = false;

  @MutationAction({ mutate: ['assets'], rawError: true })
  public async setAssetHierarchy(assets: IAssetServiceHierarchyModel[]): Promise<{ assets: IAssetServiceHierarchyModel[]}> {
    return { assets };
  }
  @MutationAction({ mutate: ['assetsLoaded'], rawError: true })
  public async setAssetsLoaded(assetsLoaded: boolean): Promise<{ assetsLoaded: boolean}> {
    return { assetsLoaded };
  }
  @MutationAction({ mutate: ['mappedAssetTags'], rawError: true})
  public async setAssetTags(mappedAssetTags: IAssetServiceAgentTags): Promise<{ mappedAssetTags: IAssetServiceAgentTags}> {
    return { mappedAssetTags };
  }
  @MutationAction({ mutate: ['selectedAsset'], rawError: true })
  public async setSelectedAsset(selectedAsset: string | null): Promise<{ selectedAsset: string | null}> {
    return { selectedAsset };
  }
  @MutationAction({ mutate: ['assetServiceIsEnabled'], rawError: true })
  public async setAssetServiceFlag(assetServiceIsEnabled: boolean): Promise<{ assetServiceIsEnabled: boolean}> {
    return { assetServiceIsEnabled };
  }

  @Action({rawError: true})
  public async getAssetTags(payload: { customerKey: string, assetKey: string }) {
    const assetService = await initAssetService();
    try {
      const tags = await assetService.getTags(payload.customerKey, payload.assetKey);
      this.setAssetTags(tags);
    } catch (error) {
      store.dispatch('error/setError', {
        error,
        errorString: 'Error while trying to get Asset Service Tags\n',
        handleError: true,
        routeHomeAfterError: false
      });
    }
  }

  @Action({rawError: true})
  public async getAssetHierarchy(customerKey: string): Promise<void> {
    if (this.assetServiceIsEnabled) {
      const assetService = await initAssetService();
      this.setAssetsLoaded(false);
      try {
        const assets = await assetService.getPublishedAssets(customerKey);
        this.setAssetHierarchy(assets);
        this.setAssetsLoaded(true);
      } catch (error) {
        store.dispatch('error/setError', {
          error,
          errorString: 'Error while trying to get assets from Asset Services\n',
          handleError: true,
          routeHomeAfterError: false
        });
      } finally {
        this.setAssetsLoaded(true);
      }
    }
  }
}

export default getModule(AssetServiceStore, store);

export const initAssetService = async () => {
    const conf = await ConfigFactory.GetConfig();
    return new AssetService(sharedAxiosInstance.sharedAvailableDataAxiosInstance,
      process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL ?
        process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL :
        conf.get('pcApiUrl'));
  };