import { TagBasedTaskTypes } from "@/enum/task-definition-type";

export interface IAvailableDataLogs {
  customerKey: string;
  correlationKey: string;
  taskDefinitionKey: string;
  logs: string;
}

export interface IAvailableDataLogsPayload {
  customerKey: string;
  correlationKey: string;
}

export interface IAgentLogsPayload {
  customerKey: string;
  agentKey: string;
  date: string;
}

export interface IAgentLogsDownloadPayload {
  customerKey: string;
  agentKey: string;
  fileName: string;
}

export interface IAgentLogsResults {
  type: AgentLogType;
  expires: string;
  logs: string;
  remarks: string;
  name: string;
  createdOn: string;
  updatedOn: string;
  customerKey: string;
  agentKey: string;
  taskDefinitionKey: string;
  correlationKey: string;
  displayName: string;
}

export interface IAvailableDataUploadPayload {
  customerKey: string;
  type: TagBasedTaskTypes;
  assetKey: string;
  file: File;
}

export enum AgentLogType {
  UNKNOWN = 'UNKNOWN',
  AGENT = 'AGENT',
  TASK = 'TASK',
  DATAUPDATE = 'DATAUPDATE',
  ALL = 'ALL'
}