export interface IAgentLogState {
  
}

import ConfigFactory from '@/services/config';
import store from '@/store';
import { VuexModule, Module, getModule, Action} from 'vuex-module-decorators';
import sharedAxiosInstance from '@/services/api-service';
import { IAgentLogsDownloadPayload, IAgentLogsPayload, IAgentLogsResults } from '@/view-models/i-available-data-logs';
import { AgentLogService } from '@/services/agent-logs/agent-log-service';

@Module({dynamic: true, name: 'agentLogStore', store})
export class AgentLogStore extends VuexModule implements IAgentLogState {
  @Action({rawError: true})
  public async requestAgentLogs(logPayload: IAgentLogsPayload): Promise<any> {
    const agentService = initAgentLogService();
    return (await agentService)
    .requestAvailableAgentLogs(logPayload.customerKey, logPayload.agentKey, logPayload.date).catch((error) => {
        store.dispatch('error/setError', {
        error,
        errorString: 'Error while trying to Request Agent Logs\n',
        handleError: true,
        routeHomeAfterError: false
        });
    });
  }

  @Action({rawError: true})
  public async refreshAgentLogs(logPayload: IAgentLogsPayload): Promise<IAgentLogsResults[]> {
    const agentService = initAgentLogService();
    return (await agentService)
    .getAvailableAgentLogs(logPayload.customerKey, logPayload.agentKey, logPayload.date).catch((error) => {
        store.dispatch('error/setError', {
        error,
        errorString: 'Error while trying to Request Agent Logs\n',
        handleError: true,
        routeHomeAfterError: false
      });
    });
  }

  @Action({rawError: true})
  public async requestAgentLog(logPayload: IAgentLogsResults) {
    const agentService = initAgentLogService();
    return (await agentService)
      .getAgentLog(logPayload.customerKey, logPayload.agentKey, logPayload.name).catch((error) => {
          store.dispatch('error/setError', {
          error,
          errorString: 'Error while trying to Request Agent Log\n',
          handleError: true,
          routeHomeAfterError: false
        });
      });
  }

  @Action({rawError: true})
  public async downloadAgentLogs(downloadPayload: IAgentLogsDownloadPayload): Promise<any> {
    const agentService = initAgentLogService();
    return (await agentService)
    .downloadAvailableAgentLogs(downloadPayload.customerKey, downloadPayload.agentKey, downloadPayload.fileName).catch((error) => {
        store.dispatch('error/setError', {
        error,
        errorString: 'Error while trying to Download Logs\n',
        handleError: true,
        routeHomeAfterError: false
        });
    });
  }

  @Action({rawError: true})
  public async downloadAll(downloadPayload: IAgentLogsPayload): Promise<any> {
    const agentService = initAgentLogService();
    return (await agentService)
    .downloadAllAvailableAgentLogs(downloadPayload.customerKey, downloadPayload.agentKey, downloadPayload.date).catch((error) => {
        store.dispatch('error/setError', {
        error,
        errorString: 'Error while trying to Download Logs\n',
        handleError: true,
        routeHomeAfterError: false
        });
    });
  }
}

export default getModule(AgentLogStore, store);

export const initAgentLogService = async () => {
const conf = await ConfigFactory.GetConfig();
return new AgentLogService(sharedAxiosInstance.sharedAgentLogsAxiosInstance,
      process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL ?
      process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL :
      conf.get('pcApiUrl'));
};
