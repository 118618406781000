export interface IApiManagementState {
  apiManagementLoaded: boolean;
  apiKeyList: IApiKeyResponse[];
  apiManagementIsEnabled: boolean;
  selectedHierarchy: IHierarchy;
  showApiModal: boolean;
  showApiDeleteModal: boolean;
  showPayloadModal: boolean;
  tags: string[];
  keyValues: IDictionary[];
  selectedApi: IApiKeyModel;
  isBusy: boolean;
  hasPermissions: boolean;
  setApiManagementLoaded(flag: boolean): Promise<{apiManagementLoaded: boolean}>;
  setPermission():  Promise<{hasPermissions: boolean}>;
  setApiManagementFlag(flag: boolean): Promise<{apiManagementIsEnabled: boolean}>;
  setExamplePayloadModal(flag: boolean): Promise<{showPayloadModal: boolean}>;
  setShowApiModal(flag: boolean): Promise<{showApiModal: boolean}>;
  setShowApiDeleteModal(flag: boolean): Promise<{showApiDeleteModal: boolean}>;
  setIsBusy(flag: boolean): Promise<{isBusy: boolean}>;
  setSelectedHierarchy(selectedHierarchy: IHierarchy): Promise<{selectedHierarchy: IHierarchy}>;
  setTags(tags: string[]): Promise<{tags: string[]}>;
  setKeyValues(keyValues: IDictionary[]): Promise<{keyValues: IDictionary[]}>;
  getExamplePayload(type: string): Promise<string>;
  setTagsForApiManagement(customerKey: string): Promise<void>;
  setSelectedApi(selectedApi: IApiKeyModel): Promise<{selectedApi: IApiKeyModel}>;
  deleteApiKey(deletePayload: IApiKeyDeleteModel): Promise<void>;
  getApiKeys(customerKey: string): Promise<void>;
  createNewApiKey(customerKey: string, apiKey: string): Promise<Partial<IApiKeyResponse>>;
  updateApiKey(updatePayload: IApiKeyUpdateModel): Promise<void>;
  resetModalState(): Promise<void>;
}

import { TagBasedTaskTypes } from '@/enum/task-definition-type';
import store from '@/store';
import { IApiKeyDeleteModel, IApiKeyModel, IApiKeyResponse, IApiKeyTableData, IApiKeyUpdateModel } from '@/view-models/i-api-management-view-model';
import { IHierarchy } from '@/view-models/i-hierarchy-model';
import { IHierarchyTags } from '@/view-models/i-hierarchy-tags';
import { IDictionary } from '@/view-models/i-task-definition-view-model';
import { VuexModule, Module, getModule, MutationAction , Action} from 'vuex-module-decorators';
import { initTagsService } from '../hierarchyBuilderStore/hierarchy-builder';
import sharedAxiosInstance from '@/services/api-service';
import ConfigFactory from '@/services/config';
import { ApiManagementService } from '@/services/api-management/api-management-service';
import notificationsStore from '@/store/notificationsStore/notificationsStore';
import i18n from '@/i18n';
import { IUserViewModel } from '@/view-models/user-view-model';
import { PermissionRoles } from '@/enum/permissions-roles';

@Module({dynamic: true, name: 'apiManagementStore', store})
export class ApiManagmentStore extends VuexModule implements IApiManagementState {
  public apiManagementLoaded: boolean = false;
  public isBusy: boolean = false;
  public apiKeyList: IApiKeyResponse[] = [];
  public apiManagementIsEnabled: boolean = true;
  public showApiModal: boolean = false;
  public showApiDeleteModal: boolean = false;
  public showPayloadModal: boolean = false;
  public selectedHierarchy: IHierarchy = {} as IHierarchy;
  public tags: string[] = [];
  public keyValues: IDictionary[] = [];
  public selectedApi: IApiKeyModel = {} as IApiKeyModel;
  public apiDataTable: IApiKeyTableData[] = [];
  public hasPermissions: boolean = false;

  @MutationAction({ mutate: ['apiManagementLoaded'], rawError: true })
  public async setApiManagementLoaded(flag: boolean): Promise<{apiManagementLoaded: boolean}> {
    return { apiManagementLoaded: flag };
  }
  @MutationAction({ mutate: ['apiManagementIsEnabled'], rawError: true })
  public async setApiManagementFlag(flag: boolean): Promise<{apiManagementIsEnabled: boolean}> {
    return { apiManagementIsEnabled: flag };
  }
  @MutationAction({ mutate: ['showApiModal'], rawError: true })
  public async setShowApiModal(flag: boolean): Promise<{showApiModal: boolean}> {
    return { showApiModal: flag };
  }
  @MutationAction({ mutate: ['showApiDeleteModal'], rawError: true })
  public async setShowApiDeleteModal(flag: boolean): Promise<{showApiDeleteModal: boolean}> {
    return { showApiDeleteModal: flag };
  }
  @MutationAction({ mutate: ['showPayloadModal'], rawError: true })
  public async setExamplePayloadModal(flag: boolean): Promise<{showPayloadModal: boolean}> {
    return { showPayloadModal: flag };
  }
  @MutationAction({ mutate: ['selectedHierarchy'], rawError: true })
  public async setSelectedHierarchy(selectedHierarchy: IHierarchy): Promise<{selectedHierarchy: IHierarchy}> {
    return { selectedHierarchy };
  }
  @MutationAction({ mutate: ['keyValues'], rawError: true })
  public async setKeyValues(keyValues: IDictionary[]): Promise<{keyValues: IDictionary[]}> {
    return { keyValues };
  }
  @MutationAction({ mutate: ['tags'], rawError: true })
  public async setTags(tags: string[]): Promise<{tags: string[]}> {
    return { tags };
  }
  @MutationAction({ mutate: ['selectedApi'], rawError: true })
  public async setSelectedApi(selectedApi: IApiKeyModel): Promise<{selectedApi: IApiKeyModel}> {
    return { selectedApi };
  }
  @MutationAction({ mutate: ['apiKeyList'], rawError: true })
  public async setApiKeyList(apiKeyList: IApiKeyResponse[]): Promise<{apiKeyList: IApiKeyResponse[]}> {
    return { apiKeyList };
  }
  @MutationAction({ mutate: ['isBusy'], rawError: true })
  public async setIsBusy(isBusy: boolean): Promise<{isBusy: boolean}> {
    return { isBusy };
  }
  @Action({ rawError: true })
  public async getExamplePayload(type: string): Promise<string> {
    const examplePayload = {
      dataReadDateTime:  '<place your request date time here>',
      type,
      data: [
        {
          name: 'string',
          value:  `<place your value here>`,
          readingDateTime: new Date().toISOString()
        }
      ],
      keyValues: { }
    } as any;

    examplePayload.data = this.tags.map((tag) => {
      return {
        name: tag,
        value: `<place your ${tag} reading value here>`,
        readingDateTime: `<place your ${tag} reading date time here>`
      };
    });

    // parse out hierarchykey and hierarkey name and get the rest of the key values.
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      HierarchyKey, HierarchyName, hierarchyKey, hierarchyName,
      ...keyValues} = this.keyValues as any;
    console.log(this.keyValues);
    examplePayload.keyValues = keyValues;
    
    return await JSON.stringify(examplePayload, undefined, 4);
  }

  @Action({ rawError: true })
  public async setTagsForApiManagement(customerKey: string): Promise<void> {
    const tagsService = await initTagsService('customers');
    await tagsService.getTagInputsByHierarchyId(customerKey, this.selectedHierarchy)
      .then((response: IHierarchyTags) => {
          this.setTags(response.tagNames);
          this.setKeyValues(response.keyValues);
          return Promise.resolve();
      }).catch((error) => {
          store.dispatch('error/setError', {
              error,
              errorString: 'Error while getting tags for selected hierarchy\n',
              handleError: true,
              routeHomeAfterError: false
          });
      });
  }

  @Action({rawError: true})
  public async getApiKeys(customerKey: string): Promise<void> {
    this.setIsBusy(true);
    const apiManagementService = initApiManagementService();
    (await apiManagementService).get(customerKey).then((result) => {
      result.forEach((value) => value.previousLabel = value.label);
      this.setApiKeyList(result);
    })
    .catch((error) => {
      store.dispatch('error/setError', {
        error,
        errorString: 'Error while trying to Get API Keys\n',
        handleError: true,
        routeHomeAfterError: false
      });
    }).finally(() => {
      this.setIsBusy(false);
    });
  }

  @Action({rawError: true})
  public async deleteApiKey(deletePayload: IApiKeyDeleteModel): Promise<void> {
    this.setIsBusy(true);
    const apiManagementService = initApiManagementService();
    (await apiManagementService).delete(deletePayload.customerKey, deletePayload).then(() => {
      notificationsStore.setMessage(`${i18n.t(`apiManagement.successDeletingKey`)}`);
      notificationsStore.toastContext.show('success-toast');
      this.getApiKeys(deletePayload.customerKey);
    })
    .catch((error) => {
      store.dispatch('error/setError', {
        error,
        errorString: 'Error while trying to Delete API Key\n',
        handleError: true,
        routeHomeAfterError: false
      });
    }).finally(() => {
      this.setIsBusy(false);
    });
  }

  @Action({rawError: true})
  public async createNewApiKey(customerKey: string, label: string = ''): Promise<Partial<IApiKeyResponse>> {
    const apiManagementService = initApiManagementService();
    return Promise.resolve((await apiManagementService).create(customerKey, label));
  }

  @Action({rawError: true})
  public async updateApiKey(updatePayload: IApiKeyUpdateModel): Promise<void> {
    const apiManagementService = initApiManagementService();
    (await apiManagementService).update(updatePayload.customerKey, updatePayload).then(() => {
      notificationsStore.setMessage(`${i18n.t(`apiManagement.successUpdatingKey`)}`);
      notificationsStore.toastContext.show('success-toast');
    })
    .catch((error) => {
      store.dispatch('error/setError', {
        error,
        errorString: 'Error while trying to Update API Key\n',
        handleError: true,
        routeHomeAfterError: false
    });
    }).finally(() => {
      this.getApiKeys(updatePayload.customerKey);
      this.setIsBusy(false);
    });
  }

  @MutationAction({ mutate: ['hasPermissions'], rawError: true})
  public async setPermission(): Promise<{hasPermissions: boolean}> {
    const user = store.getters['agent/user'] as IUserViewModel;
    return {hasPermissions : user?.permissions?.includes(PermissionRoles.ManagePlantConnectorApi) ?? false};
  }

  @Action({rawError: true})
  public async resetModalState(): Promise<void> {
    this.setSelectedHierarchy({} as IHierarchy);
  }
}

export default getModule(ApiManagmentStore, store);

export const initApiManagementService = async () => {
  const conf = await ConfigFactory.GetConfig();
  return new ApiManagementService(sharedAxiosInstance.sharedAgentAxiosInstance,
      process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL ?
        process.env.VUE_APP_PLANT_CONNECTOR_API_BASE_URL :
        conf.get('pcApiUrl'));
};
