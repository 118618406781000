



































// Libraries
// Components
import { Component, Prop } from 'vue-property-decorator';
import store from '@/store';
import { IBSelect } from '@/view-models/shared/b-select';
import BaseComponent from '@/shared/BaseComponent.vue';
import { IDataSourceViewModel, DataSourceViewModel } from '@/view-models/i-data-source-view-model';
import { HierarchicalDataSourceTypes, TagBasedDataSourceTypes } from '@/enum/data-source-types';
import HelperMethods from '@/shared/helper-methods';

@Component({
  name: 'data-source-details'
})
export default class DataSourceDetails extends BaseComponent {

  @Prop({ required: true})
  private dataSource !: DataSourceViewModel;
  private dataSrcSelected: HierarchicalDataSourceTypes |
                            TagBasedDataSourceTypes = HierarchicalDataSourceTypes.PiAssetFramework;
  private dataSourceName: string = '';
  private showProgress: boolean = false;
  private btnText: string = '';
  private titleText: string = '';
  private selectedDataSource = new DataSourceViewModel();

  get dataSrcOptions(): IBSelect[] {
    const piTypes = Object.values(HierarchicalDataSourceTypes);
    const piAfTypes = Object.values(TagBasedDataSourceTypes);
    const options: string[] = [...piAfTypes, ...piTypes] ;
    return options.map((type) =>
      ({ value: type, text: this.$t(`dataSource.type.${type}`).toString() }));
  }
  get dataSourceList(): IDataSourceViewModel[] {
    return store.state.dataSource.dataSourceList;
  }
  get hasDatasourceKey(): boolean {
    return !HelperMethods.isStringEmpty(this.dataSource.dataSourceKey);
  }
  get hasName(): boolean {
    return this.dataSourceName !== this.dataSource.name;
  }
  get isInvalidDataSource(): boolean {
    return this.dataSourceList != null &&
      this.dataSourceList.findIndex((item: IDataSourceViewModel) =>
        HelperMethods.equalsIgnoreCase(item.name, this.dataSourceName) &&
        !HelperMethods.isStringEmpty(item.name)) > -1;
  }
  get hasSpecialCharacters(): boolean {
    return HelperMethods.specialCharacters(this.dataSourceName);
  }
  get hasLetters(): boolean {
    return HelperMethods.hasLetters(this.dataSourceName);
  }
  public mounted(): void {
    this.setValues();
  }
  public setValues(): void {
    if (this.dataSource.dataSourceKey) {
      this.dataSourceName = this.dataSource.name;
      this.dataSrcSelected = this.dataSource.type;
    }
  }
  public async addDataSrc(): Promise<void> {
    this.showProgress = true;
    const dataSourceInfo: IDataSourceViewModel = {} as IDataSourceViewModel;
    dataSourceInfo.name = this.dataSourceName;
    dataSourceInfo.type = this.dataSrcSelected;
    dataSourceInfo.agentKey = this.dataSource.agentKey;
    dataSourceInfo.customerKey = this.dataSource.customerKey;
    try {
      if (this.dataSource.dataSourceKey) {
        dataSourceInfo.dataSourceKey = this.dataSource.dataSourceKey;
        await store.dispatch('dataSource/updateDataSource',dataSourceInfo);
      } else {
        await store.dispatch('dataSource/createDataSource',dataSourceInfo);
      }
      this.$root.$emit('bv::toggle::collapse','collapse-' + this.dataSource.dataSourceKey);
    } catch (err) {
      this.showProgress = false;
    }
    this.showProgress = false;
  }
  public disableCreate(): boolean {
    return HelperMethods.isStringEmpty(this.dataSourceName)
          || (this.isInvalidDataSource && this.hasName);
  }
  public cancel(): void {
    this.$root.$emit(
      'bv::toggle::collapse',
      'collapse-' + this.dataSource.dataSourceKey
    );
    this.$emit('update-visible', true);
    if (!this.dataSource.dataSourceKey) {
      this.dataSourceList.splice(this.dataSourceList.findIndex((x) =>
        HelperMethods.isStringEmpty(x.dataSourceKey)), 1);
    }
    this.setValues();
  }
}
