import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import { IHierarchy } from '@/view-models/i-hierarchy-model';
import { IHierarchyAgentTags, IHierarchyTags } from '@/view-models/i-hierarchy-tags';

export class TagsService extends BaseService {

    constructor(axiosInstance: AxiosInstance, portalApiUrl: string, path: string) {
        super(portalApiUrl, '', axiosInstance, path);
    }

    public async getTagsByHierarchyId(customerKey: string, selectedHB: IHierarchy): Promise<IHierarchyAgentTags> {
        return (await this.httpClient.get<IHierarchyAgentTags>(`${customerKey}/hierarchy/${selectedHB.key}/tags`));
    }

    public async getTagInputsByHierarchyId(customerKey: string, selectedHB: IHierarchy): Promise<IHierarchyTags> {
        return (await this.httpClient.get<IHierarchyTags>(`${customerKey}/hierarchy/${selectedHB.key}/inputs`));
    }
}
