import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import { IHierarchy } from '@/view-models/i-hierarchy-model';
import { IHierarchyTags } from '@/view-models/i-hierarchy-tags';
import { HierarchyApplicationPayload } from '@/store/hierarchyBuilderStore/hierarchy-builder';

export class HierarchyService extends BaseService {

    constructor(axiosInstance: AxiosInstance, portalApiUrl: string, path: string) {
        super(portalApiUrl, '', axiosInstance, path);
    }

    public async getPublishedHierarchies(customerKey: string): Promise<IHierarchy[]> {
        return (await this.httpClient.get<IHierarchy[]>(`${customerKey}/publishedhierarchies`));
    }


    public async getTagInputsByHierarchyId(selectedHB: IHierarchy): Promise<IHierarchyTags> {
        return (await this.httpClient.get<IHierarchyTags>(`hierarchies/${selectedHB.key}/inputs`));
    }

    public async getHierarchyById(id: string): Promise<IHierarchy> {
        return (await this.httpClient.get<IHierarchy>(`hierarchies/${id}`));
    }

    public async getHierarchyByApplicationAssetKey(assetTypeKey: HierarchyApplicationPayload) {
        return (await this.httpClient.get<IHierarchy>(`hierarchies/assets/${assetTypeKey.assetKey}/hierarchies/application/${assetTypeKey.applicationType}`));
    }
}
