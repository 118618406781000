








import { Component, Prop } from 'vue-property-decorator';
import Loading from '@/components/common/Loading.vue';
import BaseComponent from '@/shared/BaseComponent.vue';
import IStatus from '@/view-models/i-status';
import HelperMethods from '@/shared/helper-methods';
import { ActiveStatus, AgentStatus } from '@/enum/agent-status';

@Component({
  name: 'status-visual',
  components: { Loading }
})
export default class StatusVisual extends BaseComponent {
  @Prop({ required: true })
  private state!: IStatus;
  @Prop({ required: false, default: true })
  private showDot!: boolean;
  private guiid: string = HelperMethods.newGuid();
  get isChanging(): boolean {
    return this.state.status !== this.state.desiredStatus;
  }
  get id(): string {
    return 'status-visual-' + this.guiid;
  }
  get statusClass(): string {
    switch (this.state.status) {
      case AgentStatus.AwaitingCertificate:
      case AgentStatus.ReadyForDataSource:
      case AgentStatus.ReadyForScheduledTask:
      case ActiveStatus.AwaitingCredentials:
      case AgentStatus.AwaitingApiKey:
        return 'secondary';
      case ActiveStatus.Healthy:
        return 'success';
      case ActiveStatus.Degraded:
        return 'warning';
      case ActiveStatus.Down:
        return 'danger';
      case ActiveStatus.Disabled:
        return 'secondary';
      default: 
        return 'secondary';
    }
  }
}
