import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import { IUserTokenViewModel } from '@/view-models/i-user-token-view-model';

export class UserTokenService extends BaseService {

    constructor(axiosInstance: AxiosInstance, portalApiUrl: string) {
        super(portalApiUrl, '', axiosInstance,'customers');
    }

    public async getUserToken(customerKey: string): Promise<IUserTokenViewModel> {
        return (await this.httpClient.get<IUserTokenViewModel>(`${customerKey}/user-token`));
    }
}
