import BaseService from './base-service';
import { AxiosInstance } from 'axios';
import { ITaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';

export class TaskDefinitionService extends BaseService {

  constructor(axiosInstance: AxiosInstance, portalApiUrl: string) {
    super(portalApiUrl, '', axiosInstance,'agents');
  }
  public async getTaskDefinitions(agentKey: string): Promise<ITaskDefinitionViewModel[]> {
    return (await this.httpClient.get<ITaskDefinitionViewModel[]>(`${agentKey}/task-definitions`));
  }
  public async createTaskDefinition(createInfo: ITaskDefinitionViewModel): Promise<ITaskDefinitionViewModel> {
    return (await this.httpClient.post<ITaskDefinitionViewModel>(`${createInfo.agentKey}/task-definitions`,
        createInfo));
  }
  public async updateTaskDefinition(updateInfo: ITaskDefinitionViewModel): Promise<ITaskDefinitionViewModel> {
    return (await this.httpClient
      .put<ITaskDefinitionViewModel>(`${updateInfo.agentKey}/task-definitions/${updateInfo.taskDefinitionKey}`,
          updateInfo));
  }
  public async enableTaskDefinition(updateInfo: ITaskDefinitionViewModel): Promise<ITaskDefinitionViewModel> {
    return (await this.httpClient
      .put<ITaskDefinitionViewModel>(`${updateInfo.agentKey}/task-definitions/${updateInfo.taskDefinitionKey}/enable`,
          updateInfo));
  }
  public async disableTaskDefinition(updateInfo: ITaskDefinitionViewModel): Promise<ITaskDefinitionViewModel> {
    return (await this.httpClient
      .put<ITaskDefinitionViewModel>(`${updateInfo.agentKey}/task-definitions/${updateInfo.taskDefinitionKey}/disable`,
          updateInfo));
  }
  public async getSpecificTaskDefinition(agentKey: string, key: string): Promise<ITaskDefinitionViewModel> {
    return (await this.httpClient.get<ITaskDefinitionViewModel>(`${agentKey}/task-definitions/${key}`));
  }
  public async triggerAgentRun(taskInfo: ITaskDefinitionViewModel): Promise<{token: string, url: string}> {
    return this.httpClient.put<{token: string, url: string}>(`${taskInfo.agentKey}/task-definitions/${taskInfo.taskDefinitionKey}/run?customerKey=${taskInfo.customerKey}`);
  }
  public async validateTags(agentKey: string, key: string): Promise<void> {
    return this.httpClient.put(`${agentKey}/task-definitions/${key}/validate-tags`);
  }
}
