














import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';
import { ITaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import store from '@/store';
import HelperMethods from '@/shared/helper-methods';
import { IAccordianDetails } from '@/view-models/i-accordian';
import { StepProgressState } from '@/enum/step-progress-state';
import TypeAheadDropdown, { TypeAheadItem } from '@/components/common/TypeAheadDropdown.vue';
import { cloneDeep } from 'lodash';
import { IAssetServiceHierarchyModel } from '@/view-models/i-asset-service-hierarchy-model';
import  assetServiceStore from '@/store/assetServiceStore/assetServiceStore';
import Loading from '@/components/common/Loading.vue';
@Component({
  name: 'asset-service-details-view',
  components: {
    TypeAheadDropdown,
    Loading
  }
})
export default class AssetServiceDetailsView extends BaseComponent {
  @Prop({required: true})
  private stepData!: IAccordianDetails;
  private assetSelected: string = '';
  private dropdownOptions: TypeAheadItem[] = [];

  get isAssetsLoaded(): boolean {
    return assetServiceStore.assetsLoaded;
  }
  get isHealthy(): boolean {
    return this.specificTaskDefinition.status === this.runningStatuses.Healthy;
  }
  get assetServiceAssetList(): IAssetServiceHierarchyModel[] {
    return assetServiceStore.assets;
  }
  get specificTaskDefinition(): ITaskDefinitionViewModel {
    return store.state.taskDefinition.specificTaskDefinition;
  }
  get taskType(): string {
    return store.state.taskDefinition.selectedTaskType;
  }
  get assetOptions(): TypeAheadItem[] {
    return this.getAssetsByType(this.assetServiceAssetList);
  }
  get selectedAsset(): string {
    return this.assetSelected;
  }
  set selectedAsset(asset: string) {
    this.assetSelected = !HelperMethods.isStringEmpty(asset)? asset : '';
  }
  get assetName(): string {
    if (this.assetOptions.length > 0) {
      const index = this.assetOptions.findIndex((a) => a.value === this.selectedAsset);
      return this.assetOptions[index]?.value;
    }
    return '';
  }
  get specificCustomerKey(): string {
    return store.getters['agent/specificCustomerKey'];
  }
  get assetDisplayName(): string {
     if (this.assetOptions.length > 0) {
      const index = this.assetOptions.findIndex((a) => a.value === this.selectedAsset);

      if (index > -1) {
        return this.assetOptions[index].name;
      }
    }
    return '';
  }

  public mounted(): void {
    this.loadTaskData();
    this.watchValues();
  }

  public updated(): void {
    this.watchValues();
    const typeahead = this.$refs['typeahead'] as TypeAheadDropdown;

    if (typeahead) {
      typeahead.selectedType = this.assetDisplayName;
    }
  }
  public async loadTaskData(): Promise<void> {
    if (this.$route.params.taskid) {
      this.setTaskDefinitionInfo(this.specificTaskDefinition);
    } else {
      this.resetTaskDefinitionInfo();
    }

    if (this.$route.params.taskid == null) {
      this.selectedAsset = '';
    }
  }

  public setSelectedType($event: TypeAheadItem) {
    this.selectedAsset = $event.value;
  }
  
  public async watchValues(): Promise<void> {
    const updatedObject: ITaskDefinitionViewModel = {} as ITaskDefinitionViewModel;
    updatedObject.assetKey = this.selectedAsset;
    updatedObject.assetName = this.assetDisplayName;
    this.updateStepProgress(!this.isEmpty(updatedObject));
    if (this.specificCustomerKey && this.selectedAsset) {
      await assetServiceStore.getAssetTags({customerKey: this.specificCustomerKey, assetKey: this.selectedAsset });
      await assetServiceStore.setSelectedAsset(this.selectedAsset);
    }
    store.commit('taskDefinition/updateSectionDetails', updatedObject);
  }
  public isEmpty(values: ITaskDefinitionViewModel): boolean {
    return Object.values(values).some((x) => (HelperMethods.isStringEmpty(x)));
  }
  public async updateStepProgress(value: boolean): Promise<void> {
    if (value) {
      this.stepData.state = StepProgressState.Completed;
    } else {
      this.stepData.state = StepProgressState.Pending;
    }
    await store.dispatch('taskDefinition/updateAccordianDetail', this.stepData);
  }
  public setTaskDefinitionInfo(data: ITaskDefinitionViewModel): void {
    if (data.name) {
      this.selectedAsset = data.assetKey;
    }
  }
  public resetTaskDefinitionInfo(): void {
    if (this.assetOptions.length > 0) {
      this.selectedAsset = this.assetOptions[0].value;
    }
  }
  public getAssetsByType(assets: IAssetServiceHierarchyModel[]): TypeAheadItem[] {
    const sorted = cloneDeep(assets);
    sorted.sort((a, b) => {
      if (a.assetName > b.assetName) {
        return 1;
      }
      if (a.assetName < b.assetName) {
        return -1;
      }
      return 0;
    });
    if (sorted.length > 0) {
      return sorted.map((asset) => ({ value: asset.assetKey, name: asset.assetName, active: this.selectedAsset === asset.assetKey ? true : false}));
    }
    return [];
  }
}
