





































import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';
import { ITaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import store from '@/store';
import { IAccordianDetails } from '@/view-models/i-accordian';
import { StepProgressState } from '@/enum/step-progress-state';
import TableComponent from '@/components/common/TableComponent.vue';
import SearchBar from '@/components/common/SearchBar.vue';
import SelectTags from '@/components/create/SelectTags.vue';
import { IBTableField } from '@/view-models/shared/b-table';
import { IResponseMessage, ITag, ITagData, Tag } from '@/view-models/i-tag-view-model';
import { translatableResources } from '@/static/locales/translatableResources';
import { TagBasedTaskTypes } from '@/enum/task-definition-type';
import HelperMethods from '@/shared/helper-methods';
import {IHierarchy} from '@/view-models/i-hierarchy-model';
import Loading from '@/components/common/Loading.vue';
import { TagBasedDataSourceTypes } from '@/enum/data-source-types';
import  assetServiceStore from '@/store/assetServiceStore/assetServiceStore';


@Component({
  name: 'tag-details-view',
  components: {
    TableComponent,
    SearchBar,
    SelectTags,
    Loading
  }
})
export default class TagDetailsView extends BaseComponent {
  public perPageItems = 25;
  public ref!: TableComponent;
  @Prop({required: true})
  private stepData!: IAccordianDetails;
  private availableTagsLength: number = 0;
  private pollingInterval: number = 0;
  private componentKey: number = 0;

  get selectedTaskType(): string {
    return store.state.taskDefinition.selectedTaskType;
  }

  get isFileType(): boolean {
    return store.state.dataSource.selectedDataSource.type === TagBasedDataSourceTypes.File;
  }
  
  get isHealthy(): boolean {
    return this.specificTaskDefinition.status === this.runningStatuses.Healthy;
  }

  get specificTaskDefinition(): ITaskDefinitionViewModel {
    return store.state.taskDefinition.specificTaskDefinition;
  }

  get isHierarchiesEmpty(): boolean {
    return this.checkHierarchy();
  }

  get searchAvailableTags(): string {
    return this.$t('taskDefinition.availableTags').toString().toLowerCase();
  }

  get availableTags(): ITag[] {
    return store.state.dataSource.availableTags.tags;
  }

  get isHierarchyBuilderType(): boolean {
    return this.selectedTaskType === TagBasedTaskTypes.HierarchyBuilder;
  }
   get isAssetServiceType(): boolean {
    return this.selectedTaskType === TagBasedTaskTypes.AssetService;
  }
  get selectedTags(): ITag [] {
    if (this.isHierarchyBuilderType) {
      return store.state.hierarchyBuilder.mappedHierarchySelectedTags;
    }

    if (this.isAssetServiceType) {
      return assetServiceStore.mappedAssetTags.tagMetaData;
    }

    return this.isHierarchyBuilderType ? store.state.hierarchyBuilder.mappedHierarchySelectedTags :
        store.state.dataSource.selectedTags;
  }
  
  get selectedTag(): ITag {
    return store.state.dataSource.selectedTag;
  }
  get emptyText(): string {
    return this.$t(translatableResources.taskDefinition.availableTagsEmpty).toString();
  }
  get hasAvailableTags(): boolean {
    return this.availableTags && this.availableTags.length > 0;
  }
  get updatedTime(): string {
    return store.state.dataSource.availableTags?.createdOnUtc;
  }
  get checkUpdatedTime(): boolean {
    return HelperMethods.isStringEmpty(this.updatedTime);
  }
  get hierarchies(): IHierarchy[] {
    return store.state.hierarchyBuilder.hierarchyList;
  }

  get getSelectedPageItems(): [] {
    return this.ref?.getAllPageItems (this.ref.filteredData, this.perPageItems);
  }

  get specificCustomerKey() {
    return store.getters['agent/specificCustomerKey'];
  }

  get tableFields(): IBTableField[] {
    const fields: IBTableField[] = [
      {label: 'Tag Name', key: 'name', tdClass: 'col-width'},
      {label: 'Data Type', key: 'sourceDataType'}
    ];

    if ( !this.isHierarchyBuilderType && !this.isHealthy ) {
      fields.unshift( {label: 'select', key: 'selected'} );
    }
    return fields;
  }

  get isLoading(): boolean {
    return store.getters['dataSource/isLoadingTags'];
  }

  @Watch('selectedTags')
  public watchValues(): void {
    if (!!this.selectedTags && this.selectedTags.length > 0) {
      this.updateStepProgress(true);
    } else {
      this.updateStepProgress(false);
    }
  }

  @Watch ('selectedTag')
  public updateSelection(): void {
    this.$nextTick ().then (() => {
      this.addTags (this.selectedTag);
      this.$root.$emit ('bv::refresh::table', 'data-table');
    });
  }

  public mounted(): void {
    this.ref = this.$refs.tagDetails as TableComponent;
    this.watchValues();
    if (this.hasAvailableTags) {
      this.availableTagsLength = this.availableTags.length;
    }
  }

  public checkHierarchy(): boolean {
    return !this.hierarchies || (this.hierarchies && this.hierarchies.length ===0);
  }

  public beforeDestroy(): void {
    this.clearPolling();
  }

  public clearPolling(): void {
    clearTimeout(this.pollingInterval);
    this.showRefreshProgress = false;
  }
  
  public validateManualTags(value: boolean) : void {
    if (!!this.selectedTags && this.selectedTags.length > 0 && value) {
      this.updateStepProgress(true);
    } else {
      this.updateStepProgress(false);
    }
  }
  public updateStepProgress(value: boolean): void {
    if (value) {
      this.stepData.state = StepProgressState.Completed;
    } else {
      this.stepData.state = StepProgressState.Pending;
    }
  }
  public addTags(data: ITag | ITaskDefinitionViewModel): void {
    store.dispatch ('dataSource/updateSelectedTags', data);
  }


  private async validateFile(event: Event) {
    const input: HTMLInputElement = event.target as HTMLInputElement;

    try {
      store.commit('dataSource/setIsLoadingTags', true);
      this.updateStepProgress(false);
      if (input && input?.files && input?.files.length > 0){
        const response: IResponseMessage<ITag[]> = await store.dispatch('taskDefinition/validateTagsByFile', {file: input.files[0], customerKey: this.specificCustomerKey });
        if (response.responseMessage !== '' && !response.success) {
           store.dispatch('error/setError', {
            error: null,
            errorString: response.responseMessage,
            handleError: true,
            routeHomeAfterError: false
          });
        }
        const tags = response.tags as ITag[];
        if (tags.length > 0) {
           const results = tags.map(value => {
             const data = value.name.replace('\r\n', '').replace('\r','').trim();
             return {
               name: data,
               key: data,
               previousValue: data
             } as ITagData;
           }).filter((value => value.name !== ''));
           results.map((tag) => {
             this.addTags(tag);
          });
        }
      }
    } catch (e) {
       store.dispatch('error/setError', {
          error: e,
          errorString: 'Failed to upload file.\n',
          handleError: true,
          routeHomeAfterError: false
        });
    } finally {
      input.value = '';
      store.commit('dataSource/setIsLoadingTags', false);
      this.watchValues();
    }
  }
}
