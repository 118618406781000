













































































// Libraries
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BDropdown } from 'bootstrap-vue';
import { IBTableField } from '@/view-models/shared/b-table';
import store from '@/store';
import { IAgentViewModel } from '@/view-models/i-agent-view-model';
// Components
import BaseComponent from '@/shared/BaseComponent.vue';
import TableComponent, { TableItem } from '@/components/common/TableComponent.vue';
import StatusVisual from '@/components/common/StatusVisual.vue';

@Component({
  name: 'agent-management-table',
  components: {
    TableComponent,
    StatusVisual
  }
})
export default class AgentManagementTable extends BaseComponent {
  @Prop ({required: true})
  private emptyText!: string;
  @Prop ({required: false})
  private primaryKey!: string;
  @Prop ({required: false})
  private searchName!: string;
  @Prop ({required: true})
  private tableData!: [];
  @Prop ({required: true})
  private perPageItems!: number;
  @Prop ({required: true})
  private tableFields!: IBTableField[];
  @Prop({required: true})
  private isLoading!:boolean;
  private agentTableLoading: boolean = false;
  private filteredTableData: TableItem[] = [];
  
  get hasData(): boolean {
    return this.tableData && this.tableData.length > 0;
  }

  get latestAgentVersion(): string {
    return `${store.getters['agent/latestAgentVersion']}`;
  }
  
  @Watch('tableData')
  public updateTableData() {
    const tableComponent = this.$refs.agentManagementTable as TableComponent;
    this.filteredTableData = [];
    this.filteredTableData = [...this.tableData];
    tableComponent.refreshTable();
  }
  
  @Watch('isLoading')
  public updateLoading() {
    this.agentTableLoading = this.isLoading;
  }

  public mounted() {
    if (this.hasData) {
      this.filteredTableData = [...this.tableData];
    }

    this.agentTableLoading = this.isLoading;
  }

  public async refresh() {
    this.agentTableLoading = true;
    const tableComponent = this.$refs.agentManagementTable as TableComponent;
    await store.dispatch('agent/loadAll')
          .then(() => {
            this.agentTableLoading = false;
            tableComponent.refreshTable();
          })
          .catch((err) => {
            store.dispatch('error/setError', {
              error: err,
              errorString: 'Error Loading Plant Connector\n',
              handleError: true,
              routeHomeAfterError: false
            });
          });
  }
  
  public isInProgress(agent: IAgentViewModel): boolean {
    return agent.status !== agent.desiredStatus;
  }

  public showMenu(id: number) {
    const ref = this.$refs[`dropdown-${id}`] as BDropdown;
    ref.show ();
  }

  public isStatusDisable(agentInfo: IAgentViewModel): boolean {
    return agentInfo.status === this.runningStatuses.Disabled;
  }

  public isAgentConfiguring(agent: IAgentViewModel): boolean {
    return agent.status === this.setupStatuses.AwaitingCertificate
        || agent.status === this.setupStatuses.ReadyForDataSource
        || agent.status === this.setupStatuses.ReadyForScheduledTask
        || agent.status === this.runningStatuses.PendingValidation;
  }

  public isStatusDown(agent: IAgentViewModel): boolean {
    return agent.status === this.runningStatuses.Down;
  }

  public async toggleAgentStatus(agentInfo: IAgentViewModel): Promise<void> {
    await store.dispatch ('agentSelected/toggleAgentStatus', agentInfo);
  }

  public async getAgentInfo(agentInfo: IAgentViewModel) {
    if (agentInfo.agentKey) {
      store.dispatch ('agentSelected/setSelectedAgent', agentInfo);
      store.dispatch('dataSource/setDataSourcesLoaded', false);
      store.dispatch ('taskDefinition/setTaskDefinitionsLoaded', false);
      this.$router.push (`/${agentInfo.agentKey}/data-sources`);
    }
  }

}
