import { AxiosInstance } from 'axios';
import BaseService from '@/services/base-service';
import { IAsset } from '@/view-models/i-asset-view-model';

export default class AuthService extends BaseService {

  constructor(axiosInstance: AxiosInstance, userManagementApi: string) {
    super(userManagementApi, '', axiosInstance, '');
  }

  public async getCustomerAssets(): Promise<IAsset[]> {
    return this.httpClient.get<IAsset[]>('users/current/assignmentTree',
            { query: {onlyActiveCustomer : true, 'api-version': '1.0'} }, );
  }
}

