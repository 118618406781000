import { AxiosInstance } from 'axios';
import HttpClient from './http-client';

export default class BaseService {
  protected httpClient: HttpClient;

  constructor(
    private readonly base: string,
    private readonly prefix: string,
    private readonly http: AxiosInstance,
    private readonly path: string = 'api'
  ) {
    this.base = base;
    this.prefix = prefix || '';
    this.path = path != null && typeof path === 'string' ? path : 'api';
    this.http = http;
    this.http.defaults.baseURL = this.base;
    this.httpClient = new HttpClient(
      this.http,
      `${this.path}${this.prefix ? `/${this.prefix}` : ''}`
    );
  }

  protected getUrl(uri: string): string {
    return `${this.path}/${this.prefix}/${uri}`;
  }
}
