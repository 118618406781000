import Vue from 'vue';
import moment from 'moment';

export function formatDateLong(value: number): string {
  if (value) {
    return moment(String(value)).local().format('MMMM D, YYYY [at] h:mm a');
  }
  return 'N/A';
}

const formatDateLongFilter = Vue.filter('formatDateLong', formatDateLong);
export default formatDateLongFilter;