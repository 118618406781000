




























































import {Component, Watch} from 'vue-property-decorator';
import BaseComponent from '@/shared/BaseComponent.vue';
import { ITaskDefinitionViewModel } from '@/view-models/i-task-definition-view-model';
import { translatableResources } from '@/static/locales/translatableResources';
import store from '@/store';
import HelperMethods from '@/shared/helper-methods';
import TableComponent, { TableItem } from '@/components/common/TableComponent.vue';
import SearchBar from '@/components/common/SearchBar.vue';
import { IResponseMessage, ITag, ITagData } from '@/view-models/i-tag-view-model';
import { IBTableField } from '@/view-models/shared/b-table';
import { TagBasedDataSourceTypes } from '@/enum/data-source-types';
import IconButton from '@/components/dsm/IconButton.vue';
import { BTable } from 'bootstrap-vue';
import { differenceWith } from 'lodash';
import { TagBasedTaskTypes } from '@/enum/task-definition-type';
import { ActiveStatus } from '@/enum/agent-status';
import Loading from '@/components/common/Loading.vue';
import assetServiceStore from '@/store/assetServiceStore/assetServiceStore';

@Component({
  name: 'select-tags',
  components: {
    TableComponent,
    SearchBar,
    IconButton,
    Loading
  }
})
export default class SelectTags extends BaseComponent {
  private tags: ITagData[] = [];
  private updatedTagName: string | null = null;
  private errorMessage: string | null = null;
  private isEditing = false;
  private isLoading = false;
  
  get isHierarchyBuilderType(): boolean {
    return this.selectedTaskType === TagBasedTaskTypes.HierarchyBuilder;
  }
  get isAssetServiceType(): boolean {
    return this.selectedTaskType === TagBasedTaskTypes.AssetService;
  }
  get validTags(): number {
    if (this.selectedTags) {
      return this.selectedTags.filter((value) => value.isValid).length;
    }

    return 0;
  }
  get hasErrorMessage(): boolean {
    return this.errorMessage != null;
  }
  get specificTaskDefinition(): ITaskDefinitionViewModel {
    return store.state.taskDefinition.specificTaskDefinition;
  }
  get isHealthy(): boolean {
    return this.specificTaskDefinition.status === this.runningStatuses.Healthy;
  }
  get searchSelectedTags(): string {
    return this.$t('taskDefinition.selectedTags').toString().toLowerCase();
  }
  get selectedTaskType(): string {
    return store.getters['taskDefinition/getSelectedTaskType'];
  }
  get availableTags(): ITag[] {
    return store.state.dataSource.availableTags.tags;
  }
  get hasTags(): boolean {
    return this.tags && this.tags.length === 0;
  }
  get selectedTags(): ITag [] {
    if (this.isHierarchyBuilderType) {
      return store.state.hierarchyBuilder.mappedHierarchySelectedTags;
    }

    if (this.isAssetServiceType) {
      return assetServiceStore.mappedAssetTags.tagMetaData;
    }

    return store.state.dataSource.selectedTags;
  }
  get tableFields(): IBTableField[] {
    const fields: IBTableField[] = [
      {label: 'Tag Name', key: 'name', tdClass: 'tag-name'},
      {label: 'Data Type', key: 'sourceDataType', tdClass: 'col-width'},
      {label: 'Validation Status', key: 'isValid', tdClass: 'col-width'},
    ];

    if (!this.isHierarchyBuilderType && !this.isAssetServiceType) {
      fields.push({label: 'Actions', key: 'actions', tdClass: 'actions-width' });
    }

    if (this.isHierarchyBuilderType || this.isAssetServiceType) {
      fields.splice(2, 0, {label: 'Direction', key: 'direction', tdClass: 'col-width'});
    }
    return fields;
  }
  get tagsLength(): number {
    return this.tags?.length ?? 0;
  }
  get maxLimit(): string {
    return (this.tags && this.tags.length > 499 && !this.isHierarchyBuilderType) ? `- ${this.$t(translatableResources.taskDefinition.maxLimitReached).toString()}` : '';
  }
  get selectedTagsEmptyText(): string {
    return this.$t(translatableResources.taskDefinition.selectedTagsEmpty).toString();
  }
  get dataSourceName(): string {
    return store.state.dataSource.selectedDataSource.name;
  }
  get isFileType(): boolean {
    return store.state.dataSource?.selectedDataSource?.type === TagBasedDataSourceTypes.File;
  }

  get specificCustomerKey() {
    return store.getters['agent/specificCustomerKey'];
  }
  
  public mounted(): void {
    this.loadTaskData();
    this.watchValues();
  }
  public async loadTaskData(): Promise<void> {
    if (this.$route.params.taskid) {
      this.setTaskDefinitionInfo(this.specificTaskDefinition);
    } else {
      this.resetTaskDefinitionInfo();
    }
  }
  @Watch('tags')
  public async setTags(){
    // Set tags for hierarchybuilder type.
    if (this.tags?.length > 0 && this.isHierarchyBuilderType) {
      store.commit('hierarchyBuilder/setMappedHierarchySelectedTags', this.tags);
    }

    if (store.state.dataSource.selectedTags.length > 0 && this.isAssetServiceType) {
      const mappedAssetTags = assetServiceStore.mappedAssetTags;
      mappedAssetTags.tagMetaData = store.state.dataSource.selectedTags;
      await assetServiceStore.setAssetTags(mappedAssetTags);
    }
  }
  @Watch('selectedTags')
  public watchValues(): void {
    if (this.selectedTags) {
      const diff = differenceWith(this.selectedTags, this.tags);
      this.tags = [];
      this.tags = [...this.selectedTags] as ITagData[];
  
      if (diff.length > 0) {
        diff.forEach((item) =>  (this.$refs.selectedTags as TableComponent).addToTrie(item.name));
        diff.forEach((item) => (this.$refs.selectedTags as TableComponent).dictionary.set(item.name, item as ITag & TableItem));
      }
      const updatedObject: ITaskDefinitionViewModel = {} as ITaskDefinitionViewModel;
      updatedObject.selectedTags = this.tags;
  
      store.commit('taskDefinition/updateSectionDetails',updatedObject);
    }
  }
  public setTaskDefinitionInfo(data: ITaskDefinitionViewModel): void {
    if (data.name) {
      store.commit('dataSource/setSelectedTags', data.selectedTags);
      this.tags = [...data.selectedTags as ITagData[]];
    }
  }
  public resetTaskDefinitionInfo(): void {
    if (this.selectedTags?.length > 0) {
      this.tags = this.selectedTags as ITagData[];
    } else {
      this.tags = [];
    }
  }
  public getSearchResults(value: any) {
    if (!HelperMethods.isStringEmpty(value)) {
      this.tags = this.selectedTags.filter((x) => Object.values(x).some((v) =>
                    v.toString().toLowerCase().indexOf(value.toLowerCase()) > -1)) as ITagData[];
    } else {
      this.tags = this.selectedTags as ITagData[];
    }
  }

  private deleteTag(tag: ITag): void {
    (this.$refs.selectedTags as TableComponent).removeFromTrie(tag.name);
    store.commit ('dataSource/deleteFromSelectedTags', tag);
  }

  public selectRow(data: BTable) {
    this.unselectAll();
    data.item.editing = true;
    (this.$refs.selectedTags as TableComponent).refreshTable();
  }

   private unselectAll(): void {
    const temp: ITagData[] = [...this.selectedTags] as ITagData[];
    temp.forEach((record) => record.editing = false);
    this.tags = [];
    this.tags = [...temp];
    (this.$refs.selectedTags as TableComponent).refreshTable();
  }

  private reset(data: BTable) {
    this.unselectAll();
    const temp = [...this.selectedTags] as ITagData[];

    if (temp[data.index]?.previousValue != null && temp[data.index]?.previousValue !== '') {
      temp[data.index].name = temp[data.index].previousValue;
      temp.map((tag) => store.dispatch ('dataSource/updateSelectedTags', tag));
     (this.$refs.selectedTags as TableComponent).refreshTable();
    }
    this.errorMessage = null;
    this.updatedTagName = null;
    this.isEditing = false;
    this.$emit('is-valid', true);
  }
  
  private async validateTagName(data: BTable) {
    if (this.updatedTagName === '' || this.updatedTagName == null) {
      return;
    }
    const hasRecord = (this.$refs.selectedTags as TableComponent).dictionary.has(this.updatedTagName);

    if (data?.item?.previousValue !== this.updatedTagName && hasRecord) {
      this.errorMessage = 'The following tag already exists.';
      return;
    }

    if (this.updatedTagName.length > 200) {
      this.errorMessage = 'Tag cannot be longer than 200 characters.';
      return;
    }
    
    try {
      this.isLoading = true;
      this.$emit('is-valid', false);
      const response: IResponseMessage<ITag[]> = await store.dispatch('taskDefinition/validateTagName', {tags: [this.updatedTagName], customerKey: this.specificCustomerKey });
      if (response.responseMessage !== '' && !response.success) {
        this.errorMessage = response.responseMessage;
        return;
      }
      this.updateTag(data);
    } catch (e) {
      store.dispatch('error/setError', {
        error: e,
        errorString: 'Failed to add tag.\n',
        handleError: true,
        routeHomeAfterError: false
      });
    } finally {
      this.isLoading = false;
    }
  }

  private async updateTag(data: BTable) {
    // this is a new record, add it to the dictionary
    if (this.updatedTagName != null && data?.item?.previousValue === '') {
      (this.$refs.selectedTags as TableComponent).dictionary.set(this.updatedTagName, 
      { 
        name: this.updatedTagName,
        previousValue: this.updatedTagName
      } as ITagData & TableItem);
    }

    const previousName = data.item.name;
    data.item.editing = false;
    data.item.name = this.updatedTagName;
    data.item.key = this.updatedTagName;
    data.item.previousValue = this.updatedTagName;
    store.dispatch ('dataSource/updateSelectedTags', data.item);
    (this.$refs.selectedTags as TableComponent).updateTrie(previousName, this.updatedTagName ?? '');
    this.updatedTagName = null;
    this.errorMessage = null;
    this.isEditing = false;    
  }

  private addNewTag() {
    this.isEditing = true;
    const blankRow = {
      name: '',
      editing: true,
      previousValue: ''
    } as TableItem & ITagData; 

    (this.$refs.selectedTags as TableComponent).addNewTagRow(blankRow);
  }

  private getValidationStatus(data: ITagData): string {
    const isPendingValidation = this.specificTaskDefinition.status === ActiveStatus.PendingValidation;
    if ( data.isValid == null || isPendingValidation) {
      return 'Pending';
    }
    if (data.isValid) {
      return 'Completed';
    } else {
      return 'Failed';
    }
  }

  private validationStyle(data: ITagData): string {
    const isPendingValidation = this.specificTaskDefinition.status === ActiveStatus.PendingValidation;
    if ( data.isValid == null || isPendingValidation) {
      return 'pending';
    }
    if (data.isValid) {
      return 'completed';
    } else {
      return 'failed';
    }
  }

}
