import { HierarchicalDataSourceTypes, TagBasedDataSourceTypes } from '@/enum/data-source-types';
import { ActiveStatus, AgentStatus } from '@/enum/agent-status';
import IStatus from '@/view-models/i-status';
import ITracking from '@/view-models/i-tracking';

export interface IDataSourceViewModel extends IStatus, ITracking {
    dataSourceKey: string;
    agentKey: string;
    name: string;
    type: HierarchicalDataSourceTypes | TagBasedDataSourceTypes;
    lastTagRefreshedDate: string | null;
    customerKey: string;
}

export class DataSourceViewModel implements IDataSourceViewModel {
    public agentKey: string = '';
    public dataSourceKey: string = '';
    public name: string = '';
    public status: AgentStatus | ActiveStatus = ActiveStatus.Disabled;
    public desiredStatus: AgentStatus | ActiveStatus = ActiveStatus.Disabled;
    public type: HierarchicalDataSourceTypes | TagBasedDataSourceTypes = HierarchicalDataSourceTypes.PiAssetFramework;
    public lastTagRefreshedDate: string | null = '';
    public customerKey: string = '';
    public createdByDate: string = '';
    public createdByName: string = '';
    public createdByUserKey: string = '';
    public lastModifiedByDate: string = '';
    public lastModifiedByName: string = '';
    public lastModifiedByUserKey: string = '';
    constructor(dataSourceViewModel?: IDataSourceViewModel) {
        if (dataSourceViewModel != null) {
            Object.assign(this, dataSourceViewModel);
        }
    }
}
