export enum AvailableDataModalState {
  Inputs = 'Inputs',
  Outputs = 'Outputs',
  Compare = 'Compare',
  Logs = 'Logs'
}

export enum DownloadAction {
  Inputs = 'Inputs',
  Outputs = 'Outputs',
  All = 'All'
}